import React from "react";
import { Route, Redirect } from "wouter";
import GameCards from "./components/GameCards";
import EditWordList from "./components/WordLists/CustomWordListEdit";
import ClosedGames from "./components/ClosedGames";
import WordLists from "./components/WordLists";
import Home from "./components/Home";
import Settings from "./components/Settings";
import TeamList from "./components/TeamList";
import TeamEdit from "./components/TeamEdit";

export default function Authenticated() {
  return (
    <>
      <Route path={"/"}>
        <Home />
      </Route>
      <Route path="/game/:id">{(params) => <GameCards id={params.id} />}</Route>
      <Route path="/wordlist/:listCode">
        {(params) => <EditWordList listCode={params.listCode} />}
      </Route>
      <Route path="/closedgames">
        <ClosedGames />
      </Route>
      <Route path="/loginsuccess">
        <Redirect to="/" />
      </Route>
      <Route path="/wordlists">
        <WordLists />
      </Route>
      <Route path="/teams">
        <TeamList />
      </Route>
      <Route path="/teams/:id">{(params) => <TeamEdit id={params.id} />}</Route>
      <Route path="/settings">
        <Settings />
      </Route>
    </>
  );
}
