import React from "react";
import classnames from "classnames";
import {
  PlusIcon,
  PlusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
  ClipboardDocumentIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  ChevronRightIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/20/solid";

export const AddCircleIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <PlusCircleIcon className={finalClasses} />;
};

export const AddIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <PlusIcon className={finalClasses} />;
};

export const EditIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <PencilSquareIcon className={finalClasses} />;
};

export const DeleteIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-red-800 hover:text-red-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <TrashIcon className={finalClasses} />;
};

export const CopyIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ClipboardDocumentIcon className={finalClasses} />;
};

export const CloseIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <XMarkIcon className={finalClasses} />;
};

export const BBLogoutIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-red-800 hover:text-red-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ArrowRightOnRectangleIcon className={finalClasses} />;
};

export const BBChevronRightIcon = ({ width = 5, className }) => {
  let finalClasses = className || `cursor-pointer text-green-800 hover:text-green-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ChevronRightIcon className={finalClasses} />;
};

export const BBExclamationTriangleIcon = ({ width = 5, tooltip, className }) => {
  let finalClasses = className || `cursor-pointer text-orange-400 hover:text-orange-600`;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ExclamationTriangleIcon className={finalClasses} />;
};
