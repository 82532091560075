// libraries
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import _get from "lodash/get";
import { useLocation, Link } from "wouter";
import classnames from "classnames";
// atoms
import PlayerProfilePicture from "../atoms/PlayerProfilePicture";
import { DangerText, SmDemphText, SmText, XsDemphText } from "../atoms/Text";
import { PageBaseInsetContainer } from "../atoms/Pages";
import { CopyIcon } from "../atoms/IconsOutline";
import { ClickableDiv } from "../atoms/Link";
import BuzzwordCopyTextChildren from "../atoms/BuzzwordCopyTextChildren";
import EditableHeaderText from "../atoms/EditableHeaderText";
import { TabContentDisplay, TabsHorizontal } from "../atoms/TabsMenu";
import ModalConfirmDanger from "../atoms/ModalConfirmDanger";
import { DismissableAnnouncementBox } from "../atoms/AnnouncementBox";
// locals
import { GET_TEAM_DETAIL, UPDATE_TEAM, DELETE_TEAM } from "./queries";
import WordLists from "./WordLists";
import { RoundedCornerDangerTitleContainer, TitleContainer } from "../atoms/Containers";
import { SoloDangerButton } from "../atoms/Button";
import TeamContext from "./TeamContext";
import PremadeWordListTabContent from "./PremadeWordListTabContent";
import SelectMenu from "../atoms/SelectMenu";

export default function TeamEdit({ id }) {
  const primaryQuery = useQuery(GET_TEAM_DETAIL, { variables: { id } });
  const { data } = primaryQuery;
  const [, setLocation] = useLocation();

  // tabs
  const [activeTab, setActiveTab] = useState(0);

  // name
  const [name, setName] = useState("");

  // custom word list data
  const [customWordListData, setCustomWordListData] = useState([]);

  const [updateTeam] = useMutation(UPDATE_TEAM, {
    refetchQueries: primaryQuery
  });
  const [teamData, setTeamData] = useState({ name: "", open: false });

  // delete team
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteTeam] = useMutation(DELETE_TEAM, primaryQuery);

  // modify internal data based on props
  useEffect(() => {
    setTeamData(_get(data, "teamById", { name: "", open: false }));
    // when our base data comes in, we grab a copy of the custom word list data and store it off separately, as it's
    // managed internally from then on
    setCustomWordListData(_get(data, "teamById.customWordLists", []));

    setName(_get(data, "teamById.name", ""));
  }, [data]);

  const handleChangeName = async (newName) => {
    // TODO: error handling and user feedback
    await updateTeam({ variables: { id, name: newName }, errorPolicy: "all" });
  };

  //
  // set team open/close
  //
  const handleToggleTeamVisibility = async () => {
    await updateTeam({ variables: { id, open: !teamData.open }, errorPolicy: "all" });
  };

  //
  // drop down (mobile)
  //
  const dropDownOptions = ["Word Lists", "Premade Word Lists", "Players", "Serious Zone"];
  const handleDropDownSelect = (selection) => {
    const idx = dropDownOptions.indexOf(selection);
    if (idx > -1) setActiveTab(idx);
  };

  //
  // delete team
  //
  const handleDeleteTeam = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteTeamConfirmed = async (id) => {
    if (!id) throw new Error("delete confirmation missing id in callback");
    setShowDeleteConfirmation(false);
    await deleteTeam({ variables: { id }, errorPolicy: "all" });
    setLocation("/teams");
  };

  const handleDeleteTeamCanceled = () => {
    setShowDeleteConfirmation(false);
  };

  //
  // custom word list
  //
  const handleAddList = async (listCode) => {
    // if the code is already in the list, don't do shit.
    const currentList = customWordListData.map((c) => c.listCode);
    if (currentList.includes(listCode)) return;

    // update the team with the new code
    currentList.push(listCode);
    await updateTeam({ variables: { id, customWordListIds: currentList } });
  };

  const handleDeleteList = async (listCode) => {
    // remove the provided list code from the current word lists
    const newCustomWordLists = customWordListData
      .map((l) => l.listCode)
      .filter((l) => l !== listCode);
    await updateTeam({
      variables: {
        id,
        customWordListIds: newCustomWordLists
      }
    });
  };

  const teamContext = { ...teamData, teamId: teamData.id };

  return (
    <TeamContext.Provider value={teamContext}>
      <PageBaseInsetContainer className={`flex flex-col`}>
        {/* Back to Teams List */}
        <ClickableDiv className={"mb-4"}>
          <SmText>
            <Link href="/teams">
              <div className="flex w-full items-center ">
                <div>
                  <ArrowLongLeftIcon className="w-5" />
                </div>
                <div className="ml-2">
                  <SmText>Back To Team List</SmText>
                </div>
              </div>
            </Link>
          </SmText>
        </ClickableDiv>

        {/* Name */}
        <div className={"mb-2"}>
          <EditableHeaderText
            text={name}
            onChange={handleChangeName}
            editButtonTitle="Edit team name"
          />
        </div>

        {/* Team open/close status, invite code */}
        <div className="flex flex-col whitespace-nowrap mb-6">
          {/* open/close */}
          <div>
            {teamData.open ? (
              <SmText className="text-buzz-green-700 ">Team Open</SmText>
            ) : (
              <SmText className="text-buzz-red-700">Team Closed</SmText>
            )}
          </div>

          {/* Invitation Code */}
          {teamData.open ? (
            <div
              className={classnames(
                "flex space-x-1 items-center",
                teamData.open ? "" : "text-gray-400"
              )}
            >
              <div className="flex gap-1">
                <div className="font-bold">
                  <SmText>Invite Code:</SmText>
                </div>
                <div>
                  <SmText>{teamData.inviteCode}</SmText>
                </div>
              </div>

              {teamData.open ? (
                <BuzzwordCopyTextChildren copyValue={teamData.inviteCode} onCopyElem={XsDemphText}>
                  <CopyIcon width="4" />
                </BuzzwordCopyTextChildren>
              ) : null}
            </div>
          ) : null}
        </div>

        {/* Player Limit warning */}
        {teamData.players && teamData.players.length > 9 ? (
          <div className="mb-2">
            <DangerText>
              <SmText>
                This team has reached the player limit of 20 players. New players won't be able to
                join.
              </SmText>
            </DangerText>
          </div>
        ) : null}

        {/* Announcement box thing */}

        <DismissableAnnouncementBox
          title="Heads Up"
          boxKey={"teamedit-comingsoon-1"}
          className="mb-6"
        >
          We are working on creating some more information about a team's word data, both from
          custom and premade word lists, to help team owners create better games for their team
          members. It's a problem on our side too, so we want to solve the problem too... Stay
          tuned!
        </DismissableAnnouncementBox>

        {/* DROP DOWN - MOBILE */}
        <div className="sm:hidden flex justify-center mb-4">
          <SelectMenu onChange={handleDropDownSelect} options={dropDownOptions} />
        </div>

        {/* HORIZONTAL TABS (SMALL AND ABOVE) */}
        <div className="hidden sm:block sm:mb-2">
          <TabsHorizontal onChangeTab={setActiveTab}>
            <TabsHorizontal.TabBadge
              text="Word Lists"
              badgeText={customWordListData && customWordListData.length}
            />
            <TabsHorizontal.TabBadge
              text="Premade Word Lists"
              badgeText={customWordListData && customWordListData.length}
            />
            <TabsHorizontal.TabBadge
              text="Players"
              badgeText={teamData.players && teamData.players.length}
            />
            <TabsHorizontal.TabDanger text="Serious Zone" />
          </TabsHorizontal>
        </div>

        <TabContentDisplay activeIndex={activeTab}>
          {/* Word Lists*/}
          <WordLists
            customWordListArray={customWordListData}
            onAddList={handleAddList}
            onDeleteList={handleDeleteList}
          />

          {/* Premade Word Lists */}
          <PremadeWordListTabContent />

          {/* Players*/}
          <TitleContainer title="Players">
            <div className="flex flex-col space-y-1.5 p-2">
              {teamData.players && teamData.players.length > 0 ? (
                teamData.players.map((p) => <PlayerListEntry key={p.username} {...p} />)
              ) : (
                <SmDemphText>(none)</SmDemphText>
              )}
            </div>
          </TitleContainer>

          {/* Danger Zone*/}
          <div className={classnames("flex flex-col")}>
            <RoundedCornerDangerTitleContainer title="Serious Zone">
              {teamData.open ? (
                <SeriousZoneEntry
                  title="Team Open Status"
                  buttonText="Close Team"
                  onClickButton={handleToggleTeamVisibility}
                >
                  This team is currently open and new players that have the code can join. You can
                  change open/close at any time.
                </SeriousZoneEntry>
              ) : (
                <SeriousZoneEntry
                  title="Team Open Status"
                  buttonText="Open Team"
                  onClickButton={handleToggleTeamVisibility}
                >
                  This team is currently closed and any players with the team code cannot join. If
                  you want to allow new players to join the team, you'll need to open the team and
                  give them the new team join code. You can change the open/close status at any
                  time.
                </SeriousZoneEntry>
              )}

              {/* Delete Team */}
              <SeriousZoneEntry
                title="Delete This Team"
                buttonText="Delete Team"
                onClickButton={handleDeleteTeam}
              >
                Do you want to completely delete this team? Do it here. This is
                <span className="italic font-semibold">&nbsp;not</span> undoable, so don't screw it
                up!
              </SeriousZoneEntry>
            </RoundedCornerDangerTitleContainer>
          </div>
        </TabContentDisplay>

        {/* Delete Team Modal */}
        {showDeleteConfirmation ? (
          <ModalConfirmDanger
            open={true}
            onConfirm={() => handleDeleteTeamConfirmed(teamData.id)}
            onCancel={handleDeleteTeamCanceled}
          />
        ) : null}
      </PageBaseInsetContainer>
    </TeamContext.Provider>
  );
}

function SeriousZoneEntry({ title, buttonText, children, onClickButton }) {
  return (
    <div
      className={classnames(
        "flex gap-2 justify-between items-center flex-wrap sm:flex-nowrap",
        "py-2"
      )}
    >
      <div>
        <div className="font-semibold">{title}</div>
        <SmDemphText>{children}</SmDemphText>
      </div>
      <div>
        <SoloDangerButton onClick={onClickButton}>{buttonText}</SoloDangerButton>
      </div>
    </div>
  );
}

function PlayerListEntry({ username, pictureUrl }) {
  return (
    <div className="flex gap-3 items-center">
      <PlayerProfilePicture name={username} pictureUrl={pictureUrl} width="8" />
      <SmText className="text-gray-600">{username}</SmText>
    </div>
  );
}
