import React, { useState, useEffect, useRef, useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import _get from "lodash/get";
import classnames from "classnames";
import { SpreadContentRow } from "../../atoms/LayoutComponents";
import { SmDemphText, SmText } from "../../atoms/Text";
import PremadeWordTeamContext from "./PremadeWordTeamContext";
import { ClickableDiv, ClickableDivDanger } from "../../atoms/Link";
import { BBChevronRightIcon } from "../../atoms/IconsOutline";

export default function PremadeWordListEntry({
  name,
  listKey,
  isNewList = false,
  isExpanded = false,
  entryCount,
  usedEntryCount,
  description,
  onClickExpand
}) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className={classnames(
          "flex justify-between items-center cursor-pointer",
          "p-2",
          "gap-2",
          "border-b border-gray-200 last:border-b-0",
          { "bg-buzz-green-50": isHovering }
        )}
        onClick={onClickExpand}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="flex flex-col">
          <div className="flex gap-2">
            <div className={"font-semibold"}>{name}&nbsp;</div>
            <div>
              {usedEntryCount > 0 ? (
                <span className="text-sm text-buzz-red-700">
                  (using {usedEntryCount} of {entryCount})
                </span>
              ) : (
                <SmDemphText>({entryCount} available)</SmDemphText>
              )}
            </div>
            {isNewList && usedEntryCount < 1 ? (
              <div>
                <span
                  className={classnames(
                    "inline-flex items-center rounded-md",
                    "px-1.5 py-0.5",
                    "text-xs font-medium",
                    "bg-buzz-blue-100 text-buzz-blue-700"
                  )}
                >
                  New
                </span>
              </div>
            ) : null}
          </div>

          <div className="pl-4">
            <SmDemphText>{description}</SmDemphText>
          </div>
        </div>

        <div
          className={`transform transition-transform ${isExpanded ? "rotate-90" : ""}`}
          onClick={onClickExpand}
        >
          <BBChevronRightIcon />
        </div>
      </div>

      <div
        className={`flex flex-col gap-2 transition-all duration-500 ${
          isExpanded ? "max-h-auto" : "max-h-0 overflow-hidden"
        }`}
      >
        {isExpanded ? (
          <div className="py-2 px-4">
            <PremadeWordListDetailContent listKey={listKey} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

const GET_PREMADE_LIST_DETAIL = gql`
  query PremadeWordsByKey($key: String!) {
    premadeWordListByKey(key: $key) {
      key
      name
      contexts
      entries
    }
  }
`;

function PremadeWordListDetailContent({ listKey }) {
  const modalContentRef = useRef(null);
  const { data, loading } = useQuery(GET_PREMADE_LIST_DETAIL, { variables: { key: listKey } });
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    setEntries(_get(data, "premadeWordListByKey.entries", []));
  }, [data]);

  const { onAddAllWordEntries, onRemoveAllWordEntries } = useContext(PremadeWordTeamContext);

  if (loading) return null;

  return (
    <div
      ref={modalContentRef}
      onClick={(e) => e.stopPropagation()}
      style={{ maxHeight: "calc(100% - 10rem)" }}
    >
      {/* Instructions and full-list actions */}
      <SpreadContentRow className="border-b border-gray-200 pb-2">
        <ClickableDiv onClick={() => onAddAllWordEntries(listKey)}>
          <SmText>Add All</SmText>
        </ClickableDiv>
        <ClickableDivDanger onClick={() => onRemoveAllWordEntries(listKey)}>
          <SmText>Remove All</SmText>
        </ClickableDivDanger>
      </SpreadContentRow>

      {/* Content */}
      <div className="flex-grow overflow-auto">
        {entries.map((e, idx) => (
          <Entry key={e} text={e} listKey={listKey} />
        ))}
      </div>
    </div>
  );
}

function Entry({ text, listKey }) {
  const { premadeWordTeamDataByKey, onChangeWord } = useContext(PremadeWordTeamContext);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // we're doing this like this instead of in a single call because the response can come back as undefined
    // because of the "?." operator, and we need to coerse that into "false" in that case.
    const doesInclude = premadeWordTeamDataByKey[listKey]?.entries?.includes(text);
    setIsChecked(!!doesInclude);
  }, [premadeWordTeamDataByKey, listKey, text]);

  const handleDivClick = () => {
    onChangeWord(listKey, text, !isChecked);
  };

  const handleCheckboxClick = (e) => {
    // prevent the click from reaching the div
    e.stopPropagation();
  };

  return (
    <div
      className={classnames(
        "sm:px-2 py-2 border-b border-b-gray-200 cursor-pointer",
        "text-sm sm:text-base",
        "hover:bg-buzz-green-100"
      )}
      onClick={handleDivClick}
    >
      <input
        className="mr-3"
        type="checkbox"
        name={text}
        onChange={handleCheckboxClick}
        checked={isChecked}
      />
      {text}
    </div>
  );
}
