import React, { useEffect, useRef, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { FormHorizontalTextInputGroupRightPillThing } from "../atoms/Forms";
import { SectionHeaderText, SmDemphText, SmText } from "../atoms/Text";
import { ButtonNormal, ButtonSave } from "../atoms/Button";
import { useAuth } from "../../contexts/AuthContext";

const queryUsername = gql`
  query {
    me {
      uid
      username
      usernameFull
      playerHash
    }
  }
`;

const queryRandomizeUsername = gql`
  query {
    randomWordStrings(adjectives: 1, nouns: 1, count: 1)
  }
`;

const mutationUsername = gql`
  mutation updatePlayerUsername($username: String!) {
    updatePlayerUsername(username: $username) {
      username
      usernameFull
      uid
    }
  }
`;

const GeneralSettingsTab = () => {
  const [isDirty, setIsDirty] = useState(false);
  const { data } = useQuery(queryUsername);
  // eslint-disable-next-line no-unused-vars
  const { data: randomUserQueryData, refetch: randomRefetch } = useQuery(queryRandomizeUsername, {
    fetchPolicy: "no-cache"
  });
  const [updateUsername, { loading }] = useMutation(
    mutationUsername
    // rsm: taking this out for now, it seems like it's unnecessary as the query is being re-run anyway on save (somehow)...
    //   {
    //   refetchQueries: [queryUsername]
    // }
  );
  const [errorText, setErrorText] = useState(null);
  const [formUsername, setFormUsername] = useState("");
  const [playerHash, setPlayerHash] = useState("");
  const auth = useAuth();
  let originalUsername = useRef("");

  useEffect(() => {
    (async () => {
      const username = _get(data, "me.username", "");
      const playerHash = _get(data, "me.playerHash", "");

      setFormUsername(username);
      originalUsername.current = username;
      setPlayerHash(playerHash);
    })();
  }, [data]);

  const handleChangeUsernameValue = (val) => {
    setFormUsername(val);
    const regex = /[^a-zA-Z0-9\-_]/g;
    const match = val.match(regex);
    if (match)
      setErrorText(
        "Username should only include letters, numbers, dashes, and underscores (and no spaces). You're screwing this up."
      );
    else setErrorText(null);
    setIsDirty(true);
  };

  const handleRandomizeUsername = async () => {
    // doing it this way because useLazyQuery wasn't really working for me - it wasn't returning the data from the
    // gql result on the second randomize request, so it was operating one request behind for some reason. No idea
    // why, so I just gave up and I'm paying the hit for an unused random name request when the page loads because
    // the query fires on component load.
    const { data: localData } = await randomRefetch();
    setFormUsername(_get(localData, "randomWordStrings.0"));
    setIsDirty(true);
    setErrorText(null);
  };

  const handleCancelUsername = () => {
    setIsDirty(false);
    setErrorText(null);
    setFormUsername(originalUsername.current);
  };

  const handleSaveUsername = async () => {
    try {
      const { data: localData } = await updateUsername({
        variables: { username: formUsername.trim() }
      });
      setIsDirty(false);
      // update the user's username and all that immediately
      auth.setUsernameData({
        username: _get(localData, "updatePlayerUsername.username", null),
        usernameFull: _get(localData, "updatePlayerUsername.usernameFull", null)
      });
    } catch (err) {
      if (err) setErrorText(err.message);
    }
  };

  return (
    <div className="sm:px-4">
      <div>
        <SectionHeaderText className="text-lg leading-6 font-medium text-gray-900">
          Profile
        </SectionHeaderText>
        <p className="text-sm text-gray-500">
          User profile information changes go here. Most of this is somewhat public data, so don't
          do anything silly.
        </p>
      </div>

      <div className="mt-4 mb-2">
        <FormHorizontalTextInputGroupRightPillThing
          className="mb-2"
          label="Username"
          buttonText={`#${playerHash}`}
          value={formUsername}
          onChange={handleChangeUsernameValue}
        />
        <ButtonNormal onClick={handleRandomizeUsername} size="sm" className="w-22">
          Random
        </ButtonNormal>
      </div>

      {errorText ? <div className="text-sm text-red-500 mb-2">{errorText}</div> : null}

      <div className="mb-6">
        <p className="mb-2">
          <SmDemphText>
            This is your username that other players will see and use for invites. You'll need
            something between 3 and 26 characters in length. You can use letters, numbers, dashes,
            and underscores, nothing weirder than that, thanks.
          </SmDemphText>
        </p>

        <p className="mb-2">
          <SmDemphText>
            The random four letter extension at the end are to allow you to pick any username you
            want and still guarantee it's unique from other players. It's part of your full username
            and will need to be included when you give your username to other players for
            invitations, game invites, and dinner parties. You cannot change this four-letter
            extension for now, unfortunately. We know, it sucks.
          </SmDemphText>
        </p>

        <p className="mb-2">
          <SmText className="text-buzz-red-400">
            Changing this will take effect immediately for all your existing and previous games.
            Just sayin'.
          </SmText>
        </p>
      </div>

      {/* buttons */}
      <div className="flex justify-between">
        <div className="flex gap-2">
          <ButtonNormal onClick={handleCancelUsername} disabled={!isDirty || loading} size="sm">
            Cancel
          </ButtonNormal>
          <ButtonSave
            onClick={handleSaveUsername}
            disabled={!isDirty || loading || !!errorText}
            size="sm"
          >
            Save
          </ButtonSave>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettingsTab;
