import React from "react";

const SelectMenu = ({ label, options = [], defaultOption, onChange }) => {
  return (
    <div className="flex items-center gap-4">
      {label && label.length > 0 ? (
        <label htmlFor="location" className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <select
        id="location"
        name="location"
        className="py-1 border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 text-sm rounded-md"
        defaultValue={defaultOption}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
      >
        {options.map((o, idx) => (
          <option key={`option_${idx}`}>{o}</option>
        ))}
      </select>
    </div>
  );
};

export default SelectMenu;
