import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import classnames from "classnames";
import {
  CheckIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  LockClosedIcon
} from "@heroicons/react/20/solid";

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);
dayjs.extend(IsSameOrBefore);

const ActivityIcon = ({ type }) => {
  const baseClasses = "flex items-center w-3.5 mr-2";

  switch (type) {
    case "ENTRY_MARKED":
      return <CheckIcon className={classnames(baseClasses, "text-white bg-green-500")} />;
    case "ENTRY_UNMARKED":
      return <XMarkIcon className={classnames(baseClasses, "text-white bg-red-400")} />;
    case "PLAYER_JOINED_GAME":
      return <HandThumbUpIcon className={classnames(baseClasses, "text-white bg-green-800")} />;
    case "PLAYER_LEFT_GAME":
      return <HandThumbDownIcon className={classnames(baseClasses, "text-white bg-red-800")} />;
    case "GAME_CLOSED":
      return <LockClosedIcon className={classnames(baseClasses, "text-white bg-red-800")} />;
    default:
      return (
        <QuestionMarkCircleIcon className={classnames(baseClasses, "text-white bg-red-800")} />
      );
  }
};

const ActivityEntryWordEvent = ({ type, markedWord, username, timestamp, relativeTimestamp }) => {
  // just in case relative and current timestamp are backwards - it can happen
  const relativeTimeText = relativeTimestamp.isSameOrBefore(timestamp)
    ? dayjs(timestamp).fromNow()
    : dayjs(timestamp).from(relativeTimestamp);

  const textColorClassname =
    type === "ENTRY_MARKED" ? classnames("text-green-800") : classnames("text-red-800");

  return (
    <div className={"flex flex-col mb-4"}>
      <div className={"flex items-center"}>
        <div>
          <ActivityIcon type={type} />
        </div>
        <div className={classnames("text-sm text-green-800", textColorClassname)}>{markedWord}</div>
      </div>
      <div className={"flex content-end"}>
        <div className={"text-left w-full text-gray-500 font-light text-xs"}>
          {relativeTimeText}
        </div>
        <div className={"text-right w-full text-gray-500 font-light text-xs mr-4"}>{username}</div>
      </div>
    </div>
  );
};

const ActivityEntryPlayerJoinedEvent = ({ type, username, timestamp, relativeTimestamp }) => {
  // just in case relative and current timestamp are backwards - it can happen
  const relativeTimeText = relativeTimestamp.isSameOrBefore(timestamp)
    ? dayjs(timestamp).fromNow()
    : dayjs(timestamp).from(relativeTimestamp);

  return (
    <div className={"flex flex-col mb-4"}>
      <div className={"flex items-center"}>
        <div>
          <ActivityIcon type={type} />
        </div>
        <div className={"text-sm text-green-800"}>{username} joined game</div>
      </div>
      <div className={"flex content-end"}>
        <div className={"text-left w-full text-gray-500 font-light text-xs"}>
          {relativeTimeText}
        </div>
      </div>
    </div>
  );
};

const ActivityEntryPlayerLeftEvent = ({ type, username, timestamp, relativeTimestamp }) => {
  // just in case relative and current timestamp are backwards - it can happen
  const relativeTimeText = relativeTimestamp.isSameOrBefore(timestamp)
    ? dayjs(timestamp).fromNow()
    : dayjs(timestamp).from(relativeTimestamp);

  return (
    <div className={"flex flex-col mb-4"}>
      <div className={"flex items-center"}>
        <div>
          <ActivityIcon type={type} />
        </div>
        <div className={"text-sm text-red-800"}>{username} left game</div>
      </div>
      <div className={"flex content-end"}>
        <div className={"text-left w-full text-gray-500 font-light text-xs"}>
          {relativeTimeText}
        </div>
      </div>
    </div>
  );
};

const ActivityEntryGameClosedEvent = ({ type, timestamp, relativeTimestamp }) => {
  // just in case relative and current timestamp are backwards - it can happen
  const relativeTimeText = relativeTimestamp.isSameOrBefore(timestamp)
    ? dayjs(timestamp).fromNow()
    : dayjs(timestamp).from(relativeTimestamp);

  return (
    <div className={"flex flex-col mb-4"}>
      <div className={"flex items-center"}>
        <div>
          <ActivityIcon type={type} />
        </div>
        <div className={"text-sm text-red-800"}>Game closed</div>
      </div>
      <div className={"flex content-end"}>
        <div className={"text-left w-full text-gray-500 font-light text-xs"}>
          {relativeTimeText}
        </div>
      </div>
    </div>
  );
};

const UnknownEvent = () => {
  return <div>UNKNOWN EVENT - WTF?!?</div>;
};

const GameActivity = ({ data = [] }) => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 2500);

    // return the cleanup function
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  const activityEntriesList = data.map((e) => {
    switch (e.type) {
      case "ENTRY_MARKED":
      case "ENTRY_UNMARKED":
        return (
          <ActivityEntryWordEvent
            key={e.id}
            type={e.type}
            markedWord={e.markedWord}
            timestamp={e.timestamp}
            relativeTimestamp={currentTime}
            username={_get(e, "player.username", "(unknown user)")}
          />
        );
      case "PLAYER_JOINED_GAME":
        return (
          <ActivityEntryPlayerJoinedEvent
            key={e.id}
            type={e.type}
            timestamp={e.timestamp}
            relativeTimestamp={currentTime}
            username={_get(e, "player.username", "(unknown user)")}
          />
        );
      case "PLAYER_LEFT_GAME":
        return (
          <ActivityEntryPlayerLeftEvent
            key={e.id}
            type={e.type}
            timestamp={e.timestamp}
            relativeTimestamp={currentTime}
            username={_get(e, "player.username", "(unknown user)")}
          />
        );
      case "GAME_CLOSED":
        return (
          <ActivityEntryGameClosedEvent
            key={e.id}
            type={e.type}
            timestamp={e.timestamp}
            relativeTimestamp={currentTime}
          />
        );
      default:
        return <UnknownEvent />;
    }
  });

  return (
    <div className={"flex flex-col border bg-color-600"}>
      <div className={"text-white text-center font-medium pt-2 pb-2 bg-green-800"}>Activity</div>
      <div className={"self-center p-2 w-full"}>{activityEntriesList}</div>
    </div>
  );
};

export default GameActivity;
