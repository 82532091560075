import _get from "lodash/get";
import React, { useContext } from "react";
import { GameDataContext } from "../../../contexts/GameDataContexts";
import CardSquare from "./CardSquare";

const GameCardsView = ({ playerCard, otherCards, isReadOnly }) => (
  <>
    {/* current player's card */}
    <Card cardData={playerCard} isViewOnly={isReadOnly} />

    {/* other player cards */}
    <div className={"bg-black font-medium text-2xl text-white p-2 mt-4 mb-4"}>
      Other Player Cards
    </div>
    {otherCards.map((c) => {
      return (
        <div key={c.player.uid} className={"mb-8"}>
          <div className={"bg-blue-800 font-light text-lg text-white p-2 mb-1"}>
            {_get(c, "player.username", "(unknown username)")}
          </div>
          <Card cardData={c} isViewOnly={true} />
        </div>
      );
    })}
  </>
);

// The Card component is tightly coupled to the data structure coming back from the server as it has specific
// knowledge of the shape of the data and it's weirdness. We could probably refactor either the API itself or
// have the context provider actually shape it after it comes back from the server in a more "card ready" way
// so these components don't have to do quite so much themselves...
const Card = ({ cardData, isViewOnly = true }) => {
  const gameDataContext = useContext(GameDataContext);

  const wordArray = _get(cardData, "wordArray", []);
  const cardUsername = cardData.player.username;
  const cardUid = cardData.player.uid;

  function buildSquareComponent(word) {
    // TODO: this seems stupid to be doing this here.
    //  I probably need to move the full word marking thing into the graphql schema at this point instead of doing
    //  it on the client.
    const matchingMarkWordEntry = gameDataContext.getMarkedWordEntry(word);

    const isMarkedByCardOwner =
      matchingMarkWordEntry && cardUid && matchingMarkWordEntry.markedByUsername === cardUsername;

    const isSaving = matchingMarkWordEntry && matchingMarkWordEntry.isSaving;
    const isMarked = !!matchingMarkWordEntry;

    const handleSquareClicked = async () => {
      if (isMarked) await gameDataContext.unmarkWord(word);
      else await gameDataContext.markWord(word);
    };

    return (
      <CardSquare
        key={`square_${word}`}
        word={word}
        isViewOnly={isViewOnly}
        isMarked={isMarked}
        isSaving={isSaving}
        isMarkedByCardOwner={isMarkedByCardOwner}
        markedByUsername={matchingMarkWordEntry && matchingMarkWordEntry.markedByUsername}
        onClick={!isViewOnly ? handleSquareClicked : () => {}}
      />
    );
  }

  const cardComponents = wordArray.flatMap((row) => row.map(buildSquareComponent));
  return <div className={"grid grid-rows-5 grid-cols-5 gap-2 text-sm"}>{cardComponents}</div>;
};

export default GameCardsView;
