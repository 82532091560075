import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonNormal, ButtonSave } from "./Button";

export default function TextInputModal({
  open,
  title,
  description,
  value = "",
  placeholder = "",
  onCancel = () => {},
  onSave = () => {}
}) {
  const [nameValue, setNameValue] = useState(value);

  useEffect(() => {
    setNameValue(value);
  }, [value]);

  const handleSave = (e) => {
    e.preventDefault();
    onSave(nameValue);
    setNameValue("");
  };

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onCancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
                    <div className="my-2 max-w-xl text-sm text-gray-500">
                      <p>{description}</p>
                    </div>
                    <form onSubmit={handleSave}>
                      <div className="flex">
                        <div className="mx-2 w-full sm:max-w-xs">
                          <input
                            className="p-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder={placeholder}
                            value={nameValue}
                            onChange={(e) => setNameValue(e.target.value)}
                            onFocus={(e) => e.target.select()}
                          />
                        </div>
                        <ButtonNormal type="button" onClick={onCancel} className="mr-2">
                          Cancel
                        </ButtonNormal>
                        <ButtonSave className="mr-2" type="submit">
                          Save
                        </ButtonSave>
                      </div>
                    </form>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
