import React, { useState } from "react";
import { SmDemphText, SmText } from "../atoms/Text";
import { CloseIcon } from "../atoms/IconsOutline";
import TextInputModal from "../atoms/TextInputModal";
import { TitleContainerWithAction } from "../atoms/Containers";

export default function WordLists({ customWordListArray, onAddList, onDeleteList }) {
  const [showAddListModal, setShowAddListModal] = useState(false);

  const handleClickAddList = () => {
    setShowAddListModal(true);
  };

  const handleAddListSave = (listcode) => {
    if (!listcode || listcode.trim().length === 0) return;

    // dedupe the new list. we don't worry about dupes with the original props list, we'll let the parent handle that
    onAddList(listcode.trim());
    setShowAddListModal(false);
  };

  const handleAddListCancel = () => {
    setShowAddListModal(false);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-12 sm:gap-6 gap-4">
      <div className="sm:col-span-10">
        {/* Tab One */}
        <div className="">
          <TitleContainerWithAction
            title="Custom Word Lists"
            actionText="Add List"
            onClickAction={handleClickAddList}
          >
            <div className="flex flex-col gap-2">
              {customWordListArray && customWordListArray.length > 0 ? (
                customWordListArray.map((l) => (
                  <WordListEntry
                    key={l.listCode}
                    {...l}
                    onClickDelete={() => {
                      // filter out the word list that was just deleted
                      onDeleteList(l.listCode);
                    }}
                  />
                ))
              ) : (
                <SmDemphText>(none)</SmDemphText>
              )}
            </div>
            <TextInputModal
              open={showAddListModal}
              title="List Codes"
              value=""
              description="Add a single custom word list code"
              onCancel={handleAddListCancel}
              onSave={handleAddListSave}
            />
          </TitleContainerWithAction>
        </div>
      </div>
    </div>
  );
}

function WordListEntry({ name, listCode, valid, onClickDelete = () => {} }) {
  return (
    <div className="flex gap-2 items-center justify-between">
      <SmText className={valid ? "" : "text-red-500"}>{name || "(invalid list code)"}</SmText>
      <div className={"flex gap-2 items-center"}>
        <SmDemphText className={valid ? "" : "text-red-500"}>{listCode}</SmDemphText>
        <div className="cursor-pointer" onClick={onClickDelete}>
          <CloseIcon className="text-red-700" width={4} />
        </div>
      </div>
    </div>
  );
}
