import React, { useContext } from "react";
import fuzzysort from "fuzzysort";
import classnames from "classnames";
import {
  WordFilterContext,
  GameDataContext,
  ShowOnlyUnmarkedWordsContext
} from "../../../contexts/GameDataContexts";
import ListCardItem from "./ListCardItem";

const ListCards = ({ wordArray, markedWords, isViewOnly, cardUsername, cardUid }) => {
  // TODO: consider changing the game queries to return a flat array with dimensions rather than a 2-dim array
  // this would make it easier for the client to decide how to display the data rather than the server having some
  // opinion about the matter, which it probably shouldn't

  const gameDataContext = useContext(GameDataContext);
  const filterValue = useContext(WordFilterContext);
  const showOnlyUnmarkedWords = useContext(ShowOnlyUnmarkedWordsContext);

  // assemble a list of marked words indexed by the word itself. This is sloppy but since words don't have unique
  // id's for now, this is the best way to uniquely identify them for fast lookup (and avoid a set of O^2 operations
  // to get all the data needed for that). TODO: move this into a data provider so we don't have multiple impls
  const markedWordsIndexByWord = markedWords.reduce((acc, w) => {
    acc[w.text] = w;
    return acc;
  }, {});

  // reassemble the array into a flat, single dimensional array
  const flatWordArray = wordArray.reduce((accum, row) => {
    row.map((w) => {
      // also filter out words based on whatever filters are enabled.
      let isWordFilteredOut = false;

      // word filter
      if (
        filterValue &&
        filterValue.length > 0 &&
        // word.toLowerCase().replaceAll(' ', '').indexOf(filterValue.replaceAll(' ', '').toLowerCase()) === -1
        fuzzysort.go(filterValue, [w], { allowTypo: false, threshold: -500 }).total < 1
      ) {
        isWordFilteredOut = true;
      }

      // only marked words filter
      if (!isWordFilteredOut && showOnlyUnmarkedWords) {
        // check to see if the word is in the marked words list. If so, filter it out
        isWordFilteredOut = !!markedWordsIndexByWord[w];
      }

      if (!isWordFilteredOut) accum.push(w);
      return accum;
    });
    return accum;
  }, []);

  const handleChanged = async (word, isMarked) => {
    if (isMarked) await gameDataContext.markWord(word);
    else await gameDataContext.unmarkWord(word);
  };

  return (
    <div
      className={classnames(
        "flex flex-col align-middle",
        "divide-y divide-gray-400 divide-dashed",
        "border border-green-600 rounded-xl p-1"
      )}
    >
      {flatWordArray.map((w, idx) => {
        const markedEntry = markedWordsIndexByWord[w];
        const isMarked = !!markedEntry;
        const isReadOnly = isViewOnly || (markedEntry && markedEntry.markedByUid !== cardUid);
        const isMarkedByOwner =
          markedEntry && cardUid && markedEntry.markedByUsername === cardUsername;
        const markedByUsername = markedEntry && markedEntry.markedByUsername;

        return (
          <div
            className="text-gray-700 hover:bg-green-100 hover:text-green-900"
            key={`word_${cardUid}_${w}`}
          >
            <ListCardItem
              word={w}
              isMarked={isMarked}
              isMarkedByOwner={isMarkedByOwner}
              markedByUsername={markedByUsername}
              isReadOnly={isReadOnly}
              onChanged={(isChecked) => handleChanged(w, isChecked)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ListCards;
