import React from "react";
import classnames from "classnames";
import { SmDemphText } from "../../atoms/Text";

export default function DangerZone({ onDeleteList }) {
  return (
    <div className={classnames("flex flex-col")}>
      {/* Danger Zone Top */}
      <div
        className={classnames(
          "text-buzz-red-800 bg-buzz-red-100 font-bold",
          "border-t border-l border-r border-red-800 rounded-tl-xl rounded-tr-xl",
          "px-4 p-2"
        )}
      >
        Serious Zone:
      </div>
      {/* Danger Zone Bottom */}
      <div
        className={classnames(
          "border-red-800 border-l border-r border-b",
          "rounded-bl-xl rounded-br-xl",
          "px-4",
          "flex flex-col divide-y divide-gray-200"
        )}
      >
        {/* Delete Team */}
        <div
          className={classnames(
            "flex gap-2 justify-between items-center flex-wrap sm:flex-nowrap",
            "py-2"
          )}
        >
          <div>
            <div className={"font-semibold"}>Delete this list.</div>
            <div>
              <SmDemphText>
                Do you want to completely delete this custom word list? Do it here. This is
                <span className="italic font-semibold">&nbsp;not</span> undoable, so don't screw it
                up!
              </SmDemphText>
            </div>
          </div>
          <div>
            <button
              className={classnames(
                "border border-gray-300 bg-gray-100 rounded-md",
                "hover:bg-buzz-red-700 hover:text-white",
                "active:bg-buzz-red-800 active:text-white",
                "px-4 py-2 max-w-xl min-w-[120px]",
                "text-xs sm:text-sm text-buzz-red-700 font-semibold"
              )}
              onClick={onDeleteList}
            >
              Delete List
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
