import React, { useEffect, useState } from "react";

const MultipleWordEntry = ({ words, onUpdate }) => {
  const [text, setText] = useState(words.join("\n"));

  useEffect(() => {
    onUpdate(text.split("\n").filter((w) => w.trim().length > 0));
  }, [text, onUpdate]);

  const handleUpdateText = (e) => {
    setText(e.target.value);
  };

  return (
    <div className={"flex flex-col gap-4"}>
      <div className="flex flex-col gap-2 text-sm">
        <p>
          You can manage the entire list from this typing area. Add, change, and remove whatever
          you'd like. You'll see the final list off to the side as you type. Any duplicate entries
          are not included in the final list and will be removed from the typing area when you
          navigate away or save the custom list.
        </p>
        <p>
          If you want to duplicate this list or save this list somewhere else, you can just
          copy/paste this entire list here.
        </p>
      </div>
      <textarea
        className={"flex-grow"}
        rows={Math.max(words.length + 2, 8)}
        value={text}
        onChange={handleUpdateText}
      />
    </div>
  );
};

export default MultipleWordEntry;
