import React, { useEffect, useState } from "react";
import * as Lockr from "lockr";

const UserLocalPrefsContext = React.createContext({});

function UserLocalPrefsProvider(props) {
  const [prefData, setPrefData] = useState(getPrefsFromStorage());

  // initialize user prefs if they're not already stored
  useEffect(() => {
    const prefs = getAllPrefs();
    if (!prefs) initPrefs();
  }, []);

  const getAllPrefs = () => getPrefsFromStorage();
  const getPref = (key) => getPrefFromStorage(key);
  const setPref = (key, value) => setPrefData(setPrefInStorage(key, value));

  return (
    <UserLocalPrefsContext.Provider
      value={{ getAllPrefs, getPref, setPref, prefData }}
      {...props}
    />
  );
}

//
// INTERNAL
//
const prefsKey = "userPrefs";

const getPrefsFromStorage = () => {
  return Lockr.get(prefsKey);
};

const getPrefFromStorage = (k) => {
  const prefs = getPrefsFromStorage();
  if (!prefs) return;
  return prefs[k];
};

const setPrefInStorage = (k, v) => {
  let prefs = getPrefsFromStorage();
  if (!prefs) prefs = {};
  prefs[k] = v;
  Lockr.set(prefsKey, prefs);
  return prefs;
};

const initPrefs = () => {
  const prefs = {};
  Lockr.set(prefsKey, prefs);
  return prefs;
};

export { UserLocalPrefsContext, UserLocalPrefsProvider };
