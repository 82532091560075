import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

//
// AUTH CONTEXT
//
const AuthContext = React.createContext();

function AuthProvider(props) {
  const [data, setData] = useState({
    username: getUsernameFromCookie(),
    usernameFull: getUsernameFullFromCookie(),
    playerHash: getPlayerHashFromCookie(),
    pictureUrl: getPictureUrlFromCookie(),
    roles: getPlayerRolesFromCookie(),
    token: getTokenFromCookie(),
    uid: getUidFromCookie()
  });

  useEffect(() => {}, []);

  const logout = () => {
    clearAuthenticationData();
    setData(getEmptyUserDataObject());
  };

  const isAuthenticated = () => {
    const token = getTokenFromCookie();
    return !!token;
  };

  const setUsernameData = ({ username, usernameFull }) => {
    setUsernameCookie(username);
    setUsernameFullCookie(usernameFull);
    setData({ ...data, username, usernameFull });
  };

  return (
    <AuthContext.Provider value={{ logout, isAuthenticated, setUsernameData, data }} {...props} />
  );
}

const useAuth = () => React.useContext(AuthContext);

//
// USER CONTEXT
//
const UserContext = React.createContext();
const UserProvider = (props) => {
  const getUserToken = () => {
    return getTokenFromCookie();
  };

  return (
    <UserContext.Provider
      value={{
        uid: useAuth().data.uid,
        username: useAuth().data.username,
        playerHash: useAuth().data.playerHash,
        usernameFull: useAuth().data.usernameFull,
        token: useAuth().data.token,
        pictureUrl: useAuth().data.pictureUrl,
        roles: useAuth().data.roles,
        getUserToken
      }}
      {...props}
    />
  );
};
const useUser = () => React.useContext(UserContext);

//
// UTIL METHODS
//
function getUidFromCookie() {
  return Cookies.get("uid");
}

function getUsernameFromCookie() {
  return Cookies.get("username");
}

function setUsernameCookie(username) {
  Cookies.set("username", username);
}

function getPlayerHashFromCookie() {
  return Cookies.get("playerHash");
}

function getUsernameFullFromCookie() {
  return Cookies.get("usernameFull");
}

function setUsernameFullCookie(usernameFull) {
  Cookies.set("usernameFull", usernameFull);
}

function getPictureUrlFromCookie() {
  return Cookies.get("pictureUrl");
}

function getPlayerRolesFromCookie() {
  // data stored as a stringified string array, so we marshall it back to that
  const data = Cookies.get("roles");
  if (data) return JSON.parse(data);
  else return null;
}

function getEmptyUserDataObject() {
  return {
    username: null,
    usernameFull: null,
    playerHash: null,
    roles: null,
    token: null,
    uid: null
  };
}

function getTokenFromCookie() {
  return Cookies.get("token");
}

function getUserToken() {
  return getTokenFromCookie();
}

function clearAuthenticationData() {
  Cookies.remove("token");
  Cookies.remove("username");
  Cookies.remove("playerHash");
  Cookies.remove("usernameFull");
  Cookies.remove("pictureUrl");
  Cookies.remove("roles");
  Cookies.remove("uid");
}

export { AuthProvider, AuthContext, UserProvider, UserContext, useAuth, useUser, getUserToken };
