import React from "react";
import { Link, useLocation } from "wouter";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import ProfileButton from "./ProfileButton";
import classnames from "classnames";
import { LogoSmall } from "../atoms/Logo";

const Header = () => {
  return (
    <>
      <Disclosure as="div" className={`bg-buzz-green-900 z-100`}>
        <div className="flex items-center justify-between h-14 px-4">
          <div className="flex gap-2">
            {/* Logo */}
            <div className="shrink-0 w-[40px]">
              <a href="https://buzzworded.com">
                <LogoSmall />
              </a>
            </div>

            {/* disclosure button */}
            <div className={`sm:hidden`}>
              <Disclosure.Button className="p-3">
                <Bars3Icon className={`text-white h-6 w-6`} aria-hidden="true" />
              </Disclosure.Button>
            </div>
          </div>

          <FullSizeHeaderContent />

          {/* profile menu */}
          <div className="ml-3">
            <ProfileButton />
          </div>
        </div>

        <DisclosureMenuContent />
      </Disclosure>
    </>
  );
};

const leftLinks = [
  { name: "Home", href: "/" },
  { name: "Word Lists", href: "/wordlists" }
];

const rightLinks = [{ name: "Teams", href: "/teams" }];

const FullSizeHeaderContent = () => {
  const [location] = useLocation();
  const linkClasses = "p-2 mr-2 rounded-md";
  const hoverClasses = "hover:text-buzz-green-100 hover:bg-green-600";
  const currentClasses = "bg-buzz-green-950";

  return (
    <div className={`hidden sm:flex justify-between flex-grow pl-4 text-green-300`}>
      {/* left aligned */}
      <div className={"flex justify-between items-center"}>
        {leftLinks.map((l) => (
          <Link
            key={l.href}
            className={classnames(linkClasses, location === l.href ? currentClasses : hoverClasses)}
            href={l.href}
          >
            {l.name}
          </Link>
        ))}
      </div>

      {/* right aligned */}
      <div className={"flex justify-between items-center space-x-2.5"}>
        {rightLinks.map((l) => (
          <Link
            key={l.href}
            className={classnames(
              linkClasses,
              "rounded-md",
              location === l.href ? currentClasses : hoverClasses
            )}
            href={l.href}
          >
            {l.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

const DisclosureMenuContent = () => {
  return (
    <Disclosure.Panel
      as="div"
      className={`z-100 sm:hidden flex flex-col p-4 gap-4 bg-buzz-green-900 text-white border-t border-gray-800`}
    >
      <Disclosure.Button as={Link} href="/" className="hover:text-green-50 hover:bg-green-500">
        Home
      </Disclosure.Button>
      <Disclosure.Button
        as={Link}
        href="/wordlists"
        className="hover:text-green-50 hover:bg-green-500"
      >
        Custom Word Lists
      </Disclosure.Button>
      <Disclosure.Button as={Link} href="/teams" className="hover:text-green-50 hover:bg-green-500">
        Teams
      </Disclosure.Button>
    </Disclosure.Panel>
  );
};

export default Header;
