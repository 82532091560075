import React from "react";

export default function GameListEntrySkeleton() {
  return (
    <div
      className={
        "flex flex-col w-full animate-pulse border border-green-600 px-3 py-2 bg-green-500 bg-opacity-5"
      }
    >
      <div className="flex gap-4">
        <div className="h-4 w-5/6 bg-slate-200 rounded" />

        <div className={"w-1/6 text-right"}>
          <div className="flex flex-col">
            <div className={"h-4 bg-slate-200 rounded"} />
            <div className={"h-4 bg-slate-200 rounded"} />
            <div className={"h-4 bg-slate-200 rounded"} />
          </div>
        </div>
      </div>
    </div>
  );
}
