import React, { useEffect, useState } from "react";
import { CancelIcon, EditIcon, SaveIcon } from "./IconsOutline";

export default function EditableHeaderText({ text, onChange, editButtonTitle }) {
  const [internalText, setInternalText] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  // in case we cancel the edit
  const originalText = text;

  useEffect(() => {
    setInternalText(text);
  }, [text]);

  const handleClickEdit = () => {
    setIsEditing(true);
  };

  const handleOnSave = () => {
    onChange(internalText);
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setInternalText(originalText);
    setIsEditing(false);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents a new line or any default action
      handleOnSave();
    } else if (e.key === "Escape") {
      e.preventDefault(); // Prevents a new line or any default action
      handleCancelEdit();
    }
  };

  return (
    <div className="flex flex-wrap gap-2 items-center justify-between">
      <div className="flex gap-4 items-center grow">
        {isEditing ? (
          <>
            <div className="w-full">
              <input
                className="text-2xl text-gray-900 bg-transparent border-none outline-none w-full ring"
                type="text"
                value={internalText}
                onChange={(e) => setInternalText(e.target.value)}
                onKeyDown={handleKeyDown}
                autoFocus
              />
            </div>
            <div className="flex items-center gap-3">
              <div onClick={handleCancelEdit}>
                <CancelIcon width={8} />
              </div>
              <div onClick={handleOnSave}>
                <SaveIcon width={8} />
              </div>
            </div>
          </>
        ) : (
          <div className="flex gap-4 items-center">
            <div className="text-2xl text-gray-900">{internalText}</div>
            <div>
              <EditIcon width={5} onClick={handleClickEdit} title={editButtonTitle} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
