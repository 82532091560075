import React, { useContext, useState } from "react";
import { WordFilterContext } from "../../../contexts/GameDataContexts";
import fuzzysort from "fuzzysort";
import classnames from "classnames";
import { BBClockIcon } from "../../atoms/IconsOutline";
import BuzzwordTooltip from "../components/BuzzwordTooltip";
import { UsersIcon } from "@heroicons/react/20/solid";
import { RightContentRow } from "../../atoms/LayoutComponents";
import { CloseIcon } from "../../atoms/IconsSolid";

const CardSquare = ({
  word,
  isMarked,
  isMarkedByCardOwner,
  markedByUsername,
  isSaving,
  isViewOnly,
  onClick
}) => {
  const filterValue = useContext(WordFilterContext);
  const [isHovering, setIsHovering] = useState(false);

  // Manage the filter term to filter out this square's word if needed
  const isWordFilteredOut =
    filterValue &&
    filterValue.length > 0 &&
    fuzzysort.go(filterValue, [word], { allowTypo: false, threshold: -500 }).total < 1;

  const squareClasses = classnames(
    isMarked && "text-white bg-green-900",
    isViewOnly || isMarked
      ? "cursor-not-allowed"
      : "sm:hover:bg-green-600 sm:hover:text-white cursor-pointer"
  );

  let TopRightIcon = null;
  if (isSaving) {
    TopRightIcon = <BBClockIcon className={`text-red-100 m-1 w-3.5`} />;
  } else if (isMarked && !isMarkedByCardOwner) {
    TopRightIcon = (
      <BuzzwordTooltip text={`marked by ${markedByUsername || "(who knows)"}`}>
        <UsersIcon className={"text-white m-1 w-3.5"} />
      </BuzzwordTooltip>
    );
  }

  return (
    <div
      className={classnames(
        squareClasses,
        "flex flex-col justify-between border border-green-500 text-xs sm:text-sm"
      )}
      onClick={!isMarked && !isViewOnly ? onClick : undefined}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/*Top Right Icon*/}
      <div className={"self-end h-4"}>
        {isMarked && !isMarkedByCardOwner ? <span>{TopRightIcon}</span> : null}
      </div>
      {/*Word text*/}
      <div
        style={{ maxHeight: "120px" }}
        className={classnames(
          "text-center p-2 overflow-y-auto",
          isWordFilteredOut ? "invisible" : null
        )}
      >
        {word}
      </div>
      {/*Bottom row*/}
      <RightContentRow className="h-4">
        {!isViewOnly && isHovering && isMarkedByCardOwner ? (
          <div
            className="bg-white cursor-pointer"
            onClick={!isViewOnly && isMarkedByCardOwner ? onClick : undefined}
          >
            <CloseIcon className="h-4 text-red-700 hover:text-red-900" />
          </div>
        ) : null}
      </RightContentRow>
    </div>
  );
};

export default CardSquare;
