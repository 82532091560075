import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { InputActiveContext } from "./ContextInputActive";

const WordFilter = ({ onChange = () => {}, onFocus = () => {}, onBlur = () => {} }) => {
  const [filter, setFilter] = useState("");
  const [, setIsFilterFocused] = useState(false);
  const inputActiveContext = useContext(InputActiveContext);
  const inputRef = useRef();

  //
  // handlers
  //
  // eslint-disable-next-line
  const handleChange = (value) => {
    setFilter(value);
    onChange(value);
  };

  const handleMouseFocus = () => {
    setIsFilterFocused(true);
    inputRef.current.select();
    inputActiveContext.setInputActive(true);
    onFocus();
  };

  const handleMouseBlur = () => {
    setIsFilterFocused(false);
    inputActiveContext.setInputActive(false);
    onBlur();
  };

  //
  // key handlers
  //
  const handleKeyEscape = useCallback(
    (e) => {
      if (isFilterInputFocused()) {
        e.preventDefault();
        handleChange("");
        inputRef.current.blur();
        setIsFilterFocused(false);
        inputActiveContext.setInputActive(false);
        onBlur();
      }
    },
    [handleChange, setIsFilterFocused, inputActiveContext, onBlur]
  );

  const handleKeyEnter = useCallback(
    (e) => {
      if (isFilterInputFocused()) {
        e.preventDefault();
        inputRef.current.blur();
        setIsFilterFocused(false);
        inputActiveContext.setInputActive(false);
        onBlur();
      }
    },
    [setIsFilterFocused, inputActiveContext, onBlur]
  );

  const handleKeySlash = useCallback(
    (e) => {
      if (!isFilterInputFocused()) {
        e.preventDefault();
        inputRef.current.focus();
        inputRef.current.select();
        setIsFilterFocused(true);
        inputActiveContext.setInputActive(true);
        onFocus();
      }
    },
    [setIsFilterFocused, inputActiveContext, onFocus]
  );

  // eslint-disable-next-line
  const keyPressHandler = (e) => {
    const key = e.key;
    const keyCode = e.keyCode;
    if (key === "/") {
      handleKeySlash(e);
    } else if (keyCode === 27) {
      handleKeyEscape(e);
    } else if (keyCode === 13) {
      handleKeyEnter(e);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);
    return () => {
      // remove this listener and the other listener as well, just in case. we don't want event listener leaks
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [keyPressHandler]);

  const isFilterInputFocused = () => document.activeElement === inputRef.current;

  return (
    <div className="rounded-md shadow-sm">
      {/* we include focus and blur events here to handle mouse-driven events properly. keyboard events
                      handled above */}
      <input
        ref={inputRef}
        type="text"
        className="focus:ring-green-500 focus:border-green-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleMouseFocus}
        onBlur={handleMouseBlur}
        value={filter}
      />
      {!isFilterInputFocused() && filter.length === 0 ? (
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <div className="text-gray-500 text-xs">
            <span className=" px-1 border border-gray-400 rounded">/</span> to filter
          </div>
        </div>
      ) : null}
      {filter && filter.length > 0 ? (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
          <XCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
            onClick={() => handleChange("")}
          />
        </div>
      ) : null}
    </div>
  );
};

export default WordFilter;
