import React, { useState, useEffect, useContext } from "react";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import classnames from "classnames";
import { ChevronDoubleLeftIcon, ArrowPathIcon } from "@heroicons/react/20/solid";
import { useUser } from "../../contexts/AuthContext";
import { PageBaseFullWidthContainer } from "../atoms/Pages";
import SlidingDrawerThing from "./components/SlidingDrawerThing";
import WordFilter from "./components/WordFilter";
import GameRightColumn from "./GameRightColumn";
import { WordFilterContext, GameDataContext } from "../../contexts/GameDataContexts";
import GameCards from "./GameCard";
import GameListView from "./GameCardList";

dayjs.extend(relativeTime);

const Game = () => {
  // contexts
  const gameDataContext = useContext(GameDataContext);
  const { uid } = useUser();

  // state
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [isListViewEnabled] = useState(false);
  const [relativeTime, setRelativeTime] = useState("");

  // initialize view based on prefs
  // TODO: re-enable when list view toggle is put back in (issue BB-143)
  /*
  useEffect(() => {
    setListViewEnabled(userPrefs.getPref(listViewPrefKey));
  }, [userPrefs]);
*/

  // relative query time
  useEffect(() => {
    let timeoutId;
    const timeoutFunc = () => {
      setRelativeTime(dayjs().to(gameDataContext.queryTime));
      timeoutId = setTimeout(timeoutFunc, 2000);
    };

    setRelativeTime(dayjs().to(gameDataContext.queryTime));
    timeoutId = setTimeout(timeoutFunc, 2000);

    return () => clearTimeout(timeoutId);
  }, [gameDataContext, relativeTime]);

  //
  // handle methods
  //
  // TODO: re-enable when list view toggle is put back in (issue BB-143)
  /*
  const handleKeyV = useCallback(
    (e) => {
      if (!inputActiveContext.isInputActive) {
        e.preventDefault();
        handleListViewToggle(!isListViewEnabled);
      }
    },
    [inputActiveContext.isInputActive, isListViewEnabled, handleListViewToggle]
  );
  */

  const handleManualRefresh = async () => {
    await gameDataContext.refetch();
  };

  // eslint-disable-next-line
  const keyPressHandler = (e) => {
    // TODO: re-enable when list view toggle is put back in (issue BB-143)
    // const key = e.key;
    // if (key === "v") {
    //   handleKeyV(e);
    // }
  };

  // eslint-disable-next-line
  // TODO: re-enable when list view toggle is put back in (issue BB-143)
  // function handleListViewToggle(toggled) {
  //   // update state and update prefs as well
  //   setListViewEnabled(toggled);
  //   userPrefs.setPref(listViewPrefKey, toggled);
  // }

  //
  // key listeners
  //
  // TODO: get the key shortcut working again
  useEffect(() => {
    document.addEventListener("keydown", keyPressHandler);
    return () => {
      // remove this listener and the other listener as well, just in case. we don't want event listener leaks
      document.removeEventListener("keydown", keyPressHandler);
    };
  }, [keyPressHandler]);

  if (gameDataContext.loading) return null;
  else {
    if (!gameDataContext.data) {
      console.warn("loading is not true, but data is null, that sucks");
      return null;
    }

    // assemble some base data, bail early if the game or game cards data structures are empty
    // TODO: refactor these lower in the component hierarchy
    const gameData = _get(gameDataContext.data, "game", {});
    const cardsBaseData = _get(gameData, "gameCards", []);
    if (_isEmpty(gameData) || _isEmpty(cardsBaseData)) return null; // early exit
    const { name, isClosed, scores: gameScores } = gameData;

    // parse out the user's card and the other users' cards
    let myCard;
    const otherPlayersCards = [];
    cardsBaseData.forEach((c) => {
      if (_get(c, "player.uid") === uid) myCard = c;
      else otherPlayersCards.push(c);
    });

    return (
      <WordFilterContext.Provider value={filter}>
        <PageBaseFullWidthContainer>
          <div className="flex gap-4">
            {/* left column */}
            <div className={"flex flex-col flex-grow min-w-3/4"}>
              {/* title and game code */}
              <div className={"flex flex-col"}>
                <div className={"text-3xl font-medium"}>{name}</div>

                {isClosed ? (
                  <div className={"bg-red-800 text-white text-lg p-2"}>Game Closed</div>
                ) : null}
              </div>

              {/* header row / controls */}
              <div className="flex justify-end items-center pt-2 mb-2">
                {/*
               TODO: remove "justify-end" from the parent div when you put the list view toggle back in
                <div className="flex-grow">
                  <RightLabelToggle
                    label="List View"
                    secondaryText="(v)"
                    enabled={isListViewEnabled}
                    onChange={handleListViewToggle}
                  />
                </div>
*/}

                {/* refresh */}
                {gameDataContext.isPolling === true ? (
                  <div className="flex mr-2 hover:cursor-pointer" onClick={handleManualRefresh}>
                    <div className="w-4 mr-1 text-gray-500">
                      <ArrowPathIcon />
                    </div>
                    <div className="mr-2 font-light text-xs ">{relativeTime}</div>
                  </div>
                ) : null}

                {/* word filter */}
                <div className="justify-self-end relative">
                  <WordFilter onChange={setFilter} />
                </div>
              </div>

              {/* Actual game / word content */}
              <div>
                {!isListViewEnabled ? (
                  <GameCards
                    playerCard={myCard}
                    otherCards={otherPlayersCards}
                    isReadOnly={isClosed}
                  />
                ) : (
                  <GameListView
                    playerCard={myCard}
                    otherCards={otherPlayersCards}
                    isReadOnly={isClosed}
                  />
                )}
              </div>
            </div>

            {/* right column on-screen */}
            <div className={"hidden lg:block top-4 h-screen min-w-1/4 flex-grow"}>
              <GameRightColumn gameScores={gameScores} gameActivity={_get(gameData, "activity")} />
            </div>

            {/* sliding drawer for scores and activity (smaller screens) */}
            {!open ? (
              <div
                className={classnames(
                  "lg:hidden absolute right-0 cursor-pointer",
                  "rounded-l-lg border-t-2 border-l-2 border-b-2 border-green-500",
                  "bg-green-900 hover:bg-green-500 text-white"
                )}
                onClick={() => setOpen(true)}
              >
                <ChevronDoubleLeftIcon className={`${open ? "transform rotate-180" : ""} w-6`} />
              </div>
            ) : null}

            <SlidingDrawerThing open={open} setOpen={setOpen}>
              <GameRightColumn gameScores={gameScores} gameActivity={_get(gameData, "activity")} />
            </SlidingDrawerThing>
          </div>
        </PageBaseFullWidthContainer>
      </WordFilterContext.Provider>
    );
  }
};

export default Game;
