import React, { useCallback, useState } from "react";
import SingleWordEntry from "./SingleWordEntry";
import MultipleWordEntry from "./MultipleWordEntry";
import GptCustomWords from "./GptCustomWords";
import { TabContentDisplay, TabsVertical } from "../../../atoms/TabsMenu";

export default function ManageEntries({ words, onChangeWords }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleNewWordSave = (word) => {
    if (!word || word.trim() === "") return;
    const wordSet = new Set(words);
    wordSet.add(word.trim());
    onChangeWords(Array.from(wordSet));
  };

  // useCallback? really? <sigh> just because the child uses a useEffect call that includes this function in the end,
  // I have to basically wrap it up here to keep an endless re-render loop from triggering. <sigh> React.
  const handleBulkWordUpdate = useCallback(
    (wordArray) => {
      // this seems wildly inefficient creating a new set for every single fucking character that comes through, but
      // what the hell. Until it doesn't work, this appears to be working okay. I can figure it out later if it really
      // becomes a problem. Probably need to test with a ton of words and see what happens, honestly...
      const newSet = new Set();
      wordArray.forEach((w) => newSet.add(w.trim()));
      onChangeWords(Array.from(newSet));
    },
    [onChangeWords]
  );

  // TODO: my guess is this method and the handleBulkWordUpdate method could be the exact same
  const handleBulkWordAdd = useCallback(
    (wordArray) => {
      const newSet = new Set(words.concat(wordArray.map((w) => w.trim())));
      onChangeWords(Array.from(newSet));
    },
    [words, onChangeWords]
  );

  return (
    <>
      <div className={"grid sm:grid-cols-12 gap-2.5"}>
        <div className="sm:col-span-2">
          <TabsVertical onChangeTab={setActiveTab}>
            <TabsVertical.TabPlain text="Add One" />
            <TabsVertical.TabPlain text="Manage All" />
            <TabsVertical.TabPlain text="✨ Magic ✨" />
          </TabsVertical>
        </div>
        <div className="sm:col-span-10">
          {/*  Content */}
          <TabContentDisplay activeIndex={activeTab}>
            <SingleWordEntry onSave={handleNewWordSave} />
            <MultipleWordEntry words={words} onUpdate={handleBulkWordUpdate} />
            <GptCustomWords onAddToList={handleBulkWordAdd} />
          </TabContentDisplay>
        </div>
      </div>
    </>
  );
}
