import React from "react";
import { MedalFirstPlace, MedalSecondPlace, MedalThirdPlace } from "../../atoms/Emojis";

const GameScores = ({ scores = [] }) => {
  return (
    <div>
      <div className={"font-medium bg-green-800 text-white text-center pt-0.5 pb-0.5"}>Scores</div>
      <div className={"pt-1 pb-1 pl-4 pr-4 text-sm font-medium text-gray-700"}>
        {scores.map((s, idx) => (
          <div key={`user_${s.uid}`} className={"flex justify-items-stretch gap-2"}>
            <div className="flex-grow">
              {s.username}
              <span className="ml-2 text-sm">
                {s.total === 0 ? null : idx === 0 ? (
                  <MedalFirstPlace />
                ) : idx === 1 ? (
                  <MedalSecondPlace />
                ) : idx === 2 ? (
                  <MedalThirdPlace />
                ) : null}
              </span>
            </div>
            <div className="justify-self-end">{s.total}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameScores;
