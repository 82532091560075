// libs
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import _get from "lodash/get";
// contexts
import { useUser } from "../../contexts/AuthContext";
// atoms
import { PageBaseInsetContainer } from "../atoms/Pages";
import TextInputModal from "../atoms/TextInputModal";
import ModalConfirmDanger from "../atoms/ModalConfirmDanger";
import { Heading1 } from "../atoms/Heading";
import SelectMenu from "../atoms/SelectMenu";
import { SmDemphText, SmText } from "../atoms/Text";
import { ClickableDiv } from "../atoms/Link";
import ErrorNotification from "../atoms/ErrorNotification";
// locals
import * as teamQueries from "./teamQueries";
import TeamEntry from "./TeamEntry";

const Teams = () => {
  // state
  const [teamFilter, setTeamFilter] = useState("All Teams");
  const [teamList, setTeamList] = useState([]);

  // user information
  const roles = useUser().roles;
  const isPowerUser = roles && roles.includes("POWER");

  // queries and mutations
  const { data, loading, refetch } = useQuery(teamQueries.GET_MY_TEAMS, {
    fetchPolicy: "network-only"
  });
  const refetchQueriesObj = { refetchQueries: [{ query: teamQueries.GET_MY_TEAMS }] };
  const [createTeam] = useMutation(teamQueries.CREATE_TEAM, refetchQueriesObj);
  const [leaveTeam] = useMutation(teamQueries.LEAVE_TEAM, refetchQueriesObj);
  const [joinTeam] = useMutation(teamQueries.JOIN_TEAM, refetchQueriesObj);

  // create team
  const isTeamLimitReached = teamList.filter((t) => t.teamType === "owned").length >= 3;
  const [showCreateTeam, setShowCreateTeam] = useState(false);

  // leave team
  const defaultLeaveTeamCtx = { id: null };
  const [leaveTeamCtx, setLeaveTeamCtx] = useState(defaultLeaveTeamCtx);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

  // join team
  const [showJoinTeam, setShowJoinTeam] = useState(false);

  // create team game
  const [createTeamGame] = useMutation(teamQueries.CREATE_TEAM_GAME, refetchQueriesObj);

  // error data
  const defaultErrorArray = [];
  const [errorArray, setErrorArray] = useState(defaultErrorArray);

  // put together the team list based on the selection filter. decorate each team as either "joined" or "owned" so
  // we can manage them more easily when rendering
  useEffect(() => {
    // make the team data a little easier to get to
    const joinedTeams = _get(data, "joinedTeams", []);
    const ownedTeams = _get(data, "ownedTeams", []);

    if (teamFilter === "Joined Teams") {
      // joined teams
      setTeamList(joinedTeams.map((t) => ({ teamType: "joined", ...t })));
    } else if (teamFilter === "My Teams") {
      // my teams
      setTeamList(ownedTeams.map((t) => ({ teamType: "owned", ...t })));
    } else {
      // all teams
      const tempTeamList = []
        .concat(
          joinedTeams.map((t) => ({ teamType: "joined", ...t })),
          ownedTeams.map((t) => ({ teamType: "owned", ...t }))
        )
        // gotta sort them in a single list too
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : 0
        );
      setTeamList(tempTeamList);
    }
  }, [teamFilter, data]);

  const updateErrorData = (res) => {
    const errors = _get(res, "errors");
    if (errors && errors.length > 0) {
      if (Array.isArray(errors)) setErrorArray(errors.map((e) => e.message));
      else setErrorArray([errors.message || "unknown error - this is kinda stupid"]);
    } else {
      setErrorArray(defaultErrorArray);
    }
  };

  //
  // create team
  //
  const handleCreateSave = async (name) => {
    const res = await createTeam({ variables: { name }, errorPolicy: "all" });
    updateErrorData(res);
    setShowCreateTeam(false);
  };

  const handleCreateCancel = () => {
    setShowCreateTeam(false);
  };

  //
  // join team
  //
  const handleJoinTeamClick = () => {
    setShowJoinTeam(true);
  };

  const handleJoinSave = async (inviteCode) => {
    if (!inviteCode) throw new Error("invite code not provided, cannot join team");
    const res = await joinTeam({
      variables: { inviteCode },
      errorPolicy: "all"
    });
    updateErrorData(res);
    setShowJoinTeam(false);
  };

  const handleJoinCancel = () => {
    setShowJoinTeam(false);
  };

  //
  // leave team
  //
  const handleLeaveTeamClick = (id) => {
    setLeaveTeamCtx({ id });
    setShowLeaveConfirmation(true);
  };

  const handleLeaveConfirmed = async ({ id }) => {
    if (!id) throw new Error("leave confirmation missing id in callback");
    setShowLeaveConfirmation(false);
    setLeaveTeamCtx(defaultLeaveTeamCtx);
    const res = await leaveTeam({ variables: { id }, errorPolicy: "all" });
    updateErrorData(res);
  };

  const handleLeaveCanceled = () => {
    setShowLeaveConfirmation(false);
    setLeaveTeamCtx(defaultLeaveTeamCtx);
  };

  //
  // create game
  //
  const handleCreateGame = async (teamId, name) => {
    const res = await createTeamGame({ variables: { teamId, name }, errorPolicy: "all" });
    updateErrorData(res);
  };

  //
  // team filter pull down
  //
  // TODO: this is kinda a stupid way to do this. certainly there's a better way...
  const handleChangeTeamPulldown = (option) => {
    switch (option) {
      case "Joined Teams":
        setTeamFilter("Joined Teams");
        break;
      case "My Teams":
        setTeamFilter("My Teams");
        break;
      case "All Teams":
      default:
        setTeamFilter("All Teams");
        break;
    }
  };

  return (
    <PageBaseInsetContainer className="relative">
      <div className="flex flex-col gap-4">
        <div className="space-y-2">
          {/* Heading and intro text */}
          <Heading1>Teams</Heading1>
          <p>
            <SmDemphText>This is a list of all of your created and joined teams.</SmDemphText>
          </p>
          <p>
            <SmDemphText>
              Players are currently limited to creating three of their own teams. You can create as
              many games per-team as you'd like but only have two games open and active at once. If
              you need more games for a team, you can close open games to make more room.
            </SmDemphText>
          </p>
          {isTeamLimitReached && !isPowerUser ? (
            <p>
              <SmDemphText className="text-buzz-red-400">
                You've created the maximum number of allowed teams. If you need to create more,
                delete existing teams.
              </SmDemphText>
            </p>
          ) : null}
        </div>

        {/* top controls */}
        <div className={"flex flex-wrap justify-between items-center"}>
          {/* left side */}
          <div className="flex space-x-8">
            {/* join team button */}

            <ClickableDiv onClick={handleJoinTeamClick}>
              <SmText>Join Team</SmText>
            </ClickableDiv>
            {/* create team button */}
            {!isTeamLimitReached || isPowerUser ? (
              <ClickableDiv onClick={() => setShowCreateTeam(true)}>
                <SmText>Create Team</SmText>
              </ClickableDiv>
            ) : null}
          </div>

          {/* team filter */}
          <div>
            <SelectMenu
              label="Show"
              options={["All Teams", "Joined Teams", "My Teams"]}
              defaultOption={"All Teams"}
              onChange={handleChangeTeamPulldown}
            />
          </div>
        </div>

        {/* errors */}
        {/*Error Notification Box*/}
        {errorArray && errorArray.length > 0
          ? errorArray.map((e, idx) => (
              <ErrorNotification key={`error_${idx}`} header="Error" detail={e} show={true} />
            ))
          : null}

        {/* team list */}
        <div className="flex flex-col space-y-4 overflow-hidden">
          {!loading &&
            teamList.map((t) => (
              <TeamEntry
                key={`team_${t.id}`}
                onLeaveTeam={() => handleLeaveTeamClick(t.id)}
                isOwner={t.teamType === "owned"}
                isOpen={t.open}
                ownerUsername={t.owner.username}
                players={t.players}
                onCreateGame={handleCreateGame}
                onAcceptInvite={refetch}
                onIgnoreInvite={refetch}
                onLeaveGame={refetch}
                onCloseGame={refetch}
                gamesList={t.games}
                {...t}
              />
            ))}
        </div>
        {/* Create Team Modal */}
        {showCreateTeam ? (
          <TextInputModal
            open={true}
            title="Create Team"
            description="Name your new team"
            placeholder="Team Name"
            onSave={handleCreateSave}
            onCancel={handleCreateCancel}
          />
        ) : null}
        {/* Join Team Modal */}
        {showJoinTeam ? (
          <TextInputModal
            open={true}
            title="join team"
            description="join some shit"
            placeholder="Team Invite Code"
            onSave={handleJoinSave}
            onCancel={handleJoinCancel}
          />
        ) : null}
        {/* Leave Team Modal */}
        {showLeaveConfirmation ? (
          <ModalConfirmDanger
            open={true}
            onConfirm={handleLeaveConfirmed}
            onCancel={handleLeaveCanceled}
            context={leaveTeamCtx}
          />
        ) : null}
      </div>
    </PageBaseInsetContainer>
  );
};

export default Teams;
