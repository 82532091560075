import classnames from "classnames";

export const PageHeaderText = ({ children, className }) => {
  return <span className={classnames("text-3xl font-extrabold", className)}>{children}</span>;
};

export const SectionHeaderText = ({ children, className }) => {
  return <span className={classnames("text-2xl font-bold", className)}>{children}</span>;
};

export const SubheaderText = ({ children, className }) => {
  return <span className={classnames("text-xl font-bold", className)}>{children}</span>;
};

export const Text = ({ children, className }) => {
  return <span className={classnames("text-base", className)}>{children}</span>;
};

export const TextSemiBold = ({ children, className }) => {
  return <Text className={classnames("font-semibold", className)}>{children}</Text>;
};

export const TextBold = ({ children, className }) => {
  return <Text className={classnames("font-bold", className)}>{children}</Text>;
};

export const XlText = ({ children, className }) => {
  return <span className={classnames("text-xl", className)}>{children}</span>;
};

export const SmText = ({ children, className }) => {
  return <span className={classnames("text-sm", className)}>{children}</span>;
};

export const DangerText = ({ children, className }) => {
  return <span className={classnames("text-buzz-red-400", className)}>{children}</span>;
};

export const DemphText = ({ children, className }) => {
  return <span className={classnames("text-gray-500", className)}>{children}</span>;
};

export const SmDemphText = ({ children, className }) => {
  return <span className={classnames("text-sm text-gray-500", className)}>{children}</span>;
};

export const XsDemphText = ({ children, className }) => {
  return <span className={classnames("text-xs text-gray-500", className)}>{children}</span>;
};
