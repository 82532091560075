import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BuzzwordTooltip from "../components/BuzzwordTooltip";
import { UsersIcon } from "@heroicons/react/20/solid";

const ListCardItem = ({
  word,
  isMarked = false,
  isMarkedByOwner,
  markedByUsername,
  isReadOnly,
  onChanged
}) => {
  const [isMarkedInternal, setIsMarkedInternal] = useState(isMarked);
  const id = uuidv4();

  const handleClick = (marked) => {
    if (isReadOnly) return;
    setIsMarkedInternal(marked);
    onChanged(marked);
  };

  const cursorBlockClasses = isReadOnly ? "cursor-not-allowed" : "cursor-pointer";

  return (
    <div
      className={`${cursorBlockClasses} py-2 relative flex items-start`}
      onClick={(e) => handleClick(!isMarked)}
    >
      <div className={`flex items-center h-5 mr-3 ml-2`}>
        <input
          id={`word-${id}`}
          name={word}
          className={`focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded cursor-pointer`}
          type="checkbox"
          checked={isMarkedInternal}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      {/* we use isMarked here instead of isMarkedInternal because it causes a brief flash of the "marked by other user"
            icon if we use the internal setting since it won't align with the rest of the data that's passed in when we
            mark it ourselves */}
      {isMarked && !isMarkedByOwner ? (
        <BuzzwordTooltip
          className={`self-center`}
          text={`marked by ${markedByUsername || "(who knows)"}`}
        >
          <UsersIcon className={"mr-1 w-3.5 text-green-700"} />
        </BuzzwordTooltip>
      ) : null}
      <div className="text-sm">{word}</div>
    </div>
  );
};

export default ListCardItem;
