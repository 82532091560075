import React from "react";
import classnames from "classnames";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";

const PagerEntry = ({ pageNumber, isCurrent = false, onClick }) => {
  const currentClassnames = isCurrent
    ? "border-green-500 text-green-600"
    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

  return (
    <div
      onClick={onClick}
      className={classnames(
        "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium cursor-pointer",
        currentClassnames
      )}
      aria-current={isCurrent ? "page" : null}
    >
      {pageNumber}
    </div>
  );
};

/**
 * Creates a pager component
 *
 * @param numPages 0-indexed number of pages (i.e. if only a single page, should be "0")
 * @param currentPage
 * @param onPageClick
 * @returns {JSX.Element}
 * @constructor
 */
export default function Pager({ numPages, currentPage, onPageClick }) {
  let pagerEntries = [];
  for (let i = 1; i <= numPages; i++) {
    pagerEntries = [
      ...pagerEntries,
      <PagerEntry
        key={`pagerentry_${i}`}
        pageNumber={i}
        isCurrent={currentPage === i}
        onClick={() => onPageClick(i)}
      />
    ];
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      {/*Previous*/}
      <div className="-mt-px flex w-0 flex-1">
        {currentPage > 1 ? (
          <div
            onClick={() => onPageClick(currentPage - 1)}
            className={classnames(
              "inline-flex items-center border-t-2 border-transparent pt-4 pr-1",
              "cursor-pointer text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            )}
          >
            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </div>
        ) : null}
      </div>

      {/*Pager Entries*/}
      {pagerEntries}

      {/*Next*/}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {currentPage < numPages ? (
          <div
            onClick={() => onPageClick(currentPage + 1)}
            className={classnames(
              "inline-flex items-center border-t-2 border-transparent pt-4 pl-1",
              "cursor-pointer text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            )}
          >
            Next
            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        ) : null}
      </div>
    </nav>
  );
}
