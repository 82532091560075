import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { ButtonSave } from "../atoms/Button";
import { PageBaseInsetContainer } from "../atoms/Pages";
import WordListEntry from "./WordListEntry";
import TextInputModal from "../atoms/TextInputModal";
import { RightContentRow } from "../atoms/LayoutComponents";
import { Heading1 } from "../atoms/Heading";
import { TabContentDisplay, TabsHorizontal } from "../atoms/TabsMenu";
import { SmDemphText } from "../atoms/Text";

const GET_LISTS = gql`
  query MyCustomLists {
    ownedWordLists {
      id
      name
      listCode
      words
    }
  }
`;

const CREATE_LIST = gql`
  mutation CreateList($name: String!) {
    createWordList(name: $name) {
      name
      listCode
      words
    }
  }
`;

const CustomWordLists = () => {
  const { data } = useQuery(GET_LISTS, { fetchPolicy: "network-only" });
  const wordLists = _get(data, "ownedWordLists", []);
  const [showCreateList, setShowCreateList] = useState(false);
  const [createList] = useMutation(CREATE_LIST, { refetchQueries: [{ query: GET_LISTS }] });
  const [activeTab, setActiveTab] = useState(0);

  const handleCreateTeam = async (name) => {
    await createList({ variables: { name } });
    setShowCreateList(false);
  };

  return (
    <>
      <PageBaseInsetContainer className="flex flex-col">
        <Heading1 className="mb-6" noMargin>
          Word Lists
        </Heading1>
        <div className="mb-6">
          <p>
            <SmDemphText>
              This is where you create your personalized word lists to use in team games.
              Personalized word lists are how Buzzword
            </SmDemphText>
          </p>
        </div>

        <div className="mb-2">
          <TabsHorizontal onChangeTab={setActiveTab}>
            <TabsHorizontal.TabPlain text="Custom Word Lists" />
          </TabsHorizontal>
        </div>

        <TabContentDisplay activeIndex={activeTab}>
          {/* Tab One */}
          <>
            <div className="text-sm flex flex-col gap-2 text-gray-600 mb-4">
              <p>
                These are your custom word lists. The List code is how you use each list for your
                games. Drop that list code in your team or give to your friends to use in their
                teams. New games will pull entries from everyone's lists to personalize your team
                games.
              </p>
              <p>
                Add more entries to any list over time and new games will pull use the new entries
                when the game is created. Keep adding entries over time (and remove outdated
                entries) and your games will stay dynamic and fresh!
              </p>
              <p>Click the code below to copy it to your clipboard for easy sharing.</p>
            </div>

            <RightContentRow className={"items-center mb-2"}>
              <ButtonSave size="sm" onClick={() => setShowCreateList(true)}>
                New List
              </ButtonSave>
            </RightContentRow>

            <div className={"flex flex-col gap-2"}>
              {wordLists.map((w) => (
                <div key={w.listCode} className="min-w-1/2">
                  <WordListEntry {...w} />
                </div>
              ))}
            </div>
          </>
        </TabContentDisplay>
      </PageBaseInsetContainer>

      {showCreateList ? (
        <TextInputModal
          open={true}
          title="Create List"
          description="Name your new custom word list"
          placeholder="List Name"
          onSave={handleCreateTeam}
          onCancel={() => setShowCreateList(false)}
        />
      ) : null}
    </>
  );
};

export default CustomWordLists;
