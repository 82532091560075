import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { Heading1, Heading4 } from "./atoms/Heading";
import { PageBaseInsetContainer } from "./atoms/Pages";
import { RightContentRow } from "./atoms/LayoutComponents";
import GameListEntry from "./GameListEntry";
import { LeftLabelShortToggle } from "./atoms/Toggles";
import { CloseIcon } from "./atoms/IconsOutline";

const GET_MY_GAMES = gql`
  query MyGames {
    games {
      id
      name
      status
      createdAt
      owner {
        uid
        username
      }
      playerCount
      team {
        name
      }
    }
    gameInvites(status: [PENDING, IGNORED]) {
      id
      createdAt
      game {
        id
        status
        name
        status
        createdAt
        owner {
          username
          uid
        }
        playerCount
        team {
          name
        }
      }
      status
    }
  }
`;

export default function HomePage() {
  const [showIgnored, setShowIgnored] = useState(false);
  const { data, refetch } = useQuery(GET_MY_GAMES, {
    fetchPolicy: "network-only"
  });

  // error data
  // TODO: move this error display into the GameListEntry component
  const defaultErrorArray = [];
  const [errorArray, setErrorArray] = useState(defaultErrorArray);

  //
  // DERIVED DATA
  //
  // thread the games and invite data together to form a single list for the player so invitations and games can be
  // viewed together
  let games = _get(data, "games", []);
  const invites = _get(data, "gameInvites", []);
  if (!showIgnored)
    games = games.concat(
      invites.filter((i) => i.game.status === "INVITE_PENDING").map((i) => i.game)
    );
  else games = games.concat(invites.map((i) => i.game));
  const ignoredInvitationCount = invites.filter((i) => i.game.status === "INVITE_IGNORED").length;
  // sort the final array
  games.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  //
  // HANDLERS
  //
  // TODO: move this error display into the GameListEntry component
  const handleErrorsClose = () => {
    setErrorArray(defaultErrorArray);
  };

  // TODO: move this error display into the GameListEntry component
  const updateErrorData = (res) => {
    const errors = _get(res, "errors");
    if (errors && errors.length > 0) {
      if (Array.isArray(errors)) setErrorArray(res.errors.map((e) => e.message));
      else setErrorArray([errors.message || "unknown error - this is kinda stupid"]);
    } else {
      setErrorArray(defaultErrorArray);
    }
  };

  const handleCloseGame = async (id) => {
    await refetch();
  };

  const handleLeaveGame = async (id) => {
    await refetch();
  };

  const handleAcceptInvite = async (res) => {
    await refetch();
    // TODO: move this error display into the GameListEntry component
    updateErrorData(res);
  };

  const handleIgnoreInvite = async (res) => {
    await refetch();
    // TODO: move this error display into the GameListEntry component
    updateErrorData(res);
  };

  return (
    <PageBaseInsetContainer>
      <div className="flex flex-col gap-4">
        {/* errors */}
        {errorArray && errorArray.length > 0 ? (
          <div className="p-2 text-red-500 border border-red-300 bg-red-50">
            <div className="flex justify-between items-center">
              <Heading4>Errors</Heading4>
              <div onClick={handleErrorsClose}>
                <CloseIcon className="text-red-500 cursor-pointer" />
              </div>
            </div>
            <div className="flex space-y-2 px-4">
              {errorArray.map((e, idx) => (
                <div key={`error_${idx}`}>{e}</div>
              ))}
            </div>
          </div>
        ) : null}

        {/* ACTIVE GAMES */}
        <div className="flex flex-col">
          <Heading1>My Games</Heading1>
          <div>
            These are your current games and game invitations. If you want to view ignored
            invitations, use that little switch on the right to do that.
          </div>
          <RightContentRow>
            <LeftLabelShortToggle
              label="Show ignored invitations"
              secondaryText={`(${ignoredInvitationCount})`}
              enabled={showIgnored}
              onChange={() => setShowIgnored(!showIgnored)}
            />
          </RightContentRow>
          <div className={"flex flex-col gap-2 mt-4 mb-4"}>
            {games.map((g) => (
              <GameListEntry
                key={g.id}
                id={g.id}
                name={g.name}
                isInvite={g.status.startsWith("INVITE_")}
                isIgnored={g.status === "INVITE_IGNORED"}
                teamName={_get(g, "team.name")}
                playerCount={_get(g, "playerCount")}
                createdAt={g.createdAt}
                ownerUid={_get(g, "owner.uid")}
                ownerUsername={_get(g, "owner.username")}
                onClickClose={() => handleCloseGame(g.id)}
                onClickLeave={() => handleLeaveGame(g.id)}
                onClickIgnore={() => handleIgnoreInvite(g.id)}
                onClickAccept={() => handleAcceptInvite(g.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </PageBaseInsetContainer>
  );
}
