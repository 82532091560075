import classnames from "classnames";
import { SmDemphText } from "./Text";

export const FormLabel = ({ children }) => {
  return <div className={"font-semibold"}>{children}</div>;
};

export const FormVerticalTextInput = ({ label, value, onChange, className }) => {
  return (
    <div className={classnames("flex flex-col gap-1", className)}>
      <FormLabel>{label}</FormLabel>
      <input
        className={classnames("border")}
        // because tailwind doesn't have a way to inherit parent font sizes...
        style={{ fontSize: "inherit", lineHeight: "inherit" }}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export const FormHorizontalTextInput = ({
  label,
  secondaryText,
  value,
  onChange,
  className,
  onEnter
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onEnter) onEnter();
  };

  return (
    <div className={classnames("flex flex-col gap-2", className)}>
      <div className={classnames("flex gap-4 items-center")}>
        <FormLabel>{label}</FormLabel>
        <div className="w-full">
          <input
            className={"border w-full pb-2"}
            style={{ fontSize: "inherit", lineHeight: "inherit" }}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {secondaryText && <SmDemphText>{secondaryText}</SmDemphText>}
    </div>
  );
};

export const FormHorizontalTextInputGroupRightPillThing = ({
  label,
  buttonText,
  secondaryText,
  value,
  onChange,
  className
}) => {
  return (
    <div className={className}>
      <FormLabel>{label}</FormLabel>
      <div className={classnames("flex gap-4 items-center")}>
        <div className="flex items-center w-full rounded-md">
          <input
            className={"flex-grow p-2 rounded-l-md border border-r-0"}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
          <span className="p-2 rounded-r-md border border border-black text-gray-500 bg-gray-100">
            {buttonText}
          </span>
        </div>
      </div>
      {secondaryText && <SmDemphText>{secondaryText}</SmDemphText>}
    </div>
  );
};

export const FormTextInput = ({ placeholder, value, onChange, className, onEnter, type }) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onEnter) onEnter();
  };

  return (
    <input
      className={classnames("border w-full pb-2", className)}
      style={{ fontSize: "inherit", lineHeight: "inherit" }}
      type={type || "text"}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};
