import React, { useState } from "react";
import classnames from "classnames";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

// *
// * @param text
// * @param shouldDisplayOnCopy
// * @param shouldDisplayCopyIcon
// * @param classNames {string|string[]} Styles to be applied to the wrapping parent element when the text is not being
// * copies.
// *
// * @param classNamesOnCopy {string|string[]} This should be an exhaustive list of the styles applied when the user
// * clicks on the element to have the text copy. These styles will not stack with the regular "classNames" param, they
// * will replace them. This allows the user to control specific styling decisions without worrying about the original
// * non-click (regular) styling being applied on top of this style. Not sure if this is a good idea or not, but it
// * _seems_ okay, so we'll go with it for now.
// *
// * @param copyDisplayTimeMs
// * @param onCopy
// * @return {JSX.Element}
// * @constructor
const BuzzwordCopyText = ({
  text,
  shouldDisplayOnCopy = true,
  shouldDisplayCopyIcon = true,
  className,
  classNameOnCopy,
  copyDisplayTimeMs,
  onCopy
}) => {
  const [didCopy, setDidCopy] = useState(false);

  const handleClick = () => {
    setDidCopy(true);
    setTimeout(() => setDidCopy(false), copyDisplayTimeMs || 2000);
    if (onCopy) onCopy(text);
  };

  const copyComponent =
    didCopy && shouldDisplayOnCopy ? (
      <span className={classnames(classNameOnCopy || "text-green-500")}>copied!</span>
    ) : (
      <span className={classnames(className, "flex items-center cursor-pointer")}>
        <div>{text}</div>
        <div className={classnames(className, "ml-1")}>
          {shouldDisplayCopyIcon ? <ClipboardIcon className="flex w-4" /> : null}
        </div>
      </span>
    );

  return (
    <CopyToClipboard text={text} onCopy={handleClick}>
      {copyComponent}
    </CopyToClipboard>
  );
};

export default BuzzwordCopyText;
