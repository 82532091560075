import React from "react";
import { Switch } from "@headlessui/react";
import classnames from "classnames";

export const LeftLabelToggle = ({ label, secondaryText, enabled = false, onChange = () => {} }) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch.Label as="span" className="mr-3 cursor-pointer">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {secondaryText ? (
          <span className="text-sm text-gray-500">&nbsp;{secondaryText}</span>
        ) : null}
      </Switch.Label>
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classnames(
          enabled ? "bg-green-600" : "bg-gray-200",
          "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classnames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
};

export const RightLabelToggle = ({
  label,
  secondaryText,
  enabled = false,
  onChange = () => {}
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={onChange}
        className={classnames(
          enabled ? "bg-green-600" : "bg-gray-200",
          "relative inline-flex shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classnames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 cursor-pointer">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {secondaryText ? (
          <span className="text-sm text-gray-500">&nbsp;{secondaryText}</span>
        ) : null}
      </Switch.Label>
    </Switch.Group>
  );
};

//
// SHORT TOGGLE
//
// from: https://tailwindui.com/components/application-ui/forms/toggles#component-b3e0a15571300f79fced5845f97fa972
const SwitchCommon = ({ enabled, onChange }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className="shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className="pointer-events-none absolute bg-white w-full h-full rounded-md"
      />
      <span
        aria-hidden="true"
        className={classnames(
          enabled ? "bg-green-600" : "bg-gray-200",
          "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
        )}
      />
      <span
        aria-hidden="true"
        className={classnames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"
        )}
      />
    </Switch>
  );
};

export const LeftLabelShortToggle = ({
  label,
  secondaryText,
  enabled = false,
  onChange = () => {}
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch.Label as="span" className="mr-2 cursor-pointer">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {secondaryText ? <span className="ml-1 text-sm text-gray-500">{secondaryText}</span> : null}
      </Switch.Label>
      <SwitchCommon enabled={enabled} onChange={onChange} />
    </Switch.Group>
  );
};

export const RightLabelShortToggle = ({
  label,
  secondaryText,
  enabled = false,
  onChange = () => {}
}) => {
  return (
    <Switch.Group as="div" className="flex items-center">
      <SwitchCommon enabled={enabled} onChange={onChange} />
      <Switch.Label as="span" className="ml-3 cursor-pointer">
        <span className="text-sm font-medium text-gray-900">{label}</span>
        {secondaryText ? (
          <span className="text-sm text-gray-500">&nbsp;{secondaryText}</span>
        ) : null}
      </Switch.Label>
    </Switch.Group>
  );
};
