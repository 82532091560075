import React, { useState } from "react";
import classnames from "classnames";
import axios from "axios";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import isEmail from "validator/es/lib/isEmail";
import GoogleLoginButton from "./GoogleLoginButton";
import { FormTextInput } from "./atoms/Forms";
import { SmDemphText } from "./atoms/Text";
import { ButtonNoSize } from "./atoms/Button";
import { SpreadContentRow } from "./atoms/LayoutComponents";
import { LogoFull } from "./atoms/Logo";
import { QuestionMarkPopoverBottom } from "./atoms/Popovers";

const GoogleLogin = () => {
  return (
    <a href={`${process.env.REACT_APP_BACKEND_BASE_URL || ""}/login`}>
      <GoogleLoginButton alt="Log in with Google" role="button" />
    </a>
  );
};

const MagicLinkLogin = ({ onSubmitError, onSubmitSuccess }) => {
  const [email, setEmail] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmitEmailLogin = async () => {
    if (!email || email.length === 0) {
      onSubmitError("You need to actually supply an email address.");
      return;
    }

    // normalize it so we can do validity checks and use a "clean" email
    // rsm: 2024-02-07 - added toLowerCase() as part of the fix for BB-302
    const normalizedEmail = email.trim().toLowerCase();

    // check for validity
    if (!isEmail(normalizedEmail)) {
      // mark it as invalid and let something else handle the error accordingly
      onSubmitError("Please use a valid email address.");
      return;
    }

    try {
      setIsProcessing(true);
      const res = await axios({
        url: "/auth/magiclink/login",
        method: "POST",
        data: {
          email: normalizedEmail
        },
        headers: { "content-type": "application/json" }
      });
      setEmail("");
      setIsProcessing(false);
      if (res.status === 200) onSubmitSuccess();
    } catch (err) {
      onSubmitError(err.response);
    }
  };

  return (
    <>
      <div className={"flex flex-col gap-4"}>
        <SpreadContentRow className={"gap-4"}>
          <div className={"w-full"}>
            <FormTextInput
              className="rounded-full py-2 px-4"
              placeholder="Enter your email address"
              onChange={setEmail}
              onEnter={handleSubmitEmailLogin}
              value={email}
              label={"Email"}
              type="email"
            />
          </div>
        </SpreadContentRow>

        <ButtonNoSize
          className={classnames(
            "border border-gray-100 rounded-full  text-gray-100",
            "w-full p-2",
            "disabled:bg-gray-400 bg-gray-800"
          )}
          onClick={handleSubmitEmailLogin}
          disabled={isProcessing}
        >
          Get a Magic Link
        </ButtonNoSize>
      </div>
    </>
  );
};

/*
 <SmDemphText>
          <TextBold>Note:</TextBold> Buzzword uses "magic links" instead of passwords. You'll
          receive an email with a link that you can use to login to Buzzword. We don't use passwords
          because they don't make you any more secure than magic links anyway. Plus, it saves you
          and us a lot of extra hassle, work, and exposes you to fewer potential security risks.
        </SmDemphText>
        <SmDemphText>
          You'll need to click on the link using the same browser that you requested it in (or
          copy/paste it into the same browser). So if you want to log in on a different device,
          request the magic link from a browser on that same device (phone, tablet, laptop,
          mainframe, toaster, etc.).
        </SmDemphText>
 */

const Login = () => {
  const [popUpText, setPopUpText] = useState({});
  const [timeoutId, setTimeoutId] = useState(-1);

  const updatePopupTextObj = (obj) => {
    if (timeoutId !== undefined) {
      // if it's the same error, just return. if it's different, let the function continue
      if (popUpText.text && obj.text === popUpText.text) {
        return;
      } else {
        // but we need to clear the old timer first
        clearTimeout(timeoutId);
      }
    }
    setPopUpText(obj);
    const id = setTimeout(() => {
      setPopUpText({});
      setTimeoutId(-1);
    }, 5000);
    setTimeoutId(id);
  };

  const handleSubmitSuccess = () => {
    updatePopupTextObj({ type: "info", text: "Check your email for a login link." });
  };

  const handleSubmitError = (text) => {
    updatePopupTextObj({ type: "error", text });
  };

  return (
    <div className="flex flex-col flex-1 min-h-full justify-center items-center py-12  px-6 lg:px-8">
      <div className="flex flex-col items-center gap-4">
        <LogoFull />
        <SmDemphText>
          <a href="https://buzzworded.com" className="flex gap-1">
            What's Buzzword?
            <ArrowTopRightOnSquareIcon className="w-3.5" />
          </a>
        </SmDemphText>
      </div>
      <div className={classnames("mt-10 sm:mx-auto w-full sm:max-w-[480px]")}>
        <div
          className={classnames(
            "text-center space-y-6",
            "sm:border border-gray-200 sm:shadow sm:rounded-lg",
            "py-10 sm:px-12"
          )}
        >
          <p className="text-4xl font-semibold">Sign In</p>
          <div className="text-sm text-gray-600">
            Sign in to start Buzzwording with your friends now!
            <QuestionMarkPopoverBottom className="ml-1">
              Yes, we know "Buzzwording" isn't a verb, but work with us here.
            </QuestionMarkPopoverBottom>
          </div>
          {/* google login */}
          <div className="text-center">
            <GoogleLogin />
          </div>
          {/* separator */}
          <div className="relative mt-4 w-full">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">OR</span>
            </div>
          </div>
          {/* magic link */}
          <div className="mt-4">
            <MagicLinkLogin
              onSubmitError={handleSubmitError}
              onSubmitSuccess={handleSubmitSuccess}
            />
          </div>
        </div>

        {/* ERROR */}
        {Object.keys(popUpText).length > 0 ? (
          <div
            className={classnames(
              "w-full mt-4 p-2 text-center ",
              "border  rounded-lg",
              popUpText.type === "info"
                ? "bg-buzz-green-100 text-green-900 border-green-900"
                : "bg-buzz-red-100 text-red-900 border-red-900"
            )}
          >
            {popUpText.text}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Login;
