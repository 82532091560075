import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import dayjs from "dayjs";
import { StopCircleIcon, ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useUser } from "../../contexts/AuthContext";
import ModalConfirmDanger from "../atoms/ModalConfirmDanger";
import { BBLink, ClickableDiv } from "../atoms/Link";
import {
  LeftContentRow,
  RightContentRow,
  SpreadContentCollapsingRowItemsTop,
  SpreadContentRow
} from "../atoms/LayoutComponents";
import classnames from "classnames";
import { SmDemphText } from "../atoms/Text";

const ACCEPT_INVITE = gql`
  mutation AcceptInvite($id: ID!) {
    acceptGameInvite(id: $id) {
      id
      status
    }
  }
`;

const IGNORE_INVITE = gql`
  mutation IgnoreInvite($id: ID!) {
    ignoreGameInvite(id: $id) {
      id
      status
    }
  }
`;

const CLOSE_GAME = gql`
  mutation CloseGame($id: ID!) {
    closeGame(id: $id) {
      id
      name
      isClosed
    }
  }
`;

const LEAVE_GAME = gql`
  mutation LeaveGame($id: ID!) {
    leaveGame(id: $id) {
      id
    }
  }
`;

export default function GameListEntry({
  id,
  name,
  teamName,
  playerCount,
  ownerUid,
  ownerUsername,
  createdAt,
  closedAt,
  inviteCreatedAt,
  isImmutable = false,
  isInvite = false,
  isIgnored = false,
  // game actions
  onClickClose = () => {},
  onClickLeave = () => {},
  // invite actions
  onClickIgnore = () => {},
  onClickAccept = () => {}
}) {
  const uid = useUser().uid;
  const [closeGame] = useMutation(CLOSE_GAME);
  const [leaveGame] = useMutation(LEAVE_GAME);
  const [acceptInvite] = useMutation(ACCEPT_INVITE);
  const [ignoreInvite] = useMutation(IGNORE_INVITE);
  const [isCloseConfirmModalVisible, setCloseConfirmModalVisible] = useState(false);
  const [isLeaveGameConfirmModelVisible, setIsLeaveGameConfirmModelVisible] = useState(false);

  const handleCloseGame = async () => {
    setCloseConfirmModalVisible(false);
    await closeGame({ variables: { id } });
    onClickClose();
  };

  const handleLeaveGame = async () => {
    setIsLeaveGameConfirmModelVisible(false);
    await leaveGame({ variables: { id } });
    onClickLeave();
  };

  const handleAcceptInvite = async () => {
    const res = await acceptInvite({ variables: { id }, errorPolicy: "all" });
    onClickAccept(res);
  };

  const handleIgnoreInvite = async () => {
    const res = await ignoreInvite({ variables: { id }, errorPolicy: "all" });
    onClickIgnore(res);
  };

  return (
    <>
      {/* ACTUAL CONTENT */}
      <div
        className={classnames(
          "flex flex-col gap-2 px-3 py-2",
          "border",
          !isIgnored
            ? "border-green-600 bg-green-500 bg-opacity-5"
            : "text-gray-400 border-gray-500 bg-gray-500 bg-opacity-5 opacity-60",
          isInvite && !isIgnored ? "ring ring-blue-300" : ""
        )}
      >
        {/* name and creation information */}
        <SpreadContentCollapsingRowItemsTop className={"gap-1"}>
          {/* don't use a link unless it's an active / accepted game */}
          <div className={"flex flex-col"}>
            <BBLink className={"text-xl"} href={`/game/${id}`}>
              {name}
            </BBLink>
            {isInvite ? <SmDemphText>(Invitation)</SmDemphText> : null}
          </div>

          {/* show ignored if that's the state of things */}
          {isInvite && isIgnored ? <div className={"text-red-700"}>IGNORED</div> : null}

          <div className={"flex flex-col sm:text-right text-xs text-gray-500 whitespace-nowrap"}>
            <p>Created {dayjs(createdAt).format("L LT")}</p>
            <p>by {ownerUsername}</p>
            <p>team: {teamName}</p>
            <p>players: {playerCount}</p>
            {closedAt ? (
              <p className={"text-red-700"}>Closed on: {dayjs(closedAt).format("L LT")}</p>
            ) : null}
          </div>
        </SpreadContentCollapsingRowItemsTop>

        {/* ACTION BUTTONS AND SOME OTHER CRAP */}
        <SpreadContentRow>
          <LeftContentRow>{isInvite ? <InvitationActionRow /> : null}</LeftContentRow>
          <RightContentRow>{!isImmutable ? <GameActionRow /> : null}</RightContentRow>
        </SpreadContentRow>
      </div>

      {/* CLOSE GAME CONFIRM DIALOG */}
      <ModalConfirmDanger
        text="Are you sure you want to close the game?"
        secondaryText="You can't reopen the game later. This is like FINAL final. Super final. I'm serious."
        open={isCloseConfirmModalVisible}
        onConfirm={handleCloseGame}
        onCancel={() => setCloseConfirmModalVisible(false)}
      />

      {/* LEAVE GAME CONFIRM DIALOG */}
      <ModalConfirmDanger
        text="Are you sure you want to leave the game?"
        secondaryText={
          "Your current game card and all marked words will be permanently removed and everyone will know you fled. " +
          "Are you really really sure?"
        }
        open={isLeaveGameConfirmModelVisible}
        onConfirm={handleLeaveGame}
        onCancel={() => setIsLeaveGameConfirmModelVisible(false)}
      />
    </>
  );

  function GameActionRow() {
    return (
      <div className={"flex gap-5"}>
        {/* leave game */}
        {isImmutable === false && !isInvite ? (
          <div title="Leave game">
            <ArrowRightOnRectangleIcon
              className={
                "flex flex-col cursor-pointer text-buzz-red-300 hover:text-buzz-red-600 w-6"
              }
              onClick={() => setIsLeaveGameConfirmModelVisible(true)}
            />
          </div>
        ) : null}

        {/* close game icon / button */}
        {isImmutable === false && ownerUid === uid ? (
          <div title="Close game">
            <StopCircleIcon
              className={
                "flex flex-col cursor-pointer text-buzz-red-300 hover:text-buzz-red-600 w-6"
              }
              onClick={() => setCloseConfirmModalVisible(true)}
            />
          </div>
        ) : null}
      </div>
    );
  }

  function InvitationActionRow() {
    return (
      <>
        {inviteCreatedAt ? (
          <div className={"text-xs text-gray-500"}>
            invite created: {dayjs(inviteCreatedAt).format("L LT")}
          </div>
        ) : null}

        <div className="flex gap-6">
          {/*Ignore Button*/}
          {!isIgnored ? (
            <ClickableDiv
              onClick={handleIgnoreInvite}
              className="text-red-600 hover:text-red-900 cursor-pointer"
            >
              Ignore
            </ClickableDiv>
          ) : null}

          {/*Accept Button*/}
          <ClickableDiv
            onClick={handleAcceptInvite}
            className="text-green-600 hover:text-green-900 cursor-pointer"
          >
            Accept
          </ClickableDiv>
        </div>
      </>
    );
  }
}
