import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { RightContentRow, SpreadContentRow } from "./LayoutComponents";
import { SmText, TextSemiBold } from "./Text";
import { ClickableDiv, ClickableDivDanger } from "./Link";
import { BBChevronRightIcon } from "./IconsOutline";

const baseContainerClasses = classnames("flex flex-col");
const baseBorderClasses = classnames("border rounded-lg");

function TitleBase({ title, children }) {
  return (
    <SpreadContentRow className={"border-b border-gray-200 pb-2"}>
      <TextSemiBold>{title}</TextSemiBold>
      {children}
    </SpreadContentRow>
  );
}

export function CollapsingTitleContainerWithDangerAction({
  title,
  isExpanded = false,
  actionText,
  onClickAction,
  children
}) {
  const [localIsExpanded, setLocalIsExpanded] = useState(false);

  useEffect(() => {
    setLocalIsExpanded(isExpanded);
  }, [isExpanded]);

  const handleClickExpandCollapse = () => {
    setLocalIsExpanded(!localIsExpanded);
  };

  return (
    <div className={classnames(baseContainerClasses)}>
      <TitleBase title={title}>
        <ClickableDivDanger onClick={onClickAction}>
          <SmText>{actionText}</SmText>
        </ClickableDivDanger>
        <div
          className={`transform transition-transform ${localIsExpanded ? "rotate-90" : ""}`}
          onClick={handleClickExpandCollapse}
        >
          <BBChevronRightIcon />
        </div>
      </TitleBase>
      <div
        className={`flex flex-col gap-2 transition-all duration-500 ${
          localIsExpanded ? "max-h-auto" : "max-h-0 overflow-hidden"
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export function TitleContainer({ title, children }) {
  return (
    <div className={classnames(baseContainerClasses)}>
      <TitleBase title={title} />
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function TitleContainerWithAction({ title, actionText, onClickAction, children }) {
  return (
    <div className={classnames(baseContainerClasses)}>
      <TitleBase title={title}>
        <ClickableDiv onClick={onClickAction}>
          <SmText>{actionText}</SmText>
        </ClickableDiv>
      </TitleBase>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function TitleContainerWithDangerAction({ title, actionText, onClickAction, children }) {
  return (
    <div className={classnames(baseContainerClasses)}>
      <TitleBase title={title}>
        <ClickableDivDanger onClick={onClickAction}>
          <SmText>{actionText}</SmText>
        </ClickableDivDanger>
      </TitleBase>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function ContainerWithAction({ actionText, onClickAction, children }) {
  return (
    <div className={classnames(baseContainerClasses, "border-gray-400")}>
      <RightContentRow className={"border-b border-gray-200 pb-2"}>
        <ClickableDiv onClick={onClickAction}>
          <SmText>{actionText}</SmText>
        </ClickableDiv>
      </RightContentRow>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function RoundedCornerTitleContainerWithAction({
  title,
  actionText,
  onClickAction,
  children
}) {
  return (
    <div className={classnames(baseContainerClasses, "border-gray-400")}>
      <SpreadContentRow className={"border-b border-gray-200 pb-2"}>
        <TextSemiBold>{title}</TextSemiBold>
        <ClickableDiv onClick={onClickAction}>
          <SmText>{actionText}</SmText>
        </ClickableDiv>
      </SpreadContentRow>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function RoundedCornerTitleContainer({ title, children }) {
  return (
    <div
      className={classnames(
        baseContainerClasses,
        baseBorderClasses,
        "border-gray-400 px-2 sm:px-4 py-3"
      )}
    >
      <SpreadContentRow className={"border-b border-gray-200 pb-2"}>
        <TextSemiBold>{title}</TextSemiBold>
      </SpreadContentRow>
      <div className="flex flex-col">{children}</div>
    </div>
  );
}

export function RoundedCornerDangerTitleContainer({ title, children }) {
  return (
    <div className={classnames("flex flex-col")}>
      <div
        className={classnames(
          "text-buzz-red-800 bg-buzz-red-100 font-bold",
          "border-t border-l border-r border-red-800 rounded-tl-xl rounded-tr-xl",
          "px-4 p-2"
        )}
      >
        {title}
      </div>
      {/*  CONTENT BELOW HERE*/}
      <div
        className={classnames(
          "border-red-800 border-l border-r border-b",
          "rounded-bl-xl rounded-br-xl",
          "px-4",
          "flex flex-col divide-y divide-gray-200"
        )}
      >
        {children}
      </div>
    </div>
  );
}
