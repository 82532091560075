import React, { useState } from "react";
import classnames from "classnames";
import copy from "copy-to-clipboard";

const BuzzwordCopyTextChildren = ({
  children,
  copyValue,
  shouldDisplayOnCopy = true,
  shouldPreventDefault = false,
  shouldHoverPointer = true,
  className,
  classNameOnCopy,
  onCopyElem,
  copyDisplayTimeMs,
  onCopy
}) => {
  const [didCopy, setDidCopy] = useState(false);

  const handleClick = (e) => {
    if (shouldPreventDefault && e.preventDefault) e.preventDefault();
    setDidCopy(true);
    setTimeout(() => setDidCopy(false), copyDisplayTimeMs || 2000);
    if (onCopy) onCopy(copyValue);
    copy(copyValue);
  };

  let copyElemConstructed;
  if (shouldDisplayOnCopy) {
    if (onCopyElem)
      copyElemConstructed = React.createElement(onCopyElem, {
        className: classnames(className, classNameOnCopy),
        children: <>copied!</>
      });
    else
      copyElemConstructed = (
        <span onClick={handleClick} className={classnames(className)}>
          {children}
        </span>
      );
  }

  const copyComponent =
    didCopy && shouldDisplayOnCopy ? (
      copyElemConstructed
    ) : (
      <span
        onClick={handleClick}
        className={classnames(className, shouldHoverPointer ? "cursor-pointer" : "")}
      >
        {children}
      </span>
    );

  return copyComponent;
};

export default BuzzwordCopyTextChildren;
