import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import _get from "lodash/get";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Heading1 } from "./atoms/Heading";
import { SmDemphText } from "./atoms/Text";
import { PageBaseInsetContainer } from "./atoms/Pages";
import GameListEntry from "./GameListEntry";
import GameListEntrySkeleton from "./GameListEntry/GameListEntrySkeleton";
import Pager from "./atoms/Pager";

dayjs.extend(localizedFormat);

const GET_CLOSED_GAMES = gql`
  query MyClosedGames($skip: Int = 0) {
    closedGameCount
    closedGames(skip: $skip) {
      id
      name
      createdAt
      closedAt
      wordLists {
        listCode
      }
      owner {
        uid
        username
      }
      players {
        uid
        username
      }
      playerCount
      team {
        name
      }
    }
  }
`;

const PageBusySkeleComponents = ({ children }) => {
  let skeles = [];
  for (let i = 0; i < 10; i++) {
    skeles = [...skeles, <GameListEntrySkeleton key={`skele_${i}`} />];
  }

  return <>{skeles}</>;
};

const ClosedGames = () => {
  const { loading, error, data, refetch } = useQuery(GET_CLOSED_GAMES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  let games = [];
  let pageCount = 1;
  if (!loading) {
    if (error) {
      console.log("ERROR");
      console.log(error);
    }

    if (data) {
      games = _get(data, "closedGames", []);
      // update the page count. we always want to return a minimum of one page even if the count of closed games is 0
      pageCount = Math.max(1, Math.ceil(_get(data, "closedGameCount", 1) / 10));
    }
  }

  const handlePageClick = async (pageNumber) => {
    await refetch({ skip: (pageNumber - 1) * 10 });
    setCurrentPageNumber(pageNumber);
  };

  return (
    <PageBaseInsetContainer>
      {/* ERROR */}
      {error ? (
        <div style={{ color: "red" }}>
          Shit, error!
          <div>{error}</div>
        </div>
      ) : null}

      <div className={"flex flex-col"}>
        <div className={"pb-6"}>
          <Heading1>Previous Games</Heading1>
          <p className={"mb-2"}>
            These are previous games you've played and probably lost horribly. This list is mostly
            here to remind you of your failure and give you the hope that you may someday be a
            champion.
          </p>
          <SmDemphText>
            (Our suggestion for a winning strategy: lie, cheat, steal. Probably in that order.)
          </SmDemphText>
        </div>

        <div className={"flex flex-col gap-2 mb-2"}>
          {loading ? (
            // Loading stand-in elements
            <PageBusySkeleComponents />
          ) : (
            games.map((g) => (
              <GameListEntry
                isImmutable={true}
                key={g.id}
                id={g.id}
                name={g.name}
                createdAt={g.createdAt}
                closedAt={g.closedAt}
                ownerUid={_get(g, "owner.uid")}
                ownerUsername={_get(g, "owner.username")}
                teamName={_get(g, "team.name")}
                playerCount={_get(g, "playerCount")}
              />
            ))
          )}
        </div>

        <div className={"hidden md:block"}>
          {
            // TODO: this shit doesn't work on mobile, need to fix that
            pageCount > 1 ? (
              <div>
                <Pager
                  numPages={pageCount}
                  currentPage={currentPageNumber}
                  onPageClick={handlePageClick}
                />
              </div>
            ) : null
          }
        </div>
      </div>
    </PageBaseInsetContainer>
  );
};

export default ClosedGames;
