import "./App.css";
import React from "react";
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import _get from "lodash/get";
import { AuthProvider, UserProvider, useAuth, getUserToken } from "./contexts/AuthContext";
import Header from "./components/Header";
import Routes from "./Routes";

// routes
import Login from "./components/Login";
import { UserLocalPrefsProvider } from "./contexts/UserPreferencesContext";

const Authenticated = () => {
  return (
    <div className={"min-h-screen pb-16"}>
      <Header />
      <Routes />
    </div>
  );
};

const NotAuthenticated = () => {
  return <Login />;
};

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <UserProvider>
        <UserLocalPrefsProvider>
          <ApolloProviderWithAuth>{children}</ApolloProviderWithAuth>
        </UserLocalPrefsProvider>
      </UserProvider>
    </AuthProvider>
  );
}

function ApolloProviderWithAuth({ children }) {
  const auth = useAuth();

  // Build gql links here so we have access to the auth object
  const httpLink = new HttpLink({
    uri: "/graphql"
  });

  const contextLink = new setContext(async (req) => {
    const token = getUserToken();
    if (token) {
      return { headers: { authorization: `Bearer ${token}` } };
    }
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (const e of graphQLErrors) {
        if (_get(e, "extensions.code") === "UNAUTHENTICATED") {
          console.warn("RECEIVED UNAUTHENTICATED ERROR:");
          console.log(e);
          auth.logout();
        }
      }
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, contextLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Team: {
          fields: {
            players: {
              merge(existing, incoming) {
                // TODO: not sure this is really helping or not, but it's replacing existing players fields for a team.
                // Maybe this is okay here since the incoming players list is the idempotent list of players for a team,
                // so we'll go with this for now.
                return incoming;
              }
            }
          }
        },
        Player: {
          keyFields: ["uid"]
        }
      }
    })
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

// we do this in order to wrap our app in all the providers it needs so it can access them immediately as part
// of the app below. We likely need to refactor this as it seems like stupid, useless level of abstraction, but
// "meh" for now...
function AppWrapper() {
  return (
    <AppProviders>
      <App />
    </AppProviders>
  );
}

function App() {
  const auth = useAuth();

  return auth.isAuthenticated() ? <Authenticated /> : <NotAuthenticated />;
}

export default AppWrapper;
