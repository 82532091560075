import { gql } from "@apollo/client";

const TEAM_FRAGMENT_BASE = gql`
  fragment TeamShit on Team {
    id
    name
    open
    createdAt
    joinedAt
    playerCount
    inviteCode
    owner {
      uid
      username
    }
    customWordLists {
      id
      listCode
    }
    games {
      id
      name
      status
      createdAt
      wordLists {
        listCode
      }
      owner {
        uid
        username
      }
      players {
        uid
        username
        pictureUrl
      }
      playerCount
      team {
        name
      }
      invites {
        createdAt
        status
      }
    }
  }
`;

const TEAM_FRAGMENT_WORDLIST_DETAIL = gql`
  fragment WordListShit on Team {
    customWordLists {
      id
      listCode
      valid
      name
      words
    }
    players {
      uid
      username
      pictureUrl
    }
  }
`;

export const GET_TEAM_DETAIL = gql`
  ${TEAM_FRAGMENT_BASE}
  ${TEAM_FRAGMENT_WORDLIST_DETAIL}

  query Team($id: ID!) {
    teamById(id: $id) {
      ...TeamShit
      ...WordListShit
    }
  }
`;

export const UPDATE_TEAM = gql`
  ${TEAM_FRAGMENT_BASE}
  ${TEAM_FRAGMENT_WORDLIST_DETAIL}

  mutation UpdateTeam($id: ID!, $name: String, $open: Boolean, $customWordListIds: [String]) {
    updateTeam(id: $id, name: $name, open: $open, customWordListIds: $customWordListIds) {
      ...TeamShit
      ...WordListShit
    }
  }
`;

export const DELETE_TEAM = gql`
  ${TEAM_FRAGMENT_BASE}

  mutation DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      ...TeamShit
    }
  }
`;

export const GET_CUSTOM_WORD_LIST = gql`
  query CustomWordList($code: String!) {
    wordList(listCode: $code) {
      id
      listCode
      valid
      name
      words
    }
  }
`;
