import React, { useState } from "react";
import classnames from "classnames";
import { PageBaseInsetContainer } from "../atoms/Pages";
import { PageHeaderText } from "../atoms/Text";
import GeneralSettingsTab from "./GeneralSettingsTab";

const Settings = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = [{ name: "General", content: GeneralSettingsTab }];

  return (
    <PageBaseInsetContainer>
      <PageHeaderText>Settings</PageHeaderText>
      <div className="flex flex-col">
        {/* tabs */}
        <div className="block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab, idx) => (
                <a
                  onClick={() => setCurrentTabIndex(idx)}
                  key={tab.name}
                  href={tab.href}
                  className={classnames(
                    currentTabIndex === idx
                      ? "border-green-500 text-green-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        {/* tab content */}
        <div className="mt-4">{currentTabIndex === 0 ? <GeneralSettingsTab /> : null}</div>
      </div>
    </PageBaseInsetContainer>
  );
};

export default Settings;
