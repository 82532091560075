import React from "react";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classnames from "classnames";

export const MenuItem = ({ text, enabled, onChange }) => {
  return (
    <div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <div
        className={classnames(
          "group flex items-center px-4 py-2",
          "cursor-pointer text-sm text-gray-700",
          "hover:bg-green-500 hover:text-white"
        )}
        onClick={() => onChange(!enabled)}
      >
        <div className={`flex items-center h-5 mr-3`}>
          <input
            type="checkbox"
            className={`focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded cursor-pointer`}
            checked={enabled}
            onChange={(e) => onChange(e.target.checked)}
          />
        </div>
        {text}
      </div>
    </div>
  );
};

export const ListViewOptionsMenu = ({ menuItems = [] }) => {
  return (
    <Popover className="relative inline-block text-left z-100">
      {({ open }) => (
        <>
          <Popover.Button
            className={classnames(
              "inline-flex justify-center w-full py-1",
              "shadow-sm",
              "bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              // "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
            )}
          >
            Options
            <ChevronDownIcon className={`-mr-1 ml-2 h-5 w-5`} aria-hidden="true" />
          </Popover.Button>

          {open && (
            // TODO: fix this
            // <Transition
            //   as="div"
            //   enter="transition ease-out duration-100"
            //   enterFrom="transform opacity-0 scale-95"
            //   enterTo="transform opacity-100 scale-100"
            //   leave="transition ease-in duration-75"
            //   leaveFrom="transform opacity-100 scale-100"
            //   leaveTo="transform opacity-0 scale-95"
            // >
            <Popover.Panel
              static
              className={classnames(
                "absolute mt-2 w-56 left-24 -top-5",
                "border-2 border-dashed border-green-700 rounded-md",
                "shadow-2xl shadow-gray-900 bg-white",
                "ring-1 ring-black ring-opacity-5 focus:outline-none"
              )}
            >
              <div className="py-1">
                {menuItems.map((m, idx) => (
                  <div key={`menu_${idx}`}>{m}</div>
                ))}
              </div>
            </Popover.Panel>
            // </Transition>
          )}
        </>
      )}
    </Popover>
  );
};
