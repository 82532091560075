import React, { useState } from "react";
import classnames from "classnames";
import styles from "./BuzzwordTooltip.module.css";

const BuzzwordTooltip = ({ children, text, direction, className }) => {
  let timeout;
  const [active, setActive] = useState(false);

  const handleEnter = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 400);
  };

  const handleLeave = () => {
    clearTimeout(timeout);
    setActive(false);
  };

  const tooltipClasses = classnames(styles.tooltipTip, styles[`${direction}`] || styles.top);

  return (
    <div
      className={classnames(className, "inline-block relative")}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      {children}
      {active && (
        <div
          className={classnames(
            tooltipClasses,
            "text-xs font-sans bg-black text-white font-light p-1.5"
          )}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default BuzzwordTooltip;
