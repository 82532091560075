import React from "react";
import classnames from "classnames";

// for future reference - these look good
// const thing = (
//   <div>
//     <button
//       type="button"
//       className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//       onClick={onCancel}
//     >
//       Cancel
//     </button>
//     <button
//       type="submit"
//       className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//     >
//       Create
//     </button>
//   </div>
// )

// rsm: having this component is stupid but I'm doing this so I don't mess with the base Button and jack up the entire
// site at once. I've added an issue to normalize and consolidate the button sizing and get it out of
// but Button component itself - it's WAY too opinionated about things it shouldn't be. Having a good defualt
// button is good but it needs to be flexible too, for parent components that need way more control over its
// sizing and other shit.
export const ButtonNoSize = ({ children, className, ...restProps }) => {
  return (
    <button className={className} {...restProps}>
      {children}
    </button>
  );
};

const BaseButton = ({
  children,
  className,
  classNameDisabled,
  disabled,
  onClick,
  size = "md",
  ...rest
}) => {
  const isSmall = size === "sm";
  const clsSize = isSmall ? "w-16 h-6 text-xs p-1" : "w-20 h-10 p-2";

  const classes = classnames(
    `rounded-md ${clsSize} focus:ring-2`,
    disabled ? `${classNameDisabled} cursor-auto` : `${className} cursor-pointer`
  );
  return (
    <button className={classes} disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

export const ButtonSave = ({
  children = <>Save</>,
  className,
  classNameDisabled,
  size,
  onClick,
  ...rest
}) => (
  <BaseButton
    className={`bg-green-500 hover:bg-green-600 text-white ${className}`}
    classNameDisabled={`bg-green-500 opacity-50 text-white  ${classNameDisabled}`}
    size={size}
    onClick={onClick}
    {...rest}
  >
    {children}
  </BaseButton>
);

export const ButtonNormal = ({
  children = <>Cancel</>,
  className,
  classNameDisabled,
  size,
  onClick,
  ...rest
}) => (
  <BaseButton
    className={`bg-white text-black hover:bg-green-100 border border-green-500 ${className}`}
    classNameDisabled={`bg-white text-gray-500 opacity-50 border border-gray-500 ${classNameDisabled}`}
    size={size}
    onClick={onClick}
    {...rest}
  >
    {children}
  </BaseButton>
);

export const ButtonDanger = ({
  children = <>Delete</>,
  className,
  classNameDisabled,
  size,
  onClick,
  ...rest
}) => (
  <BaseButton
    className={`bg-red-600 text-white hover:bg-red-800 border border-red-700 ${className}`}
    classNameDisabled={`bg-red-600 text-white opacity-50 border border-red-500 ${classNameDisabled}`}
    size={size}
    onClick={onClick}
    {...rest}
  >
    {children}
  </BaseButton>
);

export const SoloDangerButton = ({ size, onClick, children, ...rest }) => (
  <button
    className={classnames(
      // padding / size
      "px-4 py-2 max-w-xl min-w-[120px]",
      // text / font
      "text-xs sm:text-sm text-buzz-red-700 font-semibold",
      // border
      "border border-gray-300 bg-gray-100 rounded-md",
      // hover
      "hover:bg-buzz-red-700 hover:text-white",
      // active
      "active:bg-buzz-red-800 active:text-white"
    )}
    onClick={onClick}
    {...rest}
  >
    {children}
  </button>
);
