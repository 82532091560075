import React from "react";
import { Link } from "wouter";
import BuzzwordCopyText from "../BuzzwordCopyText";
import { ClickableDiv } from "../atoms/Link";

const WordListEntry = ({ name, listCode, words = [], onClickEdit }) => {
  return (
    <div className="border rounded border-gray-200 p-4">
      {/* row */}
      <div className={"flex justify-between items-center"}>
        {/* list name */}
        <Link href={`/wordlist/${listCode}`}>
          <ClickableDiv className={"text-xl"}>{name}</ClickableDiv>
        </Link>

        {/* item count */}
        <div className={"text-sm text-gray-500"}>Item Count: {words.length}</div>
      </div>

      {/* row */}
      <div className={"flex justify-between items-center"}>
        {/* List code */}
        <div className="text-sm flex">
          <span className="font-semibold mr-2">List Code:</span>
          <BuzzwordCopyText
            text={listCode}
            className={"text-green-800 hover:text-green-600"}
            classNameOnCopy={"text-green-500"}
          />
        </div>
      </div>
    </div>
  );
};

export default WordListEntry;
