import React, { useState } from "react";
import dayjs from "dayjs";
import classnames from "classnames";
import _get from "lodash/get";
import { BorderedListCardRows, RightContentRow, SpreadContentRow } from "../atoms/LayoutComponents";
import { SmDemphText, SmText, XsDemphText } from "../atoms/Text";
import { BBLogoutIcon, CopyIcon } from "../atoms/IconsOutline";
import { BBLink } from "../atoms/Link";
import BuzzwordCopyTextChildren from "../atoms/BuzzwordCopyTextChildren";
import TextInputModal from "../atoms/TextInputModal";
import { ClickableDiv } from "../atoms/Link";
import { DividerWithLabel } from "../atoms/Dividers";
import GameListEntry from "../GameListEntry";

const TeamEntry = ({
  id,
  name,
  playerCount,
  createdAt,
  joinedAt,
  ownerUsername,
  isOwner,
  isOpen,
  inviteCode,
  customWordListCount,
  gamesList,
  onLeaveTeam,
  onCreateGame,
  // for activities on team games. See bb-199 for notes on refactoring so we don't have to prop-drill these functions
  onCloseGame,
  onAcceptInvite,
  onIgnoreInvite,
  onLeaveGame
}) => {
  // create game
  const [showCreateGame, setShowCreateGame] = useState(false);

  const handleCreateGameSave = (name) => {
    onCreateGame(id, name);
    setShowCreateGame(false);
  };

  const shouldShowCreateGameLink = !gamesList || gamesList.length < 2;

  return (
    <>
      <BorderedListCardRows
        className={classnames(
          "gap-1.5",
          isOwner
            ? "border border-blue-600 bg-blue-500 bg-opacity-5"
            : "border border-green-600 bg-green-500 bg-opacity-5"
        )}
      >
        {/*Top Row - Team name */}
        <SpreadContentRow>
          {/* Team Name */}
          <div className="flex flex-col justify-center">
            {isOwner ? (
              <div>
                <ClickableDiv>
                  <div className="text-xl">
                    <BBLink href={`/teams/${id}`}>{name}</BBLink>
                  </div>
                </ClickableDiv>
              </div>
            ) : (
              <div>
                <div className="text-xl">{name}</div>
              </div>
            )}
          </div>
        </SpreadContentRow>

        {/* Row: Owner + invite code + team status */}
        {isOwner ? (
          <div className={"flex justify-between items-center"}>
            <div className={"flex flex-col"}>
              {/* Owner */}
              <div>
                <SmDemphText>Owner</SmDemphText>
              </div>

              {/*Invite Code*/}
              <div className={"flex items-center"}>
                {isOpen ? (
                  <div
                    className={classnames(
                      "flex space-x-1 items-center",
                      isOpen ? "" : "text-gray-400"
                    )}
                  >
                    <div className="flex gap-1">
                      <div className="font-bold">
                        <SmText>Invite Code:</SmText>
                      </div>
                      <div>
                        <SmText>{inviteCode}</SmText>
                      </div>
                    </div>

                    {isOpen ? (
                      <BuzzwordCopyTextChildren copyValue={inviteCode} onCopyElem={XsDemphText}>
                        <CopyIcon width="4" />
                      </BuzzwordCopyTextChildren>
                    ) : null}
                  </div>
                ) : (
                  <div>&nbsp;</div>
                )}
              </div>
            </div>

            {/* Team Invites Open / Closed */}
            <div className="whitespace-nowrap">
              {isOwner ? (
                isOpen ? (
                  <SmText className="text-green-700">Team Open</SmText>
                ) : (
                  <SmText className="text-red-800">Team Closed</SmText>
                )
              ) : null}
            </div>
          </div>
        ) : null}

        {/* Team details / info */}
        <div className={"flex justify-between"}>
          {/* Team Details */}
          <div>
            <SmDemphText className="flex flex-col">
              <p>Players: {playerCount}</p>
              <p>Created by: {ownerUsername}</p>
              <p>Joined: {dayjs(joinedAt).format("L LT")}</p>
            </SmDemphText>
          </div>
          {/* Leave Team Button */}
          {!isOwner ? (
            <RightContentRow>
              <div className="flex items-center" title="Leave Team" onClick={onLeaveTeam}>
                <BBLogoutIcon />
              </div>
            </RightContentRow>
          ) : null}
        </div>

        {/* The parent div below is relative and the left/right columns are absolute in order to allow the middle
            column to stay positioned in the center of the entire width of the parent. Absolute positioning
            combined with left-0/right-0 allows this to happen. Thanks, ChatGPT, for the help.
            */}
        {isOwner ? (
          <div className={"relative flex sm:flex-row sm:items-center flex-col justify-center"}>
            {/*Create Game*/}
            {shouldShowCreateGameLink ? (
              <ClickableDiv onClick={() => setShowCreateGame(true)}>
                <SmText>Create Game</SmText>
              </ClickableDiv>
            ) : (
              <div className={"text-buzz-red-800 text-sm"}>Game limit reached for this team</div>
            )}

            {/*Edit Team*/}
            <div className={"sm:absolute right-0"}>
              <SmText>
                <BBLink href={`/teams/${id}`}>Edit Team</BBLink>
              </SmText>
            </div>
          </div>
        ) : null}

        {/* Team Game List */}
        <div className="flow-root lg:mx-20">
          <DividerWithLabel label="Games" classNames={"py-2"} />
          {gamesList && gamesList.length > 0 ? (
            <ul>
              {gamesList.map((g) => (
                <li key={g.id} className="py-1">
                  <GameListEntry
                    id={g.id}
                    name={g.name}
                    isInvite={g.status.startsWith("INVITE_")}
                    isIgnored={g.status === "INVITE_IGNORED"}
                    teamName={_get(g, "team.name")}
                    playerCount={_get(g, "playerCount")}
                    createdAt={g.createdAt}
                    ownerUid={_get(g, "owner.uid")}
                    ownerUsername={_get(g, "owner.username")}
                    onClickClose={() => onCloseGame(g.id)}
                    onClickIgnore={() => onIgnoreInvite(g.id)}
                    onClickLeave={() => onLeaveGame(g.id)}
                    onClickAccept={() => onAcceptInvite(g.id)}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <div className="flex justify-center">
              <SmDemphText>(No Games)</SmDemphText>
            </div>
          )}
        </div>
      </BorderedListCardRows>

      {/* Create Team Modal */}
      {showCreateGame ? (
        <TextInputModal
          open={true}
          title="New Team Game"
          placeholder="Game Name"
          onSave={handleCreateGameSave}
          onCancel={() => setShowCreateGame(false)}
        />
      ) : null}
    </>
  );
};

export default TeamEntry;
