import React from "react";
import classnames from "classnames";
import {
  PlusIcon,
  PencilSquareIcon,
  TrashIcon,
  ClipboardDocumentIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  ChevronRightIcon,
  CheckIcon,
  ClockIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";

const positiveStateBase = "text-green-800";
const positiveStateHover = "hover:text-green-600";
const defaultPositiveClasses = `cursor-pointer ${positiveStateBase} ${positiveStateHover}`;

const negativeStateBase = "text-red-800";
const negativeStateHover = "hover:text-red-600";
const defaultNegativeClasses = `cursor-pointer ${negativeStateBase} ${negativeStateHover}`;

export const AddIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <PlusIcon className={finalClasses} />;
};

export const EditIcon = ({ width = 5, className, ...restProps }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <PencilSquareIcon className={finalClasses} {...restProps} />;
};

export const DeleteIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultNegativeClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <TrashIcon className={finalClasses} />;
};

export const CopyIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ClipboardDocumentIcon className={finalClasses} />;
};

export const CloseIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <XMarkIcon className={finalClasses} />;
};

export const BBXIcon = CloseIcon;

export const CheckmarkIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <CheckIcon className={finalClasses} />;
};

export const BBLogoutIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultNegativeClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ArrowRightOnRectangleIcon className={finalClasses} />;
};

export const BBChevronRightIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ChevronRightIcon className={finalClasses} />;
};

export const BBClockIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ClockIcon className={finalClasses} />;
};

export const CancelIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultNegativeClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <XCircleIcon className={finalClasses} />;
};

export const SaveIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <CheckCircleIcon className={finalClasses} />;
};

export const BBExclamationTriangleIcon = ({ width = 5, className }) => {
  let finalClasses = className || defaultPositiveClasses;
  finalClasses = classnames(finalClasses, `w-${width}`);
  return <ExclamationTriangleIcon className={finalClasses} />;
};
