import React, { useContext } from "react";
import { gql, useMutation } from "@apollo/client";
import { useLocation } from "wouter";
import Game from "./Game";
import { InputActiveProvider } from "./components/ContextInputActive";
import { GameDataContext, GameDataProvider } from "../../contexts/GameDataContexts";
import { PageBaseFullWidthContainer } from "../atoms/Pages";
import { CenterContentRow } from "../atoms/LayoutComponents";
import { BBLink, ClickableDiv } from "../atoms/Link";
import BackgroundScreenshot from "./game-background.png";

const GameRoot = (props) => {
  return (
    <GameDataProvider id={props.id}>
      <InputActiveProvider>
        <GameStatusSwitch />
      </InputActiveProvider>
    </GameDataProvider>
  );
};

const GameStatusSwitch = () => {
  const gameDataContext = useContext(GameDataContext);
  const [, setLocation] = useLocation();

  let GameComponent;
  switch (gameDataContext.status) {
    case "OPEN":
      GameComponent = <Game />;
      break;
    case "INVITE_IGNORED":
      GameComponent = (
        <GameInviteContainer>
          <GameInviteIgnored
            id={gameDataContext.id}
            onAccept={gameDataContext.refetch}
            onIgnore={() => setLocation("/")}
          />
        </GameInviteContainer>
      );
      break;
    case "INVITE_PENDING":
      GameComponent = (
        <GameInviteContainer>
          <GameInviteOpen
            id={gameDataContext.id}
            onAccept={gameDataContext.refetch}
            onIgnore={() => setLocation("/")}
          />
        </GameInviteContainer>
      );
      break;
    case "NOT_FOUND":
      GameComponent = (
        <GameInviteContainer>
          <FuckOffSubview />
        </GameInviteContainer>
      );
      break;
    default:
      GameComponent = null;
  }

  return <PageBaseFullWidthContainer>{GameComponent}</PageBaseFullWidthContainer>;
};

const GameInviteContainer = ({ id, onIgnore, onAccept, children }) => {
  return (
    // rsm: note: the top-14 class matches the h-14 in the header. If that changes, you'll need to adjust that
    // here to match as well. Although it's really just the positioning of the background image and blur, which
    // doesn't require "position" much either...
    <div className="flex items-center justify-center">
      <div
        className="absolute inset-0 bg-cover top-14 -z-10"
        style={{
          backgroundImage: `url(${BackgroundScreenshot})`
        }}
      ></div>
      <div
        className="absolute inset-0 top-14 -z-10"
        style={{ backdropFilter: "blur(20px)", backgroundColor: "rgba(0, 0, 0, 0.6)" }}
      ></div>
      <div className="border border-blue-700 bg-white p-6 rounded-lg z-10 m-6 sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl">
        {children}
      </div>
    </div>
  );
};

const GameInviteIgnored = ({ id, onIgnore, onAccept }) => {
  // TODO: potentially refactor this and the Home component that I pulled it from such that it's part of the
  //  GameListEntry component instead and it can self-manage the mutation and just report up after the mutation
  //  is finished so the parent component can refresh state if needed. Maybe? Need to try it out to see if that
  //  will work vs. just making things harder.
  const ACCEPT_INVITE = gql`
    mutation AcceptInvite($id: ID!) {
      acceptGameInvite(id: $id) {
        id
        status
      }
    }
  `;

  const [acceptInvite] = useMutation(ACCEPT_INVITE, { variables: { id } });

  const handleAcceptInvite = async () => {
    await acceptInvite({ variables: { id }, errorPolicy: "all" });
    onAccept && onAccept();
  };

  return (
    <>
      <div className="mb-4">
        Looks like you have an invitation to join this game and previously ignored it. You can
        accept it here to join the game or continue to ignore it.
      </div>
      <CenterContentRow>
        <div className="flex gap-10">
          {/*Ignore Button*/}
          <ClickableDiv
            onClick={onIgnore}
            className="text-red-600 hover:text-red-900 cursor-pointer"
          >
            Ignore
          </ClickableDiv>

          {/*Accept Button*/}
          <ClickableDiv
            onClick={handleAcceptInvite}
            className="text-green-600 hover:text-green-900 cursor-pointer"
          >
            Accept
          </ClickableDiv>
        </div>
      </CenterContentRow>
    </>
  );
};

const GameInviteOpen = ({ id, onAccept, onIgnore }) => {
  // TODO: potentially refactor this and the Home component that I pulled it from such that it's part of the
  //  GameListEntry component instead and it can self-manage the mutation and just report up after the mutation
  //  is finished so the parent component can refresh state if needed. Maybe? Need to try it out to see if that
  //  will work vs. just making things harder.
  const ACCEPT_INVITE = gql`
    mutation AcceptInvite($id: ID!) {
      acceptGameInvite(id: $id) {
        id
        status
      }
    }
  `;

  const IGNORE_INVITE = gql`
    mutation IgnoreInvite($id: ID!) {
      ignoreGameInvite(id: $id) {
        id
        status
      }
    }
  `;

  const [acceptInvite] = useMutation(ACCEPT_INVITE, { variables: { id } });
  const [ignoreInvite] = useMutation(IGNORE_INVITE, { variables: { id } });

  const handleAcceptInvite = async () => {
    await acceptInvite({ variables: { id } });
    onAccept && onAccept();
  };

  const handleIgnoreInvite = async () => {
    await ignoreInvite({ variables: { id } });
    onIgnore && onIgnore();
  };

  return (
    <>
      <div className="mb-4">
        Looks like you have an invitation to join this game but haven't accepted it yet. You can
        accept it here if you want.
      </div>
      <div className="mb-4">
        If you decide to ignore it you can always find it and other previously-ignored game
        invitations on your home page by using the "show ignored invitations" switch.
      </div>
      <CenterContentRow>
        <div className="flex gap-10">
          {/*Ignore Button*/}
          <ClickableDiv
            onClick={handleIgnoreInvite}
            className="text-red-600 hover:text-red-900 cursor-pointer"
          >
            Ignore
          </ClickableDiv>

          {/*Accept Button*/}
          <ClickableDiv
            onClick={handleAcceptInvite}
            className="text-green-600 hover:text-green-900 cursor-pointer"
          >
            Accept
          </ClickableDiv>
        </div>
      </CenterContentRow>
    </>
  );
};

const FuckOffSubview = () => {
  return (
    <>
      <div className="mb-4">This game doesn't exist. What are you doing here?</div>
      <CenterContentRow>
        <div className="flex gap-10">
          {/*Ignore Button*/}
          <BBLink href="/" className="text-green-600 hover:text-green-900 cursor-pointer">
            Get Out Of Here
          </BBLink>
        </div>
      </CenterContentRow>
    </>
  );
};

export default GameRoot;
