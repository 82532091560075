import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { ButtonNormal } from "../../../atoms/Button";
import _get from "lodash/get";
import { RightContentRow } from "../../../atoms/LayoutComponents";
import { QuestionMarkPopoverRight } from "../../../atoms/Popovers";
import classnames from "classnames";
import { IconLoadingSpin } from "../../../atoms/IconsLoading";

const GET_MAGIC_WORDS = gql`
  query MagicWordCrap($topic: String!, $size: Int) {
    generateCustomWordEntries(topic: $topic, size: $size)
  }
`;

export default function GptCustomWords({ onAddToList }) {
  const [text, setText] = useState("");
  const [words, setWords] = useState("");
  const [entryArray, setEntryArray] = useState([]);
  const [getMagicWords, { loading }] = useLazyQuery(GET_MAGIC_WORDS, {
    fetchPolicy: "network-only"
  });

  // crappy going away lunch for tammy, she's quitting, but everyone hated her anyway
  // we're going to top golf for tanner's birthday, but no one knows how to golf for shit

  const handleGenerate = async () => {
    if (!text || text.trim() === "") return;
    const res = await getMagicWords({ variables: { topic: text, size: 10 } });
    const newEntries = _get(res, "data.generateCustomWordEntries");
    setEntryArray(newEntries);
  };

  useEffect(() => {
    setWords(entryArray.join("\n"));
  }, [entryArray]);

  const handleResultsChange = (e) => {
    setWords(e.target.value);
  };

  const handleAddToWords = (e) => {
    onAddToList(words.split("\n").filter((w) => w.trim().length > 0));
    setEntryArray([]);
  };

  return (
    <div>
      <div className={"flex flex-col gap-4"}>
        <div className={"flex flex-col gap-2"}>
          <div className={classnames("flex flex-col gap-1 text-sm")}>
            <div>
              <span className="mr-1 font-semibold">Topic</span>
              <QuestionMarkPopoverRight>
                <p className={"font-bold p-1 border-b border-b-gray-400 mb-2"}>What's this crap?</p>
                <div className={"space-y-2"}>
                  <p>
                    Need help generating some custom words? Or perhaps just need some inspiration?
                    Use a pretend fake computer brain!
                  </p>
                  <p>
                    Using state-of-the-art-code we don't understand, we can help generate some
                    custom words for you! Just type in a topic, hit the button, and see what the
                    internet barfs out for you!
                  </p>
                  <p>
                    You can edit the results before you save them to your custom words list, or
                    throw them all away and try again. You'll never be custom-wordless again!
                  </p>
                </div>
              </QuestionMarkPopoverRight>
            </div>

            <input
              className={classnames("border")}
              // because tailwind doesn't have a way to inherit parent font sizes...
              style={{ fontSize: "inherit", lineHeight: "inherit" }}
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>

          <RightContentRow>
            <ButtonNormal disabled={loading} size={"sm"} onClick={handleGenerate}>
              {loading ? (
                <div className={"flex justify-center"}>
                  <IconLoadingSpin className={"h-4 w-4"} />
                </div>
              ) : (
                <div>Submit</div>
              )}
            </ButtonNormal>
          </RightContentRow>
        </div>
        <div>
          <textarea
            className={"w-full border-blue-700 text-sm"}
            value={words}
            rows={Math.max(8, entryArray.length + 4)}
            onChange={handleResultsChange}
          />
        </div>

        <RightContentRow>
          <ButtonNormal className={"w-1/4"} size={"sm"} onClick={handleAddToWords}>
            Add
          </ButtonNormal>
        </RightContentRow>
      </div>
    </div>
  );
}
