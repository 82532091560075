import React, { createContext, useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";

// simple contexts
const WordFilterContext = React.createContext(null);
const ShowOnlyUnmarkedWordsContext = React.createContext(null);

// game data context
const GET_FULL_GAME = gql`
  query FullGameQuery($id: ID!) {
    game(id: $id) {
      id
      name
      status
      polling {
        shouldPoll
        pollingPeriodSeconds
      }
      markedWords {
        id
        text
        markedByUid
        markedByUsername
      }
      scores {
        uid
        username
        total
        words
        bonus
      }
      isClosed
      gameCards {
        rows
        cols
        wordArray
        player {
          uid
          username
        }
      }
      activity {
        id
        type
        timestamp
        markedWord
        player {
          uid
          username
        }
      }
    }
    queryTime
  }
`;

const ABBREVIATED_QUERY = gql`
  query SmallerGameQuery($id: ID!) {
    game(id: $id) {
      markedWords {
        id
        text
        markedByUid
        markedByUsername
      }
    }
  }
`;

const MARK_WORD = gql`
  mutation MarkWord($gameId: String!, $word: String!) {
    markWord(gameId: $gameId, word: $word) {
      id
      text
      markedByUid
      markedByUsername
    }
  }
`;

const UNMARK_WORD = gql`
  mutation UnmarkWord($gameId: String!, $word: String!) {
    unmarkWord(gameId: $gameId, word: $word) {
      id
      text
      markedByUid
      markedByUsername
    }
  }
`;

const GameDataContext = createContext({});

function GameDataProvider(props) {
  // base game data query
  const query = useQuery(GET_FULL_GAME, {
    variables: { id: props.id }
    // fetchPolicy: "network-only"
  });
  const { loading, data, refetch } = query;

  const [markWordHook] = useMutation(MARK_WORD);
  const [unmarkWordHook] = useMutation(UNMARK_WORD);

  // { id: string, text: string, markedByUid: string, markedByUsername: string}
  const [markedWordArray, setMarkedWordArray] = useState([]);

  //
  // polling
  //
  useEffect(() => {
    // update the marked word data
    setMarkedWordArray(_get(data, "game.markedWords"));

    // TODO: put in a "no action in a while click here to start watching the game again" feature and remove this silly shit
    if (
      process.env.REACT_APP_DISABLE_POLLING !== "true" &&
      process.env.REACT_APP_DISABLE_POLLING !== "TRUE"
    ) {
      if (!data) return;
      // bail if we shouldn't poll (as determined by the server)
      if (!_get(data, "game.polling.shouldPoll")) return;

      const initialPollPeriodSeconds = _get(data, "game.polling.pollingPeriodSeconds", 10000);
      const timeoutFunc = async () => {
        // because we're refetching the data, the useEffect will run again after it refreshes and set a new timer
        await refetch({ id: props.id });
      };

      const shouldPoll = _get(data, "game.polling.shouldPoll");
      let tid;
      if (shouldPoll) {
        tid = setTimeout(timeoutFunc, initialPollPeriodSeconds * 1000);
      }

      // this function call is memoized so we store this tid locally and it'll cancel when it unmounts.
      return () => {
        clearTimeout(tid);
      };
    }
  }, [data, props.id, refetch]);

  const markWord = async (word) => {
    setMarkedWordArray([
      ...markedWordArray,
      {
        id: "temp-id",
        text: word,
        isSaving: true
      }
    ]);

    await markWordHook({
      variables: { gameId: props.id, word },
      refetchQueries: [{ query: ABBREVIATED_QUERY, variables: { id: props.id } }]
    });
  };

  const unmarkWord = async (word) => {
    // TODO: this doesn't work great, but it's better than no optimistic right now. I think.
    setMarkedWordArray(markedWordArray.filter((w) => w.text !== word));
    await unmarkWordHook({
      variables: { gameId: props.id, word },
      refetchQueries: [{ query: ABBREVIATED_QUERY, variables: { id: props.id } }]
    });
  };

  const isWordMarked = (word) => {
    return !!getMarkedWordEntry(word);
  };

  const getMarkedWordEntry = (word) => {
    return markedWordArray && markedWordArray.find((m) => m.text === word);
  };

  return (
    <GameDataContext.Provider
      value={{
        loading,
        id: _get(data, "game.id"),
        status: _get(data, "game.status"),
        data,
        markedWordArray,
        isPolling: _get(data, "game.polling.shouldPoll"),
        queryTime: _get(data, "queryTime"),
        refetch,
        markWord,
        unmarkWord,
        isWordMarked,
        getMarkedWordEntry
      }}
      {...props}
    />
  );
}

export { GameDataContext, GameDataProvider, WordFilterContext, ShowOnlyUnmarkedWordsContext };
