import React from "react";

export function LogoFull({ className }) {
  return (
    <>
      <svg
        className={className}
        height="48px"
        viewBox="0 0 2974 885"
        version="1.1"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinejoin: "round",
          strokeMiterlimit: 2
        }}
      >
        <g transform="matrix(1,0,0,1,-519.494,-69.761)">
          <g transform="matrix(0.863565,0,0,0.863565,504.669,-195.718)">
            <g transform="matrix(715.139,0,0,715.139,3493.76,1024)" />
            <text
              x="899.956px"
              y="1024px"
              style={{
                fontFamily: "'Roboto-Black', 'Roboto'",
                fontWeight: 900,
                fontSize: "715.139px",
                fill: "rgb(76,29,149)"
              }}
            >
              u
              <tspan
                x="1281.1px 1626.98px 1972.85px 2469.93px 2849.33px 3091.49px "
                y="1024px 1024px 1024px 1024px 1024px 1024px "
              >
                zzword
              </tspan>
            </text>
          </g>
          <g id="Layer1" transform="matrix(2.94003,0,0,2.95065,99.9327,-298.669)">
            <path
              d="M151.723,124.864L398.123,124.864C398.123,124.864 407.017,125.464 407.017,136.527C407.017,150.196 407.052,213.047 407.052,213.047C407.052,213.047 407.5,244.631 377.412,274.53C403.938,300.01 407.052,327.152 407.052,337.072L407.052,415.934C407.052,415.934 405.925,424.557 398.706,424.557L151.059,424.557C150.964,424.557 142.717,425.921 142.717,416.005C142.717,406.088 142.706,134.684 142.706,134.684C142.706,134.684 142.71,124.864 151.723,124.864ZM171.068,154.988L171.207,394.805L378.896,395.254L378.831,336.825C378.831,336.825 377.687,297.387 337.828,274.553C377.726,250.075 378.932,213.241 378.932,213.241L378.717,154.795L171.068,154.988Z"
              style={{ fill: "rgb(40,100,40)" }}
            />
            <g>
              <path
                d="M358.872,375.387L191.89,375.904L191.89,173.365L358.872,173.72L358.872,204.763C358.872,204.763 351.721,261.299 302.389,261.656C253.056,262.013 214.481,261.762 214.481,261.762L214.614,286.127L302.831,286.371C333.552,286.12 359.729,321.372 358.872,343.748L358.872,375.387ZM214.531,309.277L214.531,353.057L335.268,352.603C335.268,352.603 336.899,321.445 306.21,309.259C274.791,309.259 214.531,309.277 214.531,309.277ZM214.635,196.953L214.531,239.052L304.543,239.112C304.543,239.112 335.268,235.403 335.268,196.858C308.721,196.858 214.635,196.953 214.635,196.953Z"
                style={{ fill: "rgb(76,29,149)" }}
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export function LogoSmall({ className }) {
  return (
    <>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 965 965"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
          strokeLinejoin: "round",
          strokeMiterlimit: "2"
        }}
      >
        <g transform="matrix(1,0,0,1,-427.225,-29.6337)">
          <g id="logo-solo-background" transform="matrix(1.2056,0,0,1.2056,-1916.89,-169.858)">
            <rect
              x="1944.36"
              y="165.47"
              width="800.209"
              height="800.209"
              style={{ fill: "white" }}
            />
          </g>
          <g id="Layer1" transform="matrix(2.94003,0,0,2.95065,99.9327,-298.669)">
            <g id="white-hole-fill" transform="matrix(0.340133,0,0,0.338908,-33.9904,101.221)">
              <path
                d="M562.49,116.329L1247.98,113.172L1242.14,400.958L1171.57,512L1245.21,631.552L1252.97,898.339L561.839,899.297L562.49,116.329Z"
                style={{ fill: "white" }}
              />
            </g>
            <path
              d="M151.723,124.864L398.123,124.864C398.123,124.864 407.017,125.464 407.017,136.527C407.017,150.196 407.052,213.047 407.052,213.047C407.052,213.047 407.5,244.631 377.412,274.53C403.938,300.01 407.052,327.152 407.052,337.072L407.052,415.934C407.052,415.934 405.925,424.557 398.706,424.557L151.059,424.557C150.964,424.557 142.717,425.921 142.717,416.005C142.717,406.088 142.706,134.684 142.706,134.684C142.706,134.684 142.71,124.864 151.723,124.864ZM171.068,154.988L171.207,394.805L378.896,395.254L378.831,336.825C378.831,336.825 377.687,297.387 337.828,274.553C377.726,250.075 378.932,213.241 378.932,213.241L378.717,154.795L171.068,154.988Z"
              style={{ fill: "rgb(40,100,40)" }}
            />
            <g>
              <path
                d="M358.872,375.387L191.89,375.904L191.89,173.365L358.872,173.72L358.872,204.763C358.872,204.763 351.721,261.299 302.389,261.656C253.056,262.013 214.481,261.762 214.481,261.762L214.614,286.127L302.831,286.371C333.552,286.12 359.729,321.372 358.872,343.748L358.872,375.387ZM214.635,196.953L214.531,239.052L304.543,239.112C304.543,239.112 335.268,235.403 335.268,196.858C308.721,196.858 214.635,196.953 214.635,196.953ZM214.531,309.277L214.531,353.057L335.268,352.603C335.268,352.603 336.899,321.445 306.21,309.259C274.791,309.259 214.531,309.277 214.531,309.277Z"
                style={{ fill: "rgb(76,29,149)" }}
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

/*




 */
