import React, { useContext, useEffect, useState } from "react";
import { SmDemphText } from "../../atoms/Text";
import SelectedWordsContext from "./PremadeWordTeamContext";
import { RoundedCornerTitleContainer } from "../../atoms/Containers";
import PremadeWordListEntryInactive from "./PremadeWordListEntryInactive";
import PremadeWordListEntry from "./PremadeWordListEntry";

export default function PremadeWordList() {
  const {
    premadeWordLists,
    premadeWordTeamDataByKey,
    showAllUnused,
    expandedEntryKey,
    onExpandAndContractEntry
  } = useContext(SelectedWordsContext);

  const [inUseLists, setInUseLists] = useState([]);
  const [unusedLists, setUnusedLists] = useState([]);

  useEffect(() => {
    // in-use lists. note that we also check to see if the list is available in the full list array in case
    // the team has a list in-use that's no longer available. In this case we want to let them know that
    // those word entries won't be used in new games, but they can copy them over to their own custom list if
    // they want to keep them.
    /*
        // data structure:
        [
          {
            name: string,
            key: string,
            description: string,
            isNewList: bool,
            isActive: bool,
            // entries for in-use lists are only the in-use entries from the original list
            entries: string[],
            // entryCount can be undefined if the original list is no longer available (based on "isActive")
            entryCount: number
          }
        ];
     */
    setInUseLists(
      Object.keys(premadeWordTeamDataByKey).map((k) => {
        const retObj = { ...premadeWordTeamDataByKey[k] };
        const premadeList = premadeWordLists?.find((e) => e.key === k);
        if (premadeList) retObj.entryCount = premadeList.entryCount;
        return retObj;
      })
    );

    // unused lists
    setUnusedLists(
      premadeWordLists.filter(
        (l) =>
          !premadeWordTeamDataByKey[l.key] || premadeWordTeamDataByKey[l.key]?.entries?.length === 0
      )
    );
  }, [premadeWordLists, premadeWordTeamDataByKey]);

  return (
    <>
      {/* In-Use Lists */}
      <div className="py-2">
        {/* in-use premade lists */}
        <RoundedCornerTitleContainer title="Lists In Use">
          {inUseLists.length === 0 ? (
            <div className="flex justify-center">
              <SmDemphText>No Premade List Words In Use</SmDemphText>
            </div>
          ) : (
            inUseLists.map((l) => {
              // determine if the entry is for a list that's still available or if we need to mark it as unavailable
              // and let the user know
              if (l.isActive) {
                return (
                  <PremadeWordListEntry
                    key={l.key}
                    isExpanded={l.key === expandedEntryKey}
                    listKey={l.key}
                    onClickExpand={() => onExpandAndContractEntry(l.key)}
                    usedEntryCount={
                      (premadeWordTeamDataByKey &&
                        premadeWordTeamDataByKey[l.key]?.entries?.length) ||
                      0
                    }
                    {...l}
                  />
                );
              } else {
                return (
                  <PremadeWordListEntryInactive
                    key={l.key}
                    isExpanded={l.key === expandedEntryKey}
                    listKey={l.key}
                    onClickExpand={() => onExpandAndContractEntry(l.key)}
                    entries={l.entries}
                    {...l}
                  />
                );
              }
            })
          )}
        </RoundedCornerTitleContainer>
      </div>

      {/* Available Lists */}
      {showAllUnused ? (
        <div className="py-2">
          <RoundedCornerTitleContainer title="Available Lists">
            {unusedLists.map((l) => {
              return (
                <PremadeWordListEntry
                  key={l.key}
                  isExpanded={l.key === expandedEntryKey}
                  listKey={l.key}
                  onClickExpand={() => onExpandAndContractEntry(l.key)}
                  usedEntryCount={
                    (premadeWordTeamDataByKey &&
                      premadeWordTeamDataByKey[l.key]?.entries?.length) ||
                    0
                  }
                  {...l}
                />
              );
            })}
          </RoundedCornerTitleContainer>
        </div>
      ) : null}
    </>
  );
}
