import React from "react";
import classnames from "classnames";

export default function ProfilePic({ pictureUrl, name, width = "8" }) {
  if (pictureUrl && isValidUrl(pictureUrl)) {
    return (
      <img
        className={classnames("rounded-full", `w-${width}`)}
        src={pictureUrl}
        alt={name || "profile picture"}
        referrerPolicy="no-referrer"
      />
    );
  } else {
    return <InitialsAvatar playerName={name} />;
  }
}

function InitialsAvatar({ playerName }) {
  let userInitials;

  // find user initials from player name
  const wordsBeforeHash = playerName.split("#")[0].split("-");
  userInitials = wordsBeforeHash
    .slice(0, 2)
    .map((word) => word.charAt(0))
    .join("");

  const backgroundColor = getBackgroundColorForString(playerName);
  const textColor = getTextColorForBackgroundColorHex(backgroundColor);

  return (
    <svg className="h-8 w-8 buzzgreen" viewBox="0 0 100 100">
      <rect
        x="5"
        y="5"
        width="90"
        height="90"
        rx="15"
        ry="15"
        stroke="black"
        strokeWidth="3"
        fill={backgroundColor}
      />
      <text
        x="50"
        y="55"
        dy=".3em"
        fontFamily="Verdana"
        fontSize={userInitials.length > 2 ? "32" : "48"}
        textAnchor="middle"
        fill={textColor}
      >
        {userInitials}
      </text>
    </svg>
  );
}

// determine a background color for the user's avatar that's "stable" based on the username. This creates
// a hash of the username so it's consistent over time and then selects a color from a given array so we can
// keep the colors consistent and "brand aligned".
// this function is courtesy of ChatGPT - don't blame me if it doesn't work.
function getBackgroundColorForString(str) {
  const colors = ["#aa2a2a", "#2aaa2a", "#2a2aaa", "#d4d455", "#d455d4", "#55d4d4"];
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // 1. Bitwise Left Shift (<<):
    //    - hash << 5: This operation moves all the bits in `hash` five positions to the left,
    //      effectively multiplying `hash` by 2^5 = 32.
    //
    // 2. Subtraction (-):
    //    - (hash << 5) - hash: This is the result of subtracting the original `hash` value
    //      from the left-shifted `hash` value. This is equivalent to hash * 31 since
    //      (hash * 32) - hash = hash * (32 - 1) = hash * 31.
    //
    // 3. Addition (+):
    //    - str.charCodeAt(i) + ((hash << 5) - hash): This adds the character code of the
    //      current character in the string to the result of the subtraction. This new sum
    //      is then assigned to `hash`.
    //
    // The operation is done to mix the bits of the hash value, creating a new hash value that is derived
    // from both the previous hash value and the current character's ASCII value. This way, each character
    // in the string affects the final hash value, which in turn affects the color selected from the array.
    // The bitwise left shift and subtraction are fast operations that help in creating a simple hash function
    // to derive a number from a string.
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % colors.length;
  return colors[index];
}

function getTextColorForBackgroundColorHex(backgroundColor) {
  const r = parseInt(backgroundColor.slice(1, 3), 16);
  const g = parseInt(backgroundColor.slice(3, 5), 16);
  const b = parseInt(backgroundColor.slice(5, 7), 16);
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  // return a dark or light color that can be used on the given background
  return luminance > 0.5 ? "#111111" : "#ffffff";
}

// not used right now, but keeping it here anyway
function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
}
