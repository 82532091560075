import React, { useState } from "react";
import ListCards from "./ListCards";
import _get from "lodash/get";
import { ShowOnlyUnmarkedWordsContext } from "../../../contexts/GameDataContexts";
import { MenuItem, ListViewOptionsMenu } from "./ListViewOptionsMenu";

const GameListView = ({ playerCard, markedWords, isReadOnly, otherCards }) => {
  const [showOnlyUnmarked, setShowOnlyUnmarked] = useState(false);

  // menu items
  const showOnlyUnmarkedMenuItem = (
    <MenuItem text="Show only unmarked" enabled={showOnlyUnmarked} onChange={setShowOnlyUnmarked} />
  );

  return (
    <ShowOnlyUnmarkedWordsContext.Provider value={showOnlyUnmarked}>
      {/* list-specific controls and options */}
      <div className="pb-2">
        <ListViewOptionsMenu menuItems={[showOnlyUnmarkedMenuItem]} />
      </div>

      {/* current player's card */}
      <ListCards
        wordArray={playerCard.wordArray}
        markedWords={markedWords}
        isViewOnly={isReadOnly}
        cardUsername={playerCard.player.username}
        cardUid={playerCard.player.uid}
      />

      {/* other players' cards */}
      <div>
        <div className={"bg-black font-medium text-2xl text-white p-2 mt-4 mb-4"}>
          Other Player Cards
        </div>

        {otherCards.map((c) => {
          return (
            <div key={c.player.uid} className={"mb-8"}>
              <div className={"bg-blue-800 font-light text-lg text-white p-2 mb-1"}>
                {_get(c, "player.username", "(unknown username)")}
              </div>
              <ListCards
                wordArray={_get(c, "wordArray", [])}
                markedWords={markedWords}
                isViewOnly={true}
                cardUsername={c.player.username}
                cardUid={c.player.uid}
              />
            </div>
          );
        })}
      </div>
    </ShowOnlyUnmarkedWordsContext.Provider>
  );
};

export default GameListView;
