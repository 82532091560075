import React, { useState } from "react";

const InputActiveContext = React.createContext(false);

const InputActiveProvider = (props) => {
  const [isInputActive, setInputActive] = useState(false);
  return <InputActiveContext.Provider value={{ isInputActive, setInputActive }} {...props} />;
};

export { InputActiveContext, InputActiveProvider };
