import React from "react";
import { Popover } from "@headlessui/react";
import { BiCaretDown } from "react-icons/bi";
import classnames from "classnames";
import { XsDemphText } from "../atoms/Text";
import BuzzwordCopyTextChildren from "../atoms/BuzzwordCopyTextChildren";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  ClipboardIcon,
  ArrowRightOnRectangleIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";
import { BBLink, ExternalLink } from "../atoms/Link";
import { CogIcon } from "@heroicons/react/20/solid";
import { useAuth, useUser } from "../../contexts/AuthContext";
import PlayerProfilePicture from "../atoms/PlayerProfilePicture";

const ProfileButton = () => {
  const pictureUrl = useUser().pictureUrl;
  const username = useUser().username;
  const playerHash = useUser().playerHash;
  const auth = useAuth();

  const identComponent = (
    <div className="w-8">
      <PlayerProfilePicture name={username} pictureUrl={pictureUrl} />
    </div>
  );

  const handleClickCopyUsername = () => {};

  return (
    <Popover as="div" className="relative">
      <Popover.Button className="flex items-center gap-0.5">
        {identComponent}
        <BiCaretDown className="w-4 text-white" />
      </Popover.Button>
      <Popover.Panel
        className={classnames(
          "z-100 flex flex-col p-2 right-2 w-48 bg-white origin-top-right absolute divide-y divide-gray-200",
          "border border-gray-300 rounded-md shadow-lg"
        )}
      >
        {({ close }) => (
          <>
            <div className="py-2">
              <div>
                <div>
                  <XsDemphText>Username: (click to copy)</XsDemphText>
                  <BuzzwordCopyTextChildren
                    copyValue={`${username}#${playerHash}`}
                    onCopy={handleClickCopyUsername}
                    onCopyElem={XsDemphText}
                  >
                    <XsDemphText className="flex justify-between cursor-pointer hover:text-green-500">
                      <div>{`${username}#${playerHash}`}</div>
                      <div>
                        <ClipboardIcon className="w-3" />
                      </div>
                    </XsDemphText>
                  </BuzzwordCopyTextChildren>
                </div>
              </div>
            </div>
            <Popover.Button as="div" className="py-2">
              <div onClick={() => close()}>
                <BBLink className="flex gap-2" href="/closedgames">
                  Previous Games
                </BBLink>
              </div>
            </Popover.Button>
            <Popover.Button as="div" className="py-2 ">
              <div onClick={() => close()}>
                <BBLink className="flex gap-2" href="/settings">
                  <CogIcon className="w-4" />
                  <div>Settings</div>
                </BBLink>
              </div>
            </Popover.Button>
            <div className="py-2">
              <Popover.Button as="div">
                {/* Help button */}
                <ExternalLink
                  className="flex gap-2"
                  href="https://buzzworded.com/instructions"
                  target="_blank"
                  rel="noreferrer"
                >
                  <QuestionMarkCircleIcon className="w-4" />
                  <div>Help</div>
                </ExternalLink>
              </Popover.Button>
              <Popover.Button as="div">
                <ExternalLink
                  className="flex gap-2"
                  href="https://forms.gle/YYLrgHuzdFRCRp9C7"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ChatBubbleOvalLeftEllipsisIcon className="w-4" />
                  <div>Feedback</div>
                </ExternalLink>
              </Popover.Button>
              {auth.isAuthenticated() ? (
                <Popover.Button as="div">
                  <div>
                    <BBLink className="flex gap-2" href="/" onClick={auth.logout}>
                      <ArrowRightOnRectangleIcon className="w-4" />
                      <div>Logout</div>
                    </BBLink>
                  </div>
                </Popover.Button>
              ) : null}
            </div>
          </>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default ProfileButton;
