import React from "react";

const Emoji = ({ label, symbol }) => (
  <span
    className="emoji"
    role="img"
    aria-label={label ? label : ""}
    aria-hidden={label ? "false" : "true"}
  >
    {symbol}
  </span>
);

export default Emoji;

//
// medals
//
export const MedalFirstPlace = () => <Emoji symbol="🥇" label="First place" />;
export const MedalSecondPlace = () => <Emoji symbol="🥈" label="Second place" />;
export const MedalThirdPlace = () => <Emoji symbol="🥉" label="Third place" />;

export const MagicSprinkles = () => <Emoji symbol="✨" label="Magic" />;
