import React, { Children, useState, createContext, useContext } from "react";
import classnames from "classnames";

const TabsContext = createContext({});

//
// TAB CONTENT
//
export function TabContentDisplay({ activeIndex, children }) {
  return (
    <>
      {Children.map(children, (child, index) => {
        return index === activeIndex ? child : null;
      })}
    </>
  );
}

//
// TAB LAYOUT EXTERNAL COMPONENTS
//

// HORIZONTAL
export function TabsHorizontal({ onChangeTab, children }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleClickTab = (idx) => {
    setActiveTab(idx);
    if (onChangeTab) onChangeTab(idx);
  };

  const contextValue = { activeTab, handleClickTab };

  return (
    <TabsContext.Provider value={contextValue}>
      <div>
        <div className="border-b border-gray-300">
          {/* TABS */}
          <nav className="-mb-px flex space-x-3 sm:space-x-6" aria-label="Tabs">
            {React.Children.map(children, (child, index) => {
              return React.cloneElement(child, { _tabIndex: index });
            })}
          </nav>
        </div>
      </div>
    </TabsContext.Provider>
  );
}

// VERTICAL
export function TabsVertical({ onChangeTab, children }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleClickTab = (idx) => {
    setActiveTab(idx);
    if (onChangeTab) onChangeTab(idx);
  };

  const contextValue = { activeTab, handleClickTab };

  return (
    <TabsContext.Provider value={contextValue}>
      <div>
        {/* TABS */}
        <nav className="flex flex-col text-sm text-white bg-buzz-blue-800" aria-label="Tabs">
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { _tabIndex: index });
          })}
        </nav>
      </div>
    </TabsContext.Provider>
  );
}

//
// Externally available tab components
//

// HORIZONTAL
/**
 *
 * @param text
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const HorizontalTabPlain = ({ text, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);

  return (
    <HorizontalBasicTabEntry
      text={text}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

/**
 *
 * @param text
 * @param badgeText
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const HorizontalTabBadge = ({ text, badgeText, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);

  return (
    <HorizontalBadgeTextTabEntry
      text={text}
      badgeText={badgeText}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

/**
 *
 * @param text
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const HorizontalTabDanger = ({ text, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);
  return (
    <HorizontalDangerTabEntry
      text={text}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

TabsHorizontal.TabPlain = HorizontalTabPlain;
TabsHorizontal.TabBadge = HorizontalTabBadge;
TabsHorizontal.TabDanger = HorizontalTabDanger;

// VERTICAL
/**
 *
 * @param text
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const VerticalTabPlain = ({ text, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);

  return (
    <VerticalPlainTabEntry
      text={text}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

/**
 *
 * @param text
 * @param badgeText
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const VerticalTabBadge = ({ text, badgeText, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);

  return (
    <VerticalBadgeTextTabEntry
      text={text}
      badgeText={badgeText}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

/**
 *
 * @param text
 * @param _tabIndex This is an internal property, don't use this.
 * @returns {Element}
 * @constructor
 */
const VerticalTabDanger = ({ text, _tabIndex }) => {
  // use context to get state and callbacks
  const { activeTab, handleClickTab } = useContext(TabsContext);

  return (
    <VerticalDangerTabEntry
      text={text}
      isActive={_tabIndex === activeTab}
      onClick={() => handleClickTab(_tabIndex)}
    />
  );
};

TabsVertical.TabPlain = VerticalTabPlain;
TabsVertical.TabBadge = VerticalTabBadge;
TabsVertical.TabDanger = VerticalTabDanger;

//
// TAB DETAIL COMPONENTS - INTERNAL
//
function BaseTabEntry({
  children,
  isActive,
  onClick,
  classesBase,
  classesActive,
  classesInactive
}) {
  return (
    <div className={"flex items-center gap-1"}>
      <div
        onClick={onClick}
        className={classnames(isActive ? classesActive : classesInactive, classesBase)}
        aria-current={isActive ? "page" : undefined}
      >
        {children}
      </div>
    </div>
  );
}

// VERTICAL
function VerticalPlainTabEntry({ isActive, onClick, text }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={"py-2 px-2 md:px-4 w-full"}
      classesActive={classnames(
        "cursor-default",
        "bg-buzz-blue-900 font-semibold",
        "border-r-4 border-r-buzz-blue-400"
      )}
      classesInactive={classnames(
        "cursor-pointer",
        "hover:text-buzz-blue-100 hover:bg-buzz-blue-600 hover:border-r-4 hover:border-r-buzz-blue-100"
      )}
    >
      {text}
    </BaseTabEntry>
  );
}

function VerticalBadgeTextTabEntry({ text, badgeText, isActive, onClick }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={"py-2 px-2 md:px-4 w-full"}
      classesActive={classnames(
        "cursor-default",
        "bg-buzz-blue-900 font-semibold",
        "border-r-4 border-r-buzz-blue-400"
      )}
      classesInactive={classnames(
        "cursor-pointer",
        "hover:text-buzz-blue-100 hover:bg-buzz-blue-600 hover:border-r-4 hover:border-r-buzz-blue-100"
      )}
    >
      {text}
      {badgeText ? (
        <span
          className={classnames(
            "ml-2 text-xs rounded-full py-0.5 px-1.5 font-normal",
            isActive ? "bg-buzz-green-700 text-buzz-green-100" : "bg-buzz-green-300 text-white"
          )}
        >
          {badgeText}
        </span>
      ) : null}
    </BaseTabEntry>
  );
}

function VerticalDangerTabEntry({ isActive, onClick, text }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={"py-2 px-2 md:px-4 w-full"}
      classesActive={classnames(
        "cursor-default",
        "bg-buzz-blue-900 font-semibold",
        "border-r-4 border-r-buzz-blue-400"
      )}
      classesInactive={classnames(
        "cursor-pointer",
        "hover:text-buzz-blue-100 hover:bg-buzz-blue-600 hover:border-r-4 hover:border-r-buzz-blue-100"
      )}
    >
      {text}
    </BaseTabEntry>
  );
}

// HORIZONTAL
function HorizontalBasicTabEntry({ isActive, onClick, text }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={classnames(
        "whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm",
        "cursor-pointer text-green-600 hover:text-green-700"
      )}
      classesActive={"border-green-500 text-green-700"}
      classesInactive={"border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"}
    >
      {text}
    </BaseTabEntry>
  );
}

function HorizontalBadgeTextTabEntry({ text, badgeText, isActive, onClick }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={classnames(
        "whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm",
        "cursor-pointer text-green-600 hover:text-green-700"
      )}
      classesActive={"border-green-500 text-green-700"}
      classesInactive={"border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"}
    >
      {text}
      {badgeText ? (
        <span
          className={classnames(
            "ml-2 text-xs rounded-full py-0.5 px-1.5 font-normal",
            isActive ? "bg-buzz-green-700 text-buzz-green-100" : "bg-buzz-green-300 text-white"
          )}
        >
          {badgeText}
        </span>
      ) : null}
    </BaseTabEntry>
  );
}

function HorizontalDangerTabEntry({ text, isActive, onClick }) {
  return (
    <BaseTabEntry
      isActive={isActive}
      onClick={onClick}
      classesBase={"whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer"}
      classesActive={"border-buzz-red-500 text-buzz-red-700"}
      classesInactive={
        "border-transparent text-buzz-red-900 hover:text-buzz-red-700 hover:border-buzz-red-300"
      }
    >
      {text}
    </BaseTabEntry>
  );
}
