import React, { useContext, useEffect, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import PremadeWordList from "./PremadeWordList";
import PremadeWordTeamContext from "./PremadeWordTeamContext";
import TeamContext from "../TeamContext";
import _get from "lodash/get";
import { LeftLabelShortToggle } from "../../atoms/Toggles";
import { RightContentRow } from "../../atoms/LayoutComponents";

const GET_PREMADE_LISTS = gql`
  query PremadeWordLists {
    premadeWordLists {
      key
      name
      isNewList
      isActive
      description
      contexts
      entryCount
    }
  }
`;

const PREMADE_WORD_QUERY_FRAGMENT = gql`
  fragment PremadeWordTeamQueryFragment on Team {
    premadeWords {
      key
      name
      isNewList
      isActive
      description
      entries
    }
  }
`;

const QUERY_GET_TEAM_PREMADE_WORDS = gql`
  ${PREMADE_WORD_QUERY_FRAGMENT}
  query TeamPremadeWords($teamId: ID!) {
    teamById(id: $teamId) {
      ...PremadeWordTeamQueryFragment
    }
  }
`;

const MUTATION_ADD_TEAM_PREMADE_WORD = gql`
  ${PREMADE_WORD_QUERY_FRAGMENT}
  mutation AddTeamPremadeWord($teamId: ID!, $listKey: String!, $word: String!) {
    addPremadeWordToTeam(teamId: $teamId, listKey: $listKey, word: $word) {
      id
      ...PremadeWordTeamQueryFragment
    }
  }
`;

const MUTATION_REMOVE_TEAM_PREMADE_WORD = gql`
  ${PREMADE_WORD_QUERY_FRAGMENT}
  mutation RemoveTeamPremadeWord($teamId: ID!, $listKey: String!, $word: String!) {
    removePremadeWordFromTeam(teamId: $teamId, listKey: $listKey, word: $word) {
      id
      ...PremadeWordTeamQueryFragment
    }
  }
`;

const MUTATION_ADD_ALL_WORD_LIST_WORDS = gql`
  ${PREMADE_WORD_QUERY_FRAGMENT}
  mutation AddAllWordListEntriesToTeam($teamId: ID!, $listKey: String!) {
    addAllPremadeWordsFromListToTeam(teamId: $teamId, listKey: $listKey) {
      ...PremadeWordTeamQueryFragment
    }
  }
`;

const MUTATION_REMOVE_ALL_WORD_LIST_WORDS = gql`
  ${PREMADE_WORD_QUERY_FRAGMENT}
  mutation RemoveAllWordListEntriesFromTeam($teamId: ID!, $listKey: String!) {
    removeAllPremadeWordsFromListFromTeam(teamId: $teamId, listKey: $listKey) {
      ...PremadeWordTeamQueryFragment
    }
  }
`;

export default function PremadeWordListTabContent() {
  //
  // contexts
  //
  const { teamId } = useContext(TeamContext);

  //
  // state
  //
  const [premadeWordLists, setPremadeWordLists] = useState([]);
  const [expandedEntryKey, setExpandedEntryKey] = useState("");
  const [showAll, setShowAll] = useState(false);
  // Format:
  // { [listKey: string]: Set([String]) }
  // where the value of each entry is an array of selected entries in that specific list (by key)
  const [premadeWordTeamDataByKey, setPremadeWordTeamDataByKey] = useState({});

  //
  // queries
  //
  const { data: teamPremadeWordQueryData } = useQuery(QUERY_GET_TEAM_PREMADE_WORDS, {
    variables: { teamId }
  });
  const { data: premadeWordListData } = useQuery(GET_PREMADE_LISTS);

  //
  // mutations
  //
  const [addPremadeWord] = useMutation(MUTATION_ADD_TEAM_PREMADE_WORD, {
    refetchQueries: [{ query: QUERY_GET_TEAM_PREMADE_WORDS, variables: { teamId } }]
  });
  const [removePremadeWord] = useMutation(MUTATION_REMOVE_TEAM_PREMADE_WORD, {
    refetchQueries: [{ query: QUERY_GET_TEAM_PREMADE_WORDS, variables: { teamId } }]
  });
  const [addAllListWords] = useMutation(MUTATION_ADD_ALL_WORD_LIST_WORDS, {
    refetchQueries: [{ query: QUERY_GET_TEAM_PREMADE_WORDS, variables: { teamId } }]
  });
  const [removeAllListWords] = useMutation(MUTATION_REMOVE_ALL_WORD_LIST_WORDS, {
    refetchQueries: [{ query: QUERY_GET_TEAM_PREMADE_WORDS, variables: { teamId } }]
  });

  //
  // use effects
  //
  useEffect(() => {
    if (!teamPremadeWordQueryData || !_get(teamPremadeWordQueryData, "teamById.premadeWords"))
      return;
    const transformedData = teamPremadeWordQueryData.teamById.premadeWords.reduce(
      (accum, premadeWord) => {
        accum[premadeWord.key] = premadeWord;
        return accum;
      },
      {}
    );
    setPremadeWordTeamDataByKey(transformedData);

    // if the team premade usage is zero, we set the unused lists as visible by default
    if (Object.keys(transformedData).length === 0) setShowAll(true);
  }, [teamPremadeWordQueryData]);

  useEffect(() => {
    setPremadeWordLists(_get(premadeWordListData, "premadeWordLists", []));
  }, [premadeWordListData]);

  //
  // handlers
  //
  const handleClickShowAllLists = () => {
    setShowAll(!showAll);
    handleCollapseAllEntries();
  };

  const handleChangePremadeWord = async (listKey, word, isChecked) => {
    if (isChecked) {
      await addPremadeWord({ variables: { teamId, listKey, word } });
    } else {
      await removePremadeWord({ variables: { teamId, listKey, word } });
    }
  };

  const handleRemovePremadeWord = async (listKey, word) => {
    await handleChangePremadeWord(listKey, word, false);
  };

  const handleAddAllWordEntries = async (listKey) => {
    await addAllListWords({ variables: { teamId, listKey } });
    handleCollapseAllEntries();
  };

  const handleRemoveAllWordEntries = async (listKey) => {
    await removeAllListWords({ variables: { teamId, listKey } });
    handleCollapseAllEntries();
  };

  const handleExpandAndContractEntry = (index) => {
    if (expandedEntryKey === index) setExpandedEntryKey("");
    else setExpandedEntryKey(index);
  };

  const handleCollapseAllEntries = () => {
    setExpandedEntryKey("");
  };

  const contextObj = {
    premadeWordLists,
    premadeWordTeamDataByKey,
    showAllUnused: showAll,
    expandedEntryKey,
    onChangeWord: handleChangePremadeWord,
    onRemoveWord: handleRemovePremadeWord,
    onAddAllWordEntries: handleAddAllWordEntries,
    onRemoveAllWordEntries: handleRemoveAllWordEntries,
    onExpandAndContractEntry: handleExpandAndContractEntry,
    onCollapseAllEntries: handleCollapseAllEntries
  };

  return (
    <PremadeWordTeamContext.Provider value={contextObj}>
      <RightContentRow>
        <LeftLabelShortToggle
          label="Show Unused Lists"
          enabled={showAll}
          onChange={handleClickShowAllLists}
        />
      </RightContentRow>
      <PremadeWordList showAllUnused={showAll} />
    </PremadeWordTeamContext.Provider>
  );
}
