import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import _get from "lodash/get";
import { useLocation } from "wouter";
import { PageBaseInsetContainer } from "../../atoms/Pages";
import { ClickableDiv } from "../../atoms/Link";
import { SmText } from "../../atoms/Text";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import EditableHeaderText from "../../atoms/EditableHeaderText";
import { SpreadContentRow } from "../../atoms/LayoutComponents";
import { ButtonNormal, ButtonSave } from "../../atoms/Button";
import ModalConfirmDanger from "../../atoms/ModalConfirmDanger";
import ManageEntries from "./ManageEntries";
import EntriesList from "./EntriesList";
import DangerZone from "./DangerZone";
import BuzzwordCopyText from "../../BuzzwordCopyText";
import { QuestionMarkPopoverRight } from "../../atoms/Popovers";
import { TabsHorizontal } from "../../atoms/TabsMenu";

//
// EDIT WORD LIST ROOT
//

const GET_LIST = gql`
  query MyCustomList($listCode: String!) {
    ownedWordList(listCode: $listCode) {
      id
      name
      words
      listCode
    }
  }
`;

const UPDATE_LIST = gql`
  mutation UpdateList($listCode: String!, $name: String!, $words: [String]!) {
    updateWordList(listCode: $listCode, name: $name, words: $words) {
      id
      name
      listCode
      words
    }
  }
`;

const DELETE_LIST = gql`
  mutation DeleteList($listCode: String!) {
    deleteWordList(listCode: $listCode)
  }
`;

export default function CustomWordListEdit({ listCode }) {
  const { data, loading } = useQuery(GET_LIST, { variables: { listCode } });
  const [updateList] = useMutation(UPDATE_LIST);
  const [deleteList] = useMutation(DELETE_LIST);
  // eslint-disable-next-line no-unused-vars
  const [_, setLocation] = useLocation();

  // EDIT PAGE THINGS
  const [localName, setLocalName] = useState("");
  const [localWords, setLocalWords] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isDeleteModalActive, setDeleteModalActive] = useState(false);
  const [isChangeModalActive, setChangeModalActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  // initialize the locally-stored words (for clean/dirty state management)
  useEffect(() => {
    const wordList = _get(data, "ownedWordList");
    const name = _get(wordList, "name", "");
    const words = _get(wordList, "words", []);
    setLocalWords(words.map((w) => w.trim())); // just to keep things clean and consistent
    setLocalName(name);
  }, [data]);

  // INDEX CODE
  const handleSave = async () => {
    const variables = { listCode, name: localName, words: localWords };
    await updateList({ variables });
    setLocation("/wordlists");
  };

  const handleCancel = async () => {
    await setLocation("/wordlists");
  };

  const handleDeleteConfirm = async () => {
    await deleteList({ variables: { listCode } });
    setLocation("/wordlists");
  };

  const handleClickDeleteList = () => {
    setDeleteModalActive(true);
  };

  // EDIT PAGE CODE
  const handleCancelRequested = async () => {
    if (isDirty) setChangeModalActive(true);
    else await handleCancel();
  };

  const handleChangeName = (name) => {
    setLocalName(name);
    setIsDirty(true);
  };

  const handleDeleteWord = (word) => {
    setLocalWords(localWords.filter((w) => w !== word));
    setIsDirty(true);
  };

  // TODO: fix this weird shit
  // set the dirty state for the form
  // useEffect(() => {
  //   // before updating local array, let's check for equality so we know if we really need to mark the form as dirty
  //   const newText = localWords.join("\n");
  //   const oldText = words.join("\n");
  //   if (newText !== oldText) {
  //     setIsDirty(true);
  //   } else {
  //     setIsDirty(false);
  //   }
  // }, [localWords, words]);

  const handleUpdateWordList = (words) => {
    // no changes to make, so don't update and mark dirty
    if (JSON.stringify(words) === JSON.stringify(localWords)) return;
    setLocalWords(words);
    setIsDirty(true);
  };

  return (
    <PageBaseInsetContainer className="min-h-full">
      <ClickableDiv className={"mb-4"} onClick={handleCancelRequested}>
        <SmText>
          <div className="flex w-full items-center ">
            <div>
              <ArrowLongLeftIcon className="w-5" />
            </div>
            <div className="ml-2">
              <SmText>Back To Word Lists</SmText>
            </div>
          </div>
        </SmText>
      </ClickableDiv>

      {loading ? null : (
        <>
          <div className={"flex flex-col gap-4"}>
            {/* NAME */}
            <EditableHeaderText text={localName} onChange={handleChangeName} />

            <SpreadContentRow>
              {/* List code */}
              <div className="text-sm flex">
                <span className="font-semibold mr-2">List Code:</span>
                <BuzzwordCopyText
                  text={listCode}
                  className={"text-green-800 hover:text-green-600"}
                  classNameOnCopy={"text-green-500"}
                  shouldDisplayCopyIcon={false}
                />
                <QuestionMarkPopoverRight>
                  <p className={"font-bold p-1 border-b border-b-gray-400 mb-2"}>
                    What's this thing?
                  </p>
                  <div className={"space-y-2"}>
                    <p>
                      Use this code to add your custom word list to your own team games or send to
                      your friends so they can use it with their team games. When everyone in a team
                      has their own custom word lists, things get much more interesting!
                    </p>
                    <p>
                      Click the code to copy it to your clipboard so you don't have to remember this
                      ugly thing. It's not meant to be pretty...
                    </p>
                  </div>
                </QuestionMarkPopoverRight>
              </div>
              {/* SAVE / CANCEL BUTTONS */}
              <div className="flex gap-2">
                <div>
                  <ButtonNormal size="sm" onClick={handleCancelRequested} disabled={!isDirty}>
                    Cancel
                  </ButtonNormal>
                </div>
                <div>
                  <ButtonSave size="sm" onClick={handleSave} disabled={!isDirty}>
                    Save
                  </ButtonSave>
                </div>
              </div>
            </SpreadContentRow>

            {/* FORM AND CUSTOM WORDS LIST */}
            <div
              className={"flex flex-col gap-4 sm:flex-row sm:gap-0 sm:divide-x sm:divide-gray-400"}
            >
              <div className={"flex-1"}>
                <TabsHorizontal onChangeTab={setActiveTab}>
                  <TabsHorizontal.TabBadge
                    text={"Entries"}
                    badgeText={localWords && localWords.length}
                  />
                  <TabsHorizontal.TabPlain text={"Manage Entries"} />
                  <TabsHorizontal.TabDanger text={"Danger Zone"} />
                </TabsHorizontal>

                {/* TAB CONTENT*/}

                <div className={"mt-2"}>
                  {activeTab === 0 ? (
                    <EntriesList words={localWords} onDeleteWord={handleDeleteWord} />
                  ) : activeTab === 1 ? (
                    <ManageEntries words={localWords} onChangeWords={handleUpdateWordList} />
                  ) : (
                    <DangerZone onDeleteList={handleClickDeleteList} />
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Delete confirmation modal */}
          <ModalConfirmDanger
            open={isDeleteModalActive}
            onConfirm={handleDeleteConfirm}
            onCancel={() => setDeleteModalActive(false)}
          />
          {/* Dirty cancelation confirmation modal */}
          <ModalConfirmDanger
            text="You've made changes. You sure you want to discard those changes?"
            secondaryText="Whatever changes you've made will get lost. Like, no takebacks here. You'll lose all that hard work..."
            open={isChangeModalActive}
            onConfirm={handleCancel}
            onCancel={() => setChangeModalActive(false)}
          />
        </>
      )}
    </PageBaseInsetContainer>
  );
}
