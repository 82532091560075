import classnames from "classnames";

const PopoverComponentRight = ({ text }) => {
  return (
    <div className={classnames("hidden group-hover:flex flex-row absolute left-5 top-1 z-100")}>
      {/* the little arrow nibblet thing on the left */}
      <svg
        width="7.5"
        height="15"
        viewBox="0 0 7.5 15"
        className={"text-gray-800 fill-current"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="7.5,0 7.5,15 0,7.5" fill="black" />
      </svg>
      {/* the actual content box */}
      <div
        className={classnames(
          "flex flex-col gap-2",
          "bg-gray-800",
          "text-xs text-white text-center",
          "p-2",
          "min-w-[200px]",
          "max-w-xs"
        )}
      >
        {text.map((t) => {
          return <p key={t}>{t}</p>;
        })}
      </div>
    </div>
  );
};

const PopoverComponentBottom = ({ text }) => {
  return (
    <div
      className={classnames(
        "hidden group-hover:flex flex-col items-center absolute inset-x-0 top-5 z-100"
      )}
    >
      {/*the little arrow nibblet thing on the top*/}
      <svg
        width="15"
        height="7.5"
        viewBox="0 0 15 7.5"
        className={"text-gray-800 fill-current"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="0,7.5 15,7.5 7.5,0" />
      </svg>
      <div
        className={classnames(
          "flex flex-col gap-2",
          "bg-gray-800",
          "text-xs text-white text-center",
          "p-2",
          "min-w-[200px]",
          "max-w-xs"
        )}
      >
        {text.map((t) => {
          return <p key={t}>{t}</p>;
        })}
      </div>
    </div>
  );
};

const PopoverComponentTop = ({ text }) => {
  return (
    <div
      className={classnames(
        "hidden group-hover:flex flex-col items-center absolute inset-x-0 bottom-full z-100"
      )}
    >
      <div
        className={classnames(
          "flex flex-col gap-2",
          "bg-gray-800",
          "text-xs text-white text-center",
          "p-2",
          "min-w-[200px]",
          "max-w-xs"
        )}
      >
        {text.map((t) => {
          return <p key={t}>{t}</p>;
        })}
      </div>
      {/* Arrow at the bottom */}
      <svg
        width="15"
        height="7.5"
        viewBox="0 0 15 7.5"
        className={"text-gray-800 fill-current"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="0,0 15,0 7.5,7.5" />
      </svg>
    </div>
  );
};

export function PopoverSimpleText({ text = [], position = "top", children }) {
  let popoverElem;
  switch (position) {
    case "right":
      popoverElem = <PopoverComponentRight text={text} />;
      break;
    case "left":
      popoverElem = <PopoverComponentRight text={text} />;
      break;
    case "bottom":
      popoverElem = <PopoverComponentBottom text={text} />;
      break;
    case "top":
    default:
      popoverElem = <PopoverComponentTop text={text} />;
      break;
  }

  return (
    <span className={classnames("group relative")}>
      {children}
      {popoverElem}
    </span>
  );
}

export function QuestionMarkPopoverBottom({ children, className }) {
  return (
    <span className={classnames("group relative", className)}>
      <QuestionMarkButton className={"align-text-top"} />
      <div
        className={classnames(
          "hidden group-hover:flex flex-col items-center absolute inset-x-0 top-5 z-100"
        )}
      >
        {/*the little arrow nibblet thing on the top*/}
        <svg
          width="15"
          height="7.5"
          viewBox="0 0 15 7.5"
          className={"text-gray-800 fill-current"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="0,7.5 15,7.5 7.5,0" />
        </svg>
        <div className={classnames("bg-gray-800", "text-xs text-white", "p-2 w-64")}>
          {children}
        </div>
      </div>
    </span>
  );
}

export function QuestionMarkPopoverRight({ children, className }) {
  return (
    <span className={classnames("group relative", className)}>
      <QuestionMarkButton className={"align-text-top"} />
      <div className={classnames("hidden group-hover:flex flex-row absolute left-5 top-1 z-100")}>
        {/* the little arrow nibblet thing on the left */}
        <svg
          width="7.5"
          height="15"
          viewBox="0 0 7.5 15"
          className={"text-gray-800 fill-current"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon points="7.5,0 7.5,15 0,7.5" fill="black" />
        </svg>
        {/* the actual content box */}
        <div className={classnames("bg-gray-800", "text-xs text-white", "p-2 w-64")}>
          {children}
        </div>
      </div>
    </span>
  );
}

function QuestionMarkButton({ className }) {
  return (
    <button className={classnames("w-4 h-4 text-gray-400 hover:text-gray-500", className)}>
      <svg
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        ></path>
      </svg>
      <span className="sr-only">Show information</span>
    </button>
  );
}
