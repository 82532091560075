import GameScores from "./GameScores";
import GameActivity from "./GameActivity";
import React from "react";

const GameRightColumn = ({ gameScores, gameActivity = [] }) => {
  return (
    <>
      <div className={"mb-2 border border-green-500"}>
        <GameScores scores={gameScores} />
      </div>

      <div className={"h-4/5"}>
        <GameActivity data={gameActivity} />
      </div>
    </>
  );
};

export default GameRightColumn;
