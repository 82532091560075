import { gql } from "@apollo/client";

const TEAM_FRAGMENT_BASE = gql`
  fragment TeamQueryFragment on Team {
    id
    name
    open
    createdAt
    joinedAt
    playerCount
    owner {
      uid
      username
    }
    games {
      id
      name
      status
      createdAt

      owner {
        uid
        username
      }
      players {
        uid
        username
      }
      playerCount
      team {
        name
      }
    }
  }
`;

const TEAM_FRAGMENT_OWNED = gql`
  fragment TeamOwnerFragment on Team {
    inviteCode
    customWordLists {
      id
      listCode
    }
    games {
      id
      name
      status
      createdAt
      wordLists {
        listCode
      }
      owner {
        uid
        username
      }
      players {
        uid
        username
      }
      playerCount
      team {
        name
      }
      invites {
        createdAt
        status
      }
    }
  }
`;

const TEAM_FRAGMENT_WORDLIST_DETAIL = gql`
  fragment WordListFragment on Team {
    customWordLists {
      id
      listCode
      valid
      name
      words
    }
    players {
      uid
      username
      pictureUrl
    }
  }
`;

export const GET_MY_TEAMS = gql`
  ${TEAM_FRAGMENT_BASE}
  ${TEAM_FRAGMENT_OWNED}

  query Teams {
    joinedTeams {
      ...TeamQueryFragment
    }
    ownedTeams {
      ...TeamQueryFragment
      ...TeamOwnerFragment
    }
  }
`;

export const GET_TEAM_DETAIL = gql`
  ${TEAM_FRAGMENT_BASE}
  ${TEAM_FRAGMENT_WORDLIST_DETAIL}
  ${TEAM_FRAGMENT_OWNED}

  query Team($id: ID!) {
    teamById(id: $id) {
      ...TeamQueryFragment
      ...WordListFragment
      ...TeamOwnerFragment
    }
  }
`;

export const CREATE_TEAM_GAME = gql`
  mutation CreateTeamGame($name: String!, $teamId: String!) {
    createTeamGame(name: $name, teamId: $teamId) {
      id
    }
  }
`;

export const CREATE_TEAM = gql`
  ${TEAM_FRAGMENT_BASE}
  ${TEAM_FRAGMENT_OWNED}

  mutation CreateTeam($name: String!) {
    createTeam(name: $name) {
      ...TeamQueryFragment
      ...TeamOwnerFragment
    }
  }
`;

export const JOIN_TEAM = gql`
  ${TEAM_FRAGMENT_BASE}

  mutation JoinTeam($inviteCode: String!) {
    joinTeam(inviteCode: $inviteCode) {
      ...TeamQueryFragment
    }
  }
`;

export const LEAVE_TEAM = gql`
  ${TEAM_FRAGMENT_BASE}

  mutation LeaveTeam($id: ID!) {
    leaveTeam(id: $id) {
      ...TeamQueryFragment
    }
  }
`;
