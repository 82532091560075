import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { CloseIcon } from "./IconsOutline";
import { SpreadContentRow } from "./LayoutComponents";
import { UserLocalPrefsContext } from "../../contexts/UserPreferencesContext";

export function AnnouncementBox({ title, children }) {
  return (
    <div className={classnames("flex flex-col")}>
      <div
        className={classnames(
          "text-yellow-700 bg-yellow-50",
          "font-bold text-sm",
          "border-t border-l border-r border-yellow-400 rounded-tl-xl rounded-tr-xl",
          "px-4 p-2"
        )}
      >
        {title}
      </div>
      {/*  CONTENT BELOW HERE*/}
      <div
        className={classnames(
          "border-yellow-400 border-l border-r border-b",
          "text-sm text-gray-700",
          "rounded-bl-xl rounded-br-xl",
          "px-4 py-2",
          "flex flex-col divide-y divide-gray-200"
        )}
      >
        {children}
      </div>
    </div>
  );
}

export function DismissableAnnouncementBox({ title, boxKey, className, children }) {
  const [shouldHide, setShouldHide] = useState(false);
  const { getPref, setPref } = useContext(UserLocalPrefsContext);

  useEffect(() => {
    const v = getPref(boxKey);
    setShouldHide(!!v);
  }, [boxKey, getPref]);

  const handleClickClose = () => {
    setPref(boxKey, true);
  };

  if (!boxKey) return;

  return !shouldHide ? (
    <div className={classnames("flex flex-col", className)}>
      <div
        className={classnames(
          "text-yellow-700 bg-yellow-50",
          "font-bold text-sm",
          "border-t border-l border-r border-yellow-400 rounded-tl-xl rounded-tr-xl",
          "px-4 p-2"
        )}
      >
        <SpreadContentRow>
          <div>{title}</div>
          <div onClick={handleClickClose}>
            <CloseIcon className={"text-yellow-700 hover:text-yellow-900 cursor-pointer"} />
          </div>
        </SpreadContentRow>
      </div>

      {/*  CONTENT BELOW HERE*/}
      <div
        className={classnames(
          "border-yellow-400 border-l border-r border-b",
          "text-sm text-gray-700",
          "rounded-bl-xl rounded-br-xl",
          "px-4 py-2",
          "flex flex-col divide-y divide-gray-200"
        )}
      >
        {children}
      </div>
    </div>
  ) : null;
}
