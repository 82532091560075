import React from "react";
import classnames from "classnames";
import { XsDemphText } from "./Text";

//
// SPREAD CONTENT ROW
//

export const SpreadContentRow = ({ children, className }) => {
  return (
    <div className={classnames("flex items-center justify-between", className)}>{children}</div>
  );
};

export const SpreadContentCollapsingRow = ({ children, className }) => {
  return (
    <div
      className={classnames("flex sm:flex-row flex-col sm:items-center justify-between", className)}
    >
      {children}
    </div>
  );
};

export const SpreadContentRowItemsTop = ({ children, className }) => {
  return (
    <div className={classnames("flex items-start justify-between", className)}>{children}</div>
  );
};

export const SpreadContentCollapsingRowItemsTop = ({ children, className }) => {
  return (
    <div className={classnames("flex sm:flex-row flex-col justify-between", className)}>
      {children}
    </div>
  );
};

export const CenterContentRow = ({ children, className, gap = 2 }) => {
  return <div className={classnames(`flex justify-center gap-${gap}`, className)}>{children}</div>;
};

export const LeftContentRow = ({ children, className }) => {
  return <div className={classnames("flex justify-start", className)}>{children}</div>;
};

export const RightContentRow = ({ children, className }) => {
  return <div className={classnames("flex justify-end", className)}>{children}</div>;
};

export const RightJustifiedDemphasizedText = ({ children }) => {
  return (
    <div className={"text-right"}>
      <XsDemphText>{children}</XsDemphText>
    </div>
  );
};

export const BorderedListCardRows = ({ children, className }) => {
  return <div className={classnames("flex flex-col p-2", className)}>{children}</div>;
};
