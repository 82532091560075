import React, { useContext, useState } from "react";
import PremadeWordTeamContext from "./PremadeWordTeamContext";
import { RightContentRow } from "../../atoms/LayoutComponents";
import { ClickableDivDanger } from "../../atoms/Link";
import { SmDemphText, SmText } from "../../atoms/Text";
import classnames from "classnames";
import { PopoverSimpleText } from "../../atoms/Popovers";
import { BBExclamationTriangleIcon } from "../../atoms/IconsSolid";
import { BBChevronRightIcon } from "../../atoms/IconsOutline";

export default function PremadeWordListEntryInactive({
  name,
  listKey,
  isExpanded = false,
  description,
  entries,
  onClickExpand
}) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className="flex flex-col">
      <div
        className={classnames(
          "flex justify-between items-center cursor-pointer",
          "p-2",
          "gap-2",
          "border-b border-gray-200 last:border-b-0",
          { "bg-buzz-green-50": isHovering }
        )}
        onClick={onClickExpand}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <div className={"font-semibold"}>{name}</div>
            <span className="text-sm text-buzz-red-700">(using {entries.length} of ???)</span>
            <PopoverSimpleText
              text={[
                "This premade list is no longer available, but the entries here will still be used in new games.",
                "If you want to keep any of these entries, add them to your own custom word list.",
                "One you remove these entries, they cannot be recovered."
              ]}
              position="top"
            >
              <BBExclamationTriangleIcon />
            </PopoverSimpleText>
          </div>

          <div className="pl-4">
            <SmDemphText>{description}</SmDemphText>
          </div>
        </div>

        <div
          className={`transform transition-transform ${isExpanded ? "rotate-90" : ""}`}
          onClick={onClickExpand}
        >
          <BBChevronRightIcon />
        </div>
      </div>

      <div
        className={`flex flex-col gap-2 transition-all duration-500 ${
          isExpanded ? "max-h-auto" : "max-h-0 overflow-hidden"
        }`}
      >
        {isExpanded ? (
          <div className="py-2 px-4">
            <PremadeWordInactiveListDetailContent listKey={listKey} entries={entries} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

function PremadeWordInactiveListDetailContent({ listKey, entries }) {
  const { onRemoveAllWordEntries } = useContext(PremadeWordTeamContext);

  return (
    <div onClick={(e) => e.stopPropagation()} style={{ maxHeight: "calc(100% - 10rem)" }}>
      {/* Instructions and full-list actions */}
      <RightContentRow className="border-b border-gray-200 pb-2">
        <ClickableDivDanger onClick={() => onRemoveAllWordEntries(listKey)}>
          <SmText>Remove All</SmText>
        </ClickableDivDanger>
      </RightContentRow>

      {/* Content */}
      <div className="flex-grow overflow-auto">
        {entries.map((entry) => (
          <div
            key={entry}
            className={classnames(
              "sm:px-2 py-2 border-b border-b-gray-200",
              "text-sm sm:text-base"
            )}
          >
            {entry}
          </div>
        ))}
      </div>
    </div>
  );
}
