import classnames from "classnames";

export function Heading1({ className, noMargin = false, children }) {
  return (
    <div className={classnames("text-3xl font-medium", { "my-4": !noMargin }, className)}>
      {children}
    </div>
  );
}

export function Heading2({ className, children }) {
  return <div className={classnames("text-2xl font-medium my-3", className)}>{children}</div>;
}

export function Heading3({ className, children }) {
  return <div className={classnames("text-xl font-medium my-2", className)}>{children}</div>;
}

export function Heading4({ className, children }) {
  return <div className={classnames("text-lg font-medium my-1", className)}>{children}</div>;
}
