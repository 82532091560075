import React, { useState } from "react";
import { ButtonSave } from "../../../atoms/Button";

const SingleWordEntry = ({ onSave }) => {
  const [word, setWord] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setWord("");
    onSave(word);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={"flex flex-col gap-4"}>
          <div className="flex flex-col gap-2 text-sm">
            <p>Add a single custom word or phrase entry here.</p>
            <p>
              If you want to add, edit, or remove multiple entries quickly, use the "Manage All" tab
              instead.
            </p>
          </div>

          <input
            className={"flex-grow"}
            type="text"
            placeholder="Add a new custom word here"
            value={word}
            onChange={(e) => setWord(e.target.value)}
          />
          <div className={"flex-none"}>
            <ButtonSave size="sm" color="success" disabled={word.length === 0}>
              Add
            </ButtonSave>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SingleWordEntry;
