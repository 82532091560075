import React, { useState } from "react";
import { MagnifyingGlassIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { RightContentRow } from "../../atoms/LayoutComponents";
import classnames from "classnames";

export default function EntriesList({ words, onDeleteWord }) {
  const [filter, setFilter] = useState("");

  const handleKeyDown = async (e) => {
    if (e.key === "Escape") {
      e.preventDefault(); // Prevents a new line or any default action
      setFilter("");
    }
  };

  return (
    <div>
      {/* WORD LIST */}
      <div className={"flex-1"}>
        {/* Filter */}
        <RightContentRow className="mb-2">
          <div className="flex relative items-center">
            <span className="text-xs absolute font-semibold">
              <MagnifyingGlassIcon className="h-3 ml-2 text-gray-400" />
            </span>
            <input
              className="text-xs py-1 px-6 rounded-full border-gray-400"
              type="text"
              placeholder="Filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onKeyDown={handleKeyDown}
            ></input>
            {filter && filter.length > 0 ? (
              <span>
                <XMarkIcon className="h-3 -ml-5 text-gray-400" onClick={() => setFilter("")} />
              </span>
            ) : null}
          </div>
        </RightContentRow>

        {/* Word List*/}
        <div className="flex flex-col justify-center divide-y divide-gray-200">
          {words
            ? words
                .filter((e) => e.indexOf(filter) > -1)
                .map((w, idx) => (
                  <EditWordEntry
                    key={`${w}_${idx}`}
                    word={w}
                    showDeleteButton={true}
                    onDelete={() => onDeleteWord(w)}
                  />
                ))
            : null}
        </div>
      </div>
    </div>
  );
}

const EditWordEntry = ({ word, onDelete, showDeleteButton = true }) => {
  const [hover, setHover] = useState(false);

  const handleDelete = (data) => {
    setHover(false);
    onDelete(data);
  };

  return (
    <div className={classnames("py-2 px-1 flex gap-1 items-center", { "bg-buzz-red-700": hover })}>
      <div className={classnames("flex-grow", { "text-white": hover })}>{word}</div>
      {showDeleteButton ? (
        <div className={"text-red-600 hover:text-white cursor-pointer justify-self-end"}>
          <TrashIcon
            className={"h-5"}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleDelete}
          />
        </div>
      ) : null}
    </div>
  );
};
